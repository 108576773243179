/* eslint-disable import/prefer-default-export */

/**
 *
 * @param errors array
 * @param name string can be simple (like "batman") or nested (like intellData.batman)
 * @returns {unknown}
 */
export function getError(errors, name) {
  return name.split('.').reduce((memo, value) => {
    return memo?.[value];
  }, errors);
}
