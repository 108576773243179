import React from 'react';
import PropTypes from 'prop-types';
import Player from 'components/player';

export const AlertAttachmentModalPreviewVideo = ({src, ...props}) => {
	return <Player url={src} {...props} />;
};

AlertAttachmentModalPreviewVideo.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	classes: PropTypes.object,
	src: PropTypes.string,
};

AlertAttachmentModalPreviewVideo.displayName = ' AlertAttachmentModal.PreviewVideo';
export default AlertAttachmentModalPreviewVideo;
