import {Badge, makeStyles} from '@material-ui/core';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import {DataLayer} from '@nimbus-target/ui';
import FeatureToggle from 'components/FeatureToggle';
import IconButton from 'components/buttons/IconButton';
import Drawer from 'components/drawer/Drawer';
import {useSocketContext} from 'context';
import {useUserContext} from 'context/index';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppValue, useDispatchSetAppValue} from 'redux/hooks/app';
import {useDispatchPost} from 'redux/hooks/fetch';
import PocketOfLeads from './PocketOfLeads';

import useSound from 'use-sound';
import sound from '../assets/pocket-drop.mp3';

function gtag(...rest) {
	DataLayer.push(...rest.slice(1));
}

const useStyles = makeStyles(theme => ({
	icon: {
		fontSize: 28,
		[theme.breakpoints.down('1300')]: {
			fontSize: 22,
		},
		[theme.breakpoints.down('1100')]: {
			fontSize: 20,
		},
	},
	badge: {
		background: theme.palette.success.main,
	},
}));

function LeadPocketButton() {
	const classes = useStyles();

	const {socketCore} = useSocketContext();

	const {groupType} = useUserContext();

	const post = useDispatchPost();

	const location = useLocation();

	const [play] = useSound(sound);

	const leadsWaitingOnPocketFirstLoad = useAppValue('leadsWaitingOnPocketFirstLoad');
	const setLeadsWaitingOnPocketFirstLoad = useDispatchSetAppValue('leadsWaitingOnPocketFirstLoad');
	const leadsWaitingOnPocket = useAppValue('leadsWaitingOnPocket');
	const setLeadsWaitingOnPocket = useDispatchSetAppValue('leadsWaitingOnPocket');

	const selectedLeadData = useAppValue('selectedLeadData');

	const setSelectedLeadData = useDispatchSetAppValue('selectedLeadData');
	const setNotFoundLead = useDispatchSetAppValue('notFoundLead');

	const [drawerPocketOfLeads, setDrawerPocketOfLeads] = useState(false);

	// Get first data
	useEffect(() => {
		// If we already populated the list, we dont need to call it again
		if (leadsWaitingOnPocketFirstLoad) return;
		// Get first list state from backend
		post('/lead/list-from-pocket')
			.then(responseData => {
				setLeadsWaitingOnPocket(responseData.data);
				// From here, we dont need to call this api again, unless the user requests when opening the pocket
				setLeadsWaitingOnPocketFirstLoad(true);
			})
			.catch(err => {
				console.error(err);
			});
	}, [post, leadsWaitingOnPocketFirstLoad, setLeadsWaitingOnPocket, setLeadsWaitingOnPocketFirstLoad]);

	// Socket event - New lead on pocket
	useEffect(() => {
		if (!socketCore) return undefined;
		// Add new lead on list
		const handleNewLeadWaiting = newLeadWaiting => {
			// If user logged is a store type
			if (groupType === 'store') {
				// Play custom sound
				play();
				// If this user had access to this lead before it goes to the pocket and have it selected on screen...
				// We have to change the selected lead data to prevent that this user makes any changes on a lead that is currently on pocket
				if (location.pathname.includes('/chat') && selectedLeadData._id === newLeadWaiting._id) {
					setSelectedLeadData({});
					setNotFoundLead(true);
				}
			}
			let newLeadsWaiting = [newLeadWaiting, ...leadsWaitingOnPocket];
			setLeadsWaitingOnPocket(newLeadsWaiting);
		};
		// When the event comes
		socketCore.on('newLeadWaitingOnPocket', handleNewLeadWaiting);
		return () => {
			socketCore.removeListener('newLeadWaitingOnPocket', handleNewLeadWaiting);
		};
	}, [
		socketCore,
		setLeadsWaitingOnPocket,
		leadsWaitingOnPocket,
		groupType,
		play,
		selectedLeadData._id,
		location,
		setSelectedLeadData,
		setNotFoundLead,
	]);

	// Socket event - Lead removed from pocket
	useEffect(() => {
		if (!socketCore) return undefined;
		// Remove lead from list
		const handleLeadRemovedFromPocket = leadid => {
			const newList = leadsWaitingOnPocket.filter(data => {
				return data._id !== leadid;
			});
			setLeadsWaitingOnPocket(newList);
		};
		// When the event comes
		socketCore.on('leadOutFromPocket', handleLeadRemovedFromPocket);
		return () => {
			socketCore.removeListener('leadOutFromPocket', handleLeadRemovedFromPocket);
		};
	}, [socketCore, leadsWaitingOnPocket, setLeadsWaitingOnPocket]);

	// Socket event - Refresh selected lead if so
	useEffect(() => {
		if (!socketCore) return undefined;
		const handleResetLeadData = leadId => {
			if (location.pathname.includes('/chat') && selectedLeadData._id === leadId) {
				setSelectedLeadData({});
				setNotFoundLead(true);
			}
		};
		// When the event comes
		socketCore.on('resetSelectedLeadData', handleResetLeadData);
		return () => {
			socketCore.removeListener('resetSelectedLeadData', handleResetLeadData);
		};
	}, [socketCore, groupType, selectedLeadData._id, location, setSelectedLeadData, setNotFoundLead]);

	return (
		<FeatureToggle configKey='pocketOfLeads'>
			<IconButton
				alt='Bolsão de leads'
				handleClick={() => {
					setDrawerPocketOfLeads(true);
					gtag('event', 'open_pocket_of_leads');
				}}
			>
				<Badge badgeContent={leadsWaitingOnPocket.length} max={99} overlap='circular' color='error'>
					<LocalMallIcon className={classes.icon} />
				</Badge>
			</IconButton>
			<Drawer
				title={`Bolsão de leads (${leadsWaitingOnPocket.length})`}
				direction={'right'}
				isOpen={drawerPocketOfLeads}
				onClose={() => setDrawerPocketOfLeads(false)}
				enableBackdropInteraction={true}
			>
				<PocketOfLeads />
			</Drawer>
		</FeatureToggle>
	);
}

export default LeadPocketButton;
