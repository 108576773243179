import React from 'react';
import PropTypes from 'prop-types';
import useIsAllowed from './useIsAllowed';

function PermissionToggle(props) {
	const {children, hasFeedback, permissionKey} = props;

	const isAllowed = !permissionKey | useIsAllowed(permissionKey);

	const feedback = hasFeedback ? <span>Você não está autorizado a realizar essa ação</span> : null;
	return isAllowed ? children : feedback;
}

PermissionToggle.propTypes = {
	children: PropTypes.node.isRequired,
	hasFeedback: PropTypes.bool,
	permissionKey: PropTypes.string.isRequired, // key within utils/permission object
};

PermissionToggle.defaultProps = {
	hasFeedback: true,
};

export default PermissionToggle;
