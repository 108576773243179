import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {Card, CardContent} from '@material-ui/core';
import MessageIcon from '@material-ui/icons/Message';
import IconButton from 'components/buttons/IconButton';
import AlertAttachmentsModalPreview from './AlertAttachmentsModal.Preview';
import AlertAttachmentModalButton from './AlertAttachmentModalButton';
import styles from './NotificationAttachmentsModal.module.css';

export const AlertAttachmentsModal = ({notification: {attachments, title, description}}) => {
	const [selectedAttachment, setSelectedAttachment] = useState(null);

	return (
		<Fragment>
			<div className={styles.containerDiv}>
				<div className={styles.buttonsRow}>
					<IconButton
						className={styles.iconButton}
						handleClick={() => {
							setSelectedAttachment(null);
						}}
					>
						<MessageIcon style={{width: 25, height: 25}} />
						<p className={styles.iconText} style={!selectedAttachment ? {fontWeight: 'bold'} : {}}>
							{' '}
							Corpo da notificação
						</p>
					</IconButton>
					{attachments.map((attachment, index) => {
						return (
							<AlertAttachmentModalButton
								key={`IconButton-${index}-${attachment.type.toString()}`}
								className={styles.iconButton}
								attachment={attachment}
								onClick={() => setSelectedAttachment(attachment)}
								selected={selectedAttachment === attachment}
							/>
						);
					})}
				</div>

				<div className={styles.content}>
					<Card variant={'elevation'} className={styles.card}>
						<CardContent className={styles.cardContent}>
							<h2>{selectedAttachment ? selectedAttachment.title : title}</h2>
							<AlertAttachmentsModalPreview title={title} description={description} attachment={selectedAttachment} />
						</CardContent>
					</Card>
				</div>
			</div>
		</Fragment>
	);
};

AlertAttachmentsModal.propTypes = {
	className: PropTypes.string,
	classes: PropTypes.object,
	notification: PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.string,
		attachments: PropTypes.arrayOf(
			PropTypes.shape({
				type: PropTypes.string,
				title: PropTypes.string,
				address: PropTypes.string,
			}),
		),
	}),
};

AlertAttachmentsModal.Preview = AlertAttachmentsModalPreview;

AlertAttachmentsModal.displayName = 'AlertAttachmentsModal';
export default AlertAttachmentsModal;
