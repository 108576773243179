import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import MaterialRadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {getError} from '../form/utils';
import {Controller} from 'react-hook-form';
import {FormHelperText} from '@material-ui/core';

const RadioGroup = React.forwardRef((props, ref) => {
  const {
    className,
    errors,
    label,
    name,
    onChange,
    options,
    helperText,
    control,
    margin,
    row,
    defaultValue,
    disabled,
  } = props;

  const handleChange = (e) => {
    if (onChange) onChange(e);
  };

  const error = getError(errors, name);
  return (
    <FormControl className={className} component="fieldset" margin={margin}>
      {label ? <FormLabel component="legend">{label}</FormLabel> : null}
      <Controller
        control={control}
        errors={error}
        name={name}
        render={({onChange, value, name, ref}) => (
          <MaterialRadioGroup
            aria-label={name}
            row={row}
            name={name}
            value={value}
            defaultValue={defaultValue}
            onChange={(e) => {onChange(e); handleChange(e)}}
            ref={ref}
          >
            {options.map((data) => {
              const {label: optionLabel} = data;
              return (
                <FormControlLabel
                  value={optionLabel}
                  disabled={disabled ? true : false}
                  control={<Radio color="primary" />}
                  label={optionLabel}
                />
              );
            })}
          </MaterialRadioGroup>
        )}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
});

RadioGroup.propTypes = {
  className: PropTypes.string,
  control: PropTypes.instanceOf(Object),
  defaultChecked: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.instanceOf(Object),
  helperText: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
  })).isRequired,
  row: PropTypes.bool,
};

export default RadioGroup;
