import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

const settingsRoutes = [
	{
		component: LazyComponent(lazy(() => import('./Update'))),
		path: '/settings',
	},
];

export default settingsRoutes;
