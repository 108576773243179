import {useEffect, useRef, useState} from 'react';
import classnames from 'classnames';
import {guard} from '@nimbus-target/ui';

/**
 * Hook `useClickOutsite`
 *
 * Este hook é utilizado para detectar cliques fora de um elemento específico.
 * Ele recebe uma função de retorno de chamada (`callback`) como argumento, que será invocada
 * sempre que um clique for detectado fora do elemento de referência.
 *
 * @param {function} callback - Uma função de retorno de chamada que será chamada quando um clique for detectado fora do elemento de referência.
 *
 * @returns {object} - Uma referência de objeto que deve ser anexada a um elemento React. Essa referência é criada usando o `useRef` e é utilizada para marcar o elemento que deve ser monitorado para cliques fora.
 *
 * @example
 *
 * function App() {
 *   const handleClickOutside = (evt) => {
 *     console.log('Clique fora do elemento detectado!', evt);
 *   };
 *
 *   const clickOutsideRef = useClickOutside(handleClickOutside);
 *
 *   return (
 *     <div>
 *       <h1>Exemplo de Hook useClickOutside</h1>
 *       <div ref={clickOutsideRef}>
 *         <p>Este é o elemento que será monitorado para cliques fora.</p>
 *       </div>
 *     </div>
 *   );
 * }
 */
export function useClickOutside(callback) {
	const elementRef = useRef(null);
	const callbackRef = useRef(callback);

	useEffect(() => {
		const handler = evt => {
			const element = elementRef.current;
			if (element && !element.contains(evt.target)) {
				callbackRef.current(evt);
			}
		};

		document.addEventListener('mousedown', handler);
		document.addEventListener('touchstart', handler);

		return () => {
			document.removeEventListener('mousedown', handler);
			document.removeEventListener('touchstart', handler);
		};
	}, []);

	return elementRef;
}

/**
 * Aplica classes de estilo condicionalmente a um conjunto de estilos CSS.
 *
 * É usado para acessar elementos mais profundos e aninhados.
 * (ao contrário do className que é aplicado ao elemento raiz)
 *
 * @param css - Um objeto contendo as classes de estilo CSS.
 * @param classes - Um objeto contendo as classes de estilo condicional.
 * @param override - Indica se as classes de estilo devem substituir ou serem adicionadas às classes existentes.
 * @returns Um array contendo os estilos atualizados e a função `classnames`.
 */
export function useClassNames(css, classes, override) {
	const [styles, setStyles] = useState(css);
	useEffect(() => {
		if (classes) {
			setStyles(
				Object.keys(css).reduce((response, key) => {
					if (guard.isString(classes[key])) {
						response[key] = override ? classes[key] : `${css[key]} ${classes[key]}`;
					} else {
						response[key] = css[key];
					}
					return response;
				}, {}),
			);
		}
	}, [classes, css, override]);
	return [styles, classnames];
}

/**
 * This hook may be used with caution.
 * Use this when you want to run something only after the first render but some data that you
 * need inside it is beign updated many times, so you can't place this variable inside
 * dependency array.
 *
 * e.g. UserContextProvider effect needs pathName in the moment of the first render
 * @param cb
 */
export function useDidMountEffect(cb) {
	useEffect(cb, []);
}

/**
 * Returns the previous value
 * @param value
 * @returns {*}
 */
export function usePrevious(value) {
	const prevChildrenRef = useRef();
	useEffect(() => {
		prevChildrenRef.current = value;
	}, [value]);
	return prevChildrenRef.current;
}
