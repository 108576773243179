import LazyComponent from 'components/LazyComponent';
import {lazy} from 'react';
import fieldConfigRoutes from 'routes/Field-Configuration/routes';
import accountManagementRoutes from 'routes/account-management/routes';
import additionalInformationRoutes from 'routes/additional-information/routes';
import articleRoutes from 'routes/article/routes';
import bestMatchRoutes from 'routes/best-match/routes';
import campaignRoutes from 'routes/campaign/routes';
import channelUsesRoutes from 'routes/channel-uses/routes';
import channelRoutes from 'routes/channel/routes';
import complianceRoutes from 'routes/compliance/routes';
import conversationKeyword from 'routes/conversation-keyword/routes';
import deleteLeads from 'routes/delete-leads/routes';
import groupRoutes from 'routes/group/routes';
import hubRoutes from 'routes/hub/routes';
import importRoutes from 'routes/import/routes';
import integration from 'routes/integration/routes';
import intelDataRoutes from 'routes/intel-data/routes';
import clusterLeads from 'routes/lead-cluster/routes';
import leadRoutes from 'routes/lead/routes';
import megadashRoutes from 'routes/megadash/routes';
import notificationCenterRoutes from 'routes/notification-center/routes';
import pipelineRoutes from 'routes/pipeline/routes';
import platformIndicatorsRoutes from 'routes/platform-indicators/routes';
import privacyRoutes from 'routes/privacy/routes';
import productRoutes from 'routes/product/routes';
import redistributionRoutes from 'routes/redistribution/routes';
import registryRoutes from 'routes/registry/routes';
import settingsRoutes from 'routes/settings/routes';
import sourceRoutes from 'routes/source-generation/routes';
import statusRoutes from 'routes/status/routes';
import storeRoutes from 'routes/store/routes';
import surveyRoutes from 'routes/survey/routes';
import taskRoutes from 'routes/task/routes';
import teamRoutes from 'routes/teams/routes';
import templateMessageWhatsAppRoutes from 'routes/template-message-whatsapp/routes';
import templateMessageRoutes from 'routes/template-message/routes';
import ticketRoutes from 'routes/ticket/routes';
import timeTableRoutes from 'routes/timetable/routes';
import topicRoutes from 'routes/topic/routes';
import trainingAdminRoutes from 'routes/training-admin/routes';
import trainingRoutes from 'routes/training/routes';
import userRoutes from 'routes/user/routes';
import webhooksRoutes from 'routes/webhook/routes';
import RouteWrapper from './RouteWrapper';

const routes = [
	{
		component: RouteWrapper,
		path: '/',
		routes: [
			...additionalInformationRoutes,
			...fieldConfigRoutes,
			...channelRoutes,
			...groupRoutes,
			...hubRoutes,
			...megadashRoutes,
			...intelDataRoutes,
			...leadRoutes,
			...importRoutes,
			...storeRoutes,
			...topicRoutes,
			...articleRoutes,
			...settingsRoutes,
			...pipelineRoutes,
			...statusRoutes,
			...userRoutes,
			...timeTableRoutes,
			...templateMessageRoutes,
			...templateMessageWhatsAppRoutes,
			...notificationCenterRoutes,
			...webhooksRoutes,
			...sourceRoutes,
			...productRoutes,
			...complianceRoutes,
			...privacyRoutes,
			...trainingAdminRoutes,
			...trainingRoutes,
			...channelUsesRoutes,
			...bestMatchRoutes,
			...redistributionRoutes,
			...surveyRoutes,
			...ticketRoutes,
			...campaignRoutes,
			...platformIndicatorsRoutes,
			...teamRoutes,
			...deleteLeads,
			...clusterLeads,
			...conversationKeyword,
			...taskRoutes,
			...integration,
			...accountManagementRoutes,
			...registryRoutes,
			{
				component: LazyComponent(lazy(() => import('./routes/home'))),
				exact: true,
				path: '/',
			},
			{
				component: LazyComponent(lazy(() => import('./routes/home/v2/client'))),
				exact: true,
				path: '/client/:clientId',
			},
			{
				component: LazyComponent(lazy(() => import('./routes/home/v2/client/investments'))),
				exact: true,
				path: '/client/:clientId/investments/:investmentId',
			},
			{
				component: LazyComponent(lazy(() => import('./routes/home'))),
				exact: true,
				path: '/home',
			},
			{
				component: LazyComponent(lazy(() => import('./routes/chat/Chat'))),
				exact: true,
				path: '/chat',
			},
			{
				component: LazyComponent(lazy(() => import('./routes/chat/Chat'))),
				path: '/chat/:id',
			},
			{
				component: LazyComponent(lazy(() => import('./routes/login/LoginSellera'))),
				path: '/login',
			},
			{
				component: LazyComponent(lazy(() => import('./routes/login/LoginSellera'))),
				path: '/password-recover',
			},
			{
				component: LazyComponent(lazy(() => import('./routes/chart/Chart'))),
				path: '/chart/:id',
			},
			{
				component: LazyComponent(lazy(() => import('./routes/health/Health'))),
				path: '/health/',
			},
			{
				component: LazyComponent(lazy(() => import('./routes/embedded/Webphone'))),
				exact: true,
				path: '/webphone/:leadId/',
			},
			{
				component: LazyComponent(lazy(() => import('./routes/chart/Chart'))),
				exact: true,
				path: '/embed-mobile-dash/:id/',
			},
			{
				component: LazyComponent(lazy(() => import('./routes/not-found/NotFound'))),
			},
		],
	},
];

export default routes;
