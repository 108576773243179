/* eslint-disable import/prefer-default-export */

import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
	resetAppFeedback,
	setAppError,
	setAppInfoMessage,
	setAppSuccessMessage,
	setAppValue,
	setAppValueWithCb,
} from 'redux/actionCreators/app';

// TODO: estudar padrao de nomemclatura para esses custom hooks de redux
export function useAppValue(key) {
	return useSelector(state => state.app[key]);
}

export function useFeatureToggle(configKey) {
	const featureToggleList = useAppValue('featureToggleList');
	const showFeature = featureToggleList.find(data => {
		return data.featureId.key === configKey;
	});
	return {showFeature, featureToggleList};
}

export function useDispatchResetAppFeedback() {
	const dispatch = useDispatch();
	return useCallback(message => dispatch(resetAppFeedback(message)), [dispatch]);
}

export function useDispatchSetAppError() {
	const dispatch = useDispatch();
	return useCallback(message => dispatch(setAppError(message)), [dispatch]);
}

export function useDispatchSetAppInfoMesssage() {
	const dispatch = useDispatch();
	return useCallback(message => dispatch(setAppInfoMessage(message)), [dispatch]);
}

export function useDispatchSetAppSuccessMessage() {
	const dispatch = useDispatch();
	return useCallback(message => dispatch(setAppSuccessMessage(message)), [dispatch]);
}

// TODO: checar se precisa do useCallback
export function useDispatchSetAppValue(key) {
	const dispatch = useDispatch();
	return useCallback(
		value => dispatch(setAppValue(key, value)),
		[dispatch, key], // TODO: checar
	);
}

export function useDispatchSetAppValueWithCb(key, cbParamKey) {
	const dispatch = useDispatch();
	return useCallback(
		cb => dispatch(setAppValueWithCb(cb, key, cbParamKey)),
		[cbParamKey, dispatch, key], // TODO: checar se o undefined nao da merda aqui
	);
}
