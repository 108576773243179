import React from 'react';

export function AlertReadIcon({...props}) {
	return (
		<svg {...props} width='62' height='48' viewBox='0 0 62 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M32.8219 41.6688H15.2075C14.8955 41.6883 14.5877 41.5882 14.3469 41.3889C14.106 41.1896 13.9502 40.906 13.911 40.5958C13.8848 40.4359 13.8911 40.2723 13.9295 40.1148C13.9679 39.9574 14.0376 39.8092 14.1345 39.6793C14.8498 38.6287 15.5651 37.5781 16.2358 36.5051C17.5251 34.5699 18.4144 32.3962 18.8511 30.1121C18.8963 29.6213 18.8963 29.1275 18.8511 28.6368V19.1142C18.8335 17.2947 19.1362 15.4864 19.7452 13.7718C20.4063 11.8121 21.552 10.0512 23.0759 8.65283C24.6674 7.17153 26.6037 6.11119 28.7089 5.56807C28.9101 5.56807 28.9772 5.4563 28.9772 5.25512C28.9772 5.05394 28.9772 4.24922 28.9772 3.75745C28.9823 2.80757 29.345 1.89448 29.9932 1.20007C30.6413 0.505656 31.5272 0.0809017 32.4745 0.0104201C33.4218 -0.0600614 34.3609 0.228903 35.1046 0.819748C35.8484 1.41059 36.3423 2.25995 36.4879 3.19861C36.5326 3.86847 36.5326 4.54056 36.4879 5.21042C36.4879 5.43395 36.4879 5.52336 36.7785 5.59042C38.701 6.07929 40.4826 7.01107 41.9808 8.31131C43.479 9.61156 44.6523 11.2442 45.4069 13.0788C46.1089 14.6814 46.496 16.4041 46.547 18.153V23.6743C46.547 23.6743 46.547 26.9155 46.547 28.5473C46.5471 30.2639 46.9288 31.9589 47.6646 33.5098C48.572 35.4206 49.6492 37.2461 50.8834 38.964L51.3752 39.6793C51.5051 39.8789 51.5742 40.1118 51.5742 40.3499C51.5742 40.588 51.5051 40.821 51.3752 41.0205C51.2679 41.2269 51.1032 41.398 50.901 41.513C50.6987 41.628 50.4675 41.6821 50.2352 41.6688H32.8219ZM34.1184 5.0763C34.1184 4.65158 34.1184 4.20452 34.1184 3.75745C34.1189 3.43675 33.9963 3.12811 33.7758 2.89519C33.5554 2.66227 33.2539 2.52284 32.9337 2.50566C32.6284 2.48495 32.3263 2.57886 32.0866 2.76902C31.8468 2.95917 31.6866 3.23191 31.6372 3.53392C31.6037 4.0475 31.6037 4.56272 31.6372 5.0763H34.1184Z'
				fill='#E2E2E2'
			/>
			<path
				d='M38.6548 44.217C38.1744 45.3397 37.375 46.2966 36.3558 46.969C35.3365 47.6415 34.1424 48 32.9213 48C31.7002 48 30.5059 47.6415 29.4867 46.969C28.4674 46.2966 27.668 45.3397 27.1876 44.217H38.6548Z'
				fill='#E2E2E2'
			/>
			<rect
				width='66.7615'
				height='7.85429'
				transform='matrix(-0.866025 -0.5 -0.5 0.866025 61.8721 36.9927)'
				fill='#C4C4C4'
			/>
		</svg>
	);
}

AlertReadIcon.displayName = 'AlertReadIcon';
export default AlertReadIcon;
