import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

const notificationCenterRoutes = [
	{
		component: LazyComponent(lazy(() => import('./List'))),
		path: '/notification-center/list',
	},
	{
		component: LazyComponent(lazy(() => import('./Update'))),
		path: '/notification-center/update/:id',
	},
	{
		component: LazyComponent(lazy(() => import('./Create'))),
		path: '/notification-center/create',
	},
];

export default notificationCenterRoutes;
