import {Badge, makeStyles} from '@material-ui/core';
import NotificationsOutlined from '@material-ui/icons/NotificationsOutlined';
import {DataLayer, useEffectUpdate} from '@nimbus-target/ui';
import sound from 'assets/sound.mp3';
import IconButton from 'components/buttons/IconButton';
import Drawer from 'components/drawer/Drawer';
import {useSocketContext} from 'context';
import PropTypes from 'prop-types';
import {Fragment, useCallback, useEffect, useState} from 'react';
import {useAppValue, useDispatchSetAppValue} from 'redux/hooks/app';
import {useDispatchPost} from 'redux/hooks/fetch';
import useSound from 'use-sound';
import AlertsHeader from './Alerts.Header';
import AlertsMessages from './Alerts.Messages';

function gtag(...rest) {
	DataLayer.push(...rest.slice(1));
}

const useStyles = makeStyles(theme => ({
	icon: {
		fontSize: 28,
		[theme.breakpoints.down('1300')]: {
			fontSize: 22,
		},
		[theme.breakpoints.down('1100')]: {
			fontSize: 20,
		},
	},
}));

/**
 * @see https://miro.com/app/board/uXjVMd0Hd_8=/?moveToWidget=3458764558312418302&cot=14
 *
 * Layout:
 * @see https://www.figma.com/file/xTB5hP0E2D0ZabR6W0dQCW/Handoff-AII's?node-id=849%3A3368&mode=dev
 */
function AlertsButton() {
	const styles = useStyles();
	const {socketCore} = useSocketContext();
	const post = useDispatchPost();
	const [play] = useSound(sound);
	const countNotificationsNimbus = useAppValue('countNotificationsNimbus');
	const countNotificationsBTG = useAppValue('countNotificationsBTG');
	const setCountNotificationsNimbus = useDispatchSetAppValue('countNotificationsNimbus');
	const setCountNotificationsNotAccept = useDispatchSetAppValue('countNotificationsNotAccept');
	const setCountNotificationsBTG = useDispatchSetAppValue('countNotificationsBTG');
	const drawerNotifications = useAppValue('notificationsDrawer');
	const setDrawerNotifications = useDispatchSetAppValue('notificationsDrawer');
	const [isSwitchChecked, setSwitchChecked] = useState(false);
	const [tabValue, setTabValue] = useState('nimbus');
	const status = isSwitchChecked ? 'read' : 'unread';

	const getNotification = useCallback(() => {
		post('/notification-center/count', {channel: 'nimbus', status}).then(responseData => {
			setCountNotificationsNimbus(responseData.data);
			setCountNotificationsNotAccept(responseData.countNotAccepted);
		});
		post('/notification-center/count', {channel: 'btg', status}).then(responseData => {
			setCountNotificationsBTG(responseData.data);
		});
	}, [post, status, setCountNotificationsNimbus, setCountNotificationsNotAccept, setCountNotificationsBTG]);

	useEffect(() => {
		if (!socketCore) return undefined;
		const handleNotification = _data => {
			getNotification();
			play();
		};
		socketCore.on('notification', handleNotification);
		return () => {
			socketCore.removeListener('notification');
		};
	}, [getNotification, play, socketCore]);

	useEffect(() => {
		getNotification();
	}, [getNotification]);

	useEffectUpdate(() => {
		gtag('event', 'header_alerts_clicked', {
			opened: drawerNotifications,
		});
	}, [drawerNotifications]);

	return (
		<Fragment>
			<IconButton
				handleClick={() => setDrawerNotifications(true)}
				data-tour='tour_home_menu_notificaoes'
				alt='Mensagens'
			>
				<Badge
					badgeContent=' '
					max={9}
					variant='dot'
					overlap='circular'
					color='error'
					invisible={!(countNotificationsNimbus + countNotificationsBTG)}
				>
					<NotificationsOutlined className={styles.icon} />
				</Badge>
			</IconButton>
			<Drawer
				direction={'right'}
				isOpen={drawerNotifications}
				onClose={() => setDrawerNotifications(false)}
				header={
					<AlertsHeader
						nimbusLength={countNotificationsNimbus}
						btgLength={countNotificationsBTG}
						isSwitchChecked={isSwitchChecked}
						setSwitchChecked={setSwitchChecked}
						tabValue={tabValue}
						setTabValue={setTabValue}
						setDrawerNotifications={setDrawerNotifications}
					/>
				}
			>
				<AlertsMessages type={tabValue} status={status} />
			</Drawer>
		</Fragment>
	);
}

AlertsButton.propTypes = {
	type: PropTypes.oneOf(['nimbus', 'btg']),
};

AlertsButton.displayName = 'Alerts.Button';
export default AlertsButton;
