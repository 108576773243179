import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import GetApp from '@material-ui/icons/GetApp';
import AlertAttachmentModalPreviewText from './AlertAttachmentsModal.PreviewText';
import AlertAttachmentModalPreviewVideo from './AlertAttachmentsModal.PreviewVideo';
import AlertAttachmentModalPreviewImage from './AlertAttachmentsModal.PreviewImage';

export const AlertAttachmentModalPreview = ({attachment, description, title}) => {
	if (!attachment) {
		return <AlertAttachmentModalPreviewText title={title} description={description} />;
	}
	const type = attachment.type.toString().toLowerCase();
	if (type.includes('image')) {
		return <AlertAttachmentModalPreviewImage src={attachment.address} alt={title} />;
	}
	if (type.includes('video') || type.includes('application/x-mpegurl')) {
		return <AlertAttachmentModalPreviewVideo src={attachment.address} />;
	}
	return (
		<Box display='flex' flexDirection='column' alignItems='center' marginTop='12vh' textAlign='center' maxWidth='100%'>
			<h3 style={{paddingBottom: '1vh'}}>Não foi possível visualizar o arquivo</h3>
			<Box
				component='a'
				display='flex'
				flexDirection='column'
				alignItems='center'
				href={attachment.address}
				rel='noopener noreferrer'
				target='_blank'
				download={attachment.address}
			>
				<h4 style={{paddingBottom: '1vh'}}>Clique para baixar</h4>
				<GetApp style={{width: 30, height: 30}} />
			</Box>
		</Box>
	);
};

AlertAttachmentModalPreview.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	classes: PropTypes.object,
	label: PropTypes.string,
};

AlertAttachmentModalPreview.displayName = 'AlertAttachmentModalPreview';
export default AlertAttachmentModalPreview;
