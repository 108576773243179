import FeedbackMessage from 'components/FeedbackMessage';
import {useUserContext} from 'context/index';
import LoginModal from 'frame/LoginModal';
import WhatsappPerUserModal from 'frame/WhatsappPerUserModal';
import Header from 'header/Header';
import PropTypes from 'prop-types';
import {Fragment, useEffect, useRef, useState} from 'react';
import {renderRoutes} from 'react-router-config';
import {useLocation} from 'react-router-dom';
import {useAppValue, useDispatchSetAppValue} from 'redux/hooks/app';
import {useDispatchGet, useDispatchPost} from 'redux/hooks/fetch';

function RouteWrapper(props) {
	const {route} = props;

	const {
		name: username,
		email: useremail,
		_id: userid,
		isAuthenticationChecked,
		clientId,
		isAuthenticated,
		groupName,
	} = useUserContext();
	const jwt = localStorage.getItem('jwt');
	const client = useAppValue('clientData');
	const clientName = client?.name;

	const landbotFrame = useRef(document.getElementsByTagName('iframe'));

	const intervalLoadBot = useRef();

	const bubblePop = useRef(window.turn_off_bubble);

	const {pathname} = useLocation();

	const setLeadList = useDispatchSetAppValue('leadList');
	const setLeadLoading = useDispatchSetAppValue('loadingLeads');
	const setSelectedLeadData = useDispatchSetAppValue('selectedLeadData');
	const setSelectedLeadLoading = useDispatchSetAppValue('selectedLeadLoading');
	const setConversationMessages = useDispatchSetAppValue('conversationMessages');
	const setConversationLoading = useDispatchSetAppValue('conversationLoading');

	const setWhatsappPerUserModalAlreadyIgnored = useDispatchSetAppValue('whatsappPerUserModalAlreadyIgnored');

	const get = useDispatchGet();
	const setChannelList = useDispatchSetAppValue('channelList');
	const post = useDispatchPost();
	const setFeatureToggleList = useDispatchSetAppValue('featureToggleList');

	const setSupportMailbox = useDispatchSetAppValue('supportMailbox');

	const [openSupportBox, setOpenSupportBox] = useState(false);

	useEffect(() => {
		if (pathname.includes('/chat')) return;
		setLeadList([]);
		setLeadLoading(true);
		setSelectedLeadData({});
		setSelectedLeadLoading(true);
		setConversationMessages(null);
		setConversationLoading(true);
		setWhatsappPerUserModalAlreadyIgnored(false);
	}, [
		pathname,
		setConversationLoading,
		setConversationMessages,
		setLeadList,
		setLeadLoading,
		setSelectedLeadData,
		setSelectedLeadLoading,
		setWhatsappPerUserModalAlreadyIgnored,
	]);

	useEffect(() => {
		if (!clientId) return;
		get('/channel/list')
			.then(responseData => {
				setChannelList(responseData.data);
			})
			.catch(() => {});
		post('/feature/client-list', {clientId, type: 'featureToggle'})
			.then(responseData => {
				setFeatureToggleList(responseData.data);
			})
			.catch(err => {
				console.error(err);
			});
	}, [clientId, get, post, setChannelList, setFeatureToggleList]);

	useEffect(() => {
		const handleEvents = event => {
			if (event.data?.event === 'new_message') {
				if (!openSupportBox) {
					setSupportMailbox(1);
					bubblePop.current.play();
				}
			}
			if (event.data?.event === 'widget_open') {
				setOpenSupportBox(true);
				setSupportMailbox(0);
			}
			if (event.data?.event === 'widget_close') setOpenSupportBox(false);
		};
		window.addEventListener('message', handleEvents);
		return () => {
			window.removeEventListener('message', handleEvents);
		};
	}, [setSupportMailbox, setOpenSupportBox, openSupportBox]);

	useEffect(() => {
		setTimeout(() => {
			if (landbotFrame.current && landbotFrame.current[0]) {
				const landbotStyles = landbotFrame.current[0].contentWindow.document.getElementById('custom-styles');
				if (intervalLoadBot.current) clearInterval(intervalLoadBot.current);
				if (isAuthenticated && landbotStyles) {
					console.log('esconde bot');
					document.getElementById('landbot-styles').innerHTML = '.LandbotLivechat { display: none; }';
					landbotStyles.innerHTML = '.launcher__bubble {\n  display: none;\n}';
				} else if (isAuthenticationChecked && landbotStyles) {
					console.log('mostra bot');
					document.getElementById('landbot-styles').innerHTML = '';
					landbotStyles.innerHTML = '';

					// workaround, please someone improve this
					intervalLoadBot.current = setInterval(() => {
						document.getElementById('landbot-styles').innerHTML = '';
						landbotStyles.innerHTML = '';
					}, 5000);
				}
			}
		}, 1500);
	}, [isAuthenticated, isAuthenticationChecked, landbotFrame]);

	useEffect(() => {
		if (openSupportBox) {
			window.postMessage({
				source: 'nimbus',
				event: 'identify_client',
				userid: userid || null,
				username: username || null,
				useremail: useremail || undefined,
				clientname: clientName || undefined,
				pdvname: groupName || null,
			});
		}
	}, [clientName, groupName, jwt, openSupportBox, userid, username, useremail]);

	if (!isAuthenticationChecked) return null;
	return (
		<Fragment>
			<FeedbackMessage />
			<LoginModal />
			<WhatsappPerUserModal />
			<Header />
			{renderRoutes(route.routes)}
		</Fragment>
	);
}

RouteWrapper.propTypes = {
	route: PropTypes.instanceOf(Object).isRequired,
};

export default RouteWrapper;
