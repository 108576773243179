import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {useTheme} from '@material-ui/core';
import {useClickOutside} from 'utils/hooks';
import styles from './Dropdown.module.css';

function Dropdown({
	className = '',
	hasArrow = true,
	label = '',
	positionX = 'left',
	positionY = 'bottom',
	selected = false,
	labelStyle,
	outerButtonStyle,
	children,
}) {
	const theme = useTheme();
	const [isOpen, setIsOpen] = useState(false);
	const close = () => setIsOpen(false);
	const toggleOpen = () => setIsOpen(open => !open);
	const buttonRef = useRef(null);
	const dropdownRef = useClickOutside(evt => {
		if (buttonRef.current && !buttonRef.current.contains(evt.target)) {
			toggleOpen();
		}
	});

	return (
		<div
			className={cn(styles.dropdown, className, {
				[styles.dark]: theme.palette.type === 'dark',
			})}
		>
			<button
				ref={buttonRef}
				className={selected ? styles.openButtonSelected : styles.openButton}
				onClick={toggleOpen}
				role='menu'
				type='button'
			>
				<div style={{...labelStyle}} className={styles.label}>
					<div style={{...outerButtonStyle}}>{label}</div>
				</div>
				{hasArrow ? (
					<svg className={styles.arrow}>
						<use xlinkHref='#icon-feather-chevron-down' />
					</svg>
				) : null}
			</button>
			{isOpen && (
				<div
					ref={dropdownRef}
					className={cn(styles.menu, {
						[styles.menuRight]: positionX === 'right',
						[styles.menuTop]: positionY === 'top',
					})}
				>
					<div onClick={close}>{children({closeDropdown: close})}</div>
				</div>
			)}
		</div>
	);
}

Dropdown.propTypes = {
	children: PropTypes.func.isRequired,
	className: PropTypes.string,
	hasArrow: PropTypes.bool,
	label: PropTypes.node.isRequired,
	positionX: PropTypes.oneOf(['left', 'right']),
	positionY: PropTypes.oneOf(['bottom', 'top']),
	selected: PropTypes.bool,
};

Dropdown.displayName = 'Dropdown';
export default Dropdown;
