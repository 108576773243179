import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

const articleRoutes = [
	{
		component: LazyComponent(lazy(() => import('./List'))),
		path: '/article/list',
	},
	{
		component: LazyComponent(lazy(() => import('./Create'))),
		path: '/article/create',
	},
	{
		component: LazyComponent(lazy(() => import('./Update'))),
		path: '/article/update/:id',
	},
];

export default articleRoutes;
