import Box from '@material-ui/core/Box';
import {withStyles} from '@material-ui/core/styles';
import AttachmentIcon from '@material-ui/icons/Attachment';
import ImageIcon from '@material-ui/icons/Image';
import SlideshowIcon from '@material-ui/icons/Slideshow';
import IconButton from 'components/buttons/IconButton';
import PropTypes from 'prop-types';

const IconButtonLabel = withStyles({
	root: {
		marginLeft: 2,
		fontSize: 'small',
		fontFamily: '"Raleway", sans-serif',
		textAlign: 'start',
		wordBreak: 'break-word',
	},
})(Box);

/**
 * @envtest:
 * http://localhost:3000/notification-center/list
 * https://staging.nimbustarget.digital/notification-center/list
 *
 * @usage:
 * <AlertAttachmentModalButton
 *   key={`IconButton-${index}-${attachment.type.toString()}`}
 *   className={styles.iconButton}
 *   attachment={attachment}
 *   onClick={() => setSelectedAttachment(attachment)}
 *   selected={selectedAttachment === attachment}
 * />
 */
export const AlertAttachmentModalButton = ({className, attachment, onClick, selected}) => {
	const type = attachment.type.toString();
	const iconStyle = {width: 25, height: 25};
	const isVideo = type.includes('video') || type.includes('application/x-mpegurl');
	const isImage = type.includes('image');

	return (
		<IconButton className={className} handleClick={onClick}>
			{isImage && <ImageIcon style={iconStyle} />}
			{isVideo && <SlideshowIcon style={iconStyle} />}
			{!isImage && !isVideo && <AttachmentIcon style={iconStyle} />}
			<IconButtonLabel component='p' fontWeight={selected ? 'bold' : 'normal'}>
				{' '}
				{attachment.title}{' '}
			</IconButtonLabel>
		</IconButton>
	);
};

AlertAttachmentModalButton.propTypes = {
	attachment: PropTypes.shape({
		_id: PropTypes.string,
		type: PropTypes.string,
		name: PropTypes.string,
		title: PropTypes.string,
		address: PropTypes.string,
		size: PropTypes.number,
		notification: PropTypes.shape({
			_id: PropTypes.string,
			title: PropTypes.string,
			description: PropTypes.string,
			createdAt: PropTypes.string,
			link: PropTypes.string,
			linkType: PropTypes.oneOf(['external', 'filters', 'leadId', 'termos', 'survey', 'failedWhatsappMessage']),
			recipients: PropTypes.shape({
				status: PropTypes.oneOf(['read', 'unread']),
			}),
		}),
	}).isRequired,
	className: PropTypes.string,
	classes: PropTypes.object,
	onClick: PropTypes.func,
	selected: PropTypes.bool,
};

AlertAttachmentModalButton.displayName = 'AlertAttachmentModalButton';
export default AlertAttachmentModalButton;
