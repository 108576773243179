import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useAppValue, useDispatchSetAppValue} from 'redux/hooks/app';
import {useSocketContext} from 'context';
import IconButton from 'components/buttons/IconButton';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import {makeStyles} from '@material-ui/core';
import Webphone from './voip/Webphone';
import FeatureToggle from './FeatureToggle';
import PermissionToggle from './permission/PermissionToggle';
import Drawer from './drawer/Drawer';
import VoipDialer from '../header/VoipDialer';
import {useHistory} from 'react-router-dom';

import customStyles from './VoIpHeader.module.css';
import {formatPhone, mask} from 'utils/masks';

const useStyles = makeStyles(theme => ({
	voipIcon: {
		pointerEvents: 'initial',
		padding: 5,
		borderRadius: 20,
		color: props => (!props.state || props.state === 'off' ? 'rgba(0, 0, 0, 0.87)' : '#E6E6E6'),
		background: props =>
			props.state === 'onCall' || props.state === 'dialing'
				? '#217230'
				: !props.state || props.state === 'off'
				  ? '#F3F3F3'
				  : '#a99218',
		fontSize: 19,
		[theme.breakpoints.down('1300')]: {
			fontSize: 13,
		},
		[theme.breakpoints.down('1100')]: {
			fontSize: 13,
		},
		transition: 'backgroung .5s',
	},
	voipDialerIcon: {
		pointerEvents: 'initial',
		padding: 5,
		borderRadius: 20,
		color: props => (!props.voipDialerState || props.voipDialerState === 'off' ? 'rgba(0, 0, 0, 0.87)' : '#E6E6E6'),
		background: props =>
			props.voipDialerState === 'onCall' || props.voipDialerState === 'dialing'
				? '#217230'
				: !props.voipDialerState || props.voipDialerState === 'off'
				  ? '#F3F3F3'
				  : '#a99218',
		fontSize: 28,
		[theme.breakpoints.down('1300')]: {
			fontSize: 22,
		},
		[theme.breakpoints.down('1100')]: {
			fontSize: 20,
		},
		transition: 'backgroung .5s',
	},
	accessCallingLead: {
		pointerEvents: 'initial',
		cursor: 'pointer',
		backgroundColor: theme.palette.primary.main,
		zIndex: 9,
		position: 'absolute',
		padding: '7.5px 12.5px',
		borderRadius: 12.5,
		marginTop: '3%',
		textAlign: 'center',
	},
}));

function VoIpHeader() {
	const {socketCore} = useSocketContext();

	const setVoipModal = useDispatchSetAppValue('voip_modal');
	const state = useAppValue('VOIP_callStatus');
	const voipDialerState = useAppValue('VOIP_DIALER_callStatus');

	const currentLeadDataTalking = useAppValue('VOIP_DIALER_currentLeadDataTalking');
	/*
  const currentLeadDataTalking = {
    leadId: '602d882d9175f549d39154f0',
    customerName: 'Rosaria Pompeia Siola',
    phone: '41996695069',
  }
  */

	const history = useHistory();

	const [isWhatsappHidden, setIsWhatsappHidden] = useState(true);
	const fieldConfig = useAppValue('fieldConfig');

	const ringtone = useRef(window.ringtone);
	const ringbacktone = useRef(window.ringbacktone);

	const isTwilioVoip = useAppValue('is_twilio_voip_provider');

	const [voipDrawerOpen, setVoipDialerDrawer] = useState(false);

	const styles = useStyles({state, voipDialerState});

	// Voip Dialer call finished by lead state
	const setVoipDialerCurrentCallFinished = useDispatchSetAppValue('VOIP_DIALER_currentCallFinished');
	const setCurrentLeadDataTalking = useDispatchSetAppValue('VOIP_DIALER_currentLeadDataTalking');

	function stopRingbackTone() {
		try {
			ringbacktone.current.pause();
		} catch (e) {}
	}

	function stopRingTone() {
		try {
			ringtone.current.pause();
		} catch (e) {}
	}

	// Socket event - Only Dialer (Call finished - hang up by the lead)
	useEffect(() => {
		if (!socketCore) return;

		const handleCallFinished = () => {
			if (voipDialerState === 'onCall' || voipDialerState === 'dialing' || voipDialerState === 'ringing') {
				setVoipDialerCurrentCallFinished(true);
			}
		};

		socketCore.on('voip-dialer-call-finished', handleCallFinished);
		return () => {
			socketCore.removeListener('voip-dialer-call-finished', handleCallFinished);
		};
	}, [socketCore, setVoipDialerCurrentCallFinished, voipDialerState]);

	// Socket events (Call answered - change lead)
	useEffect(() => {
		if (!socketCore) return;
		const handleAnsweredCall = leadData => {
			if (!leadData) return;
			// Force stop ringtone
			stopRingbackTone();
			stopRingTone();
			// Set lead data to show who the sales is talking to
			setCurrentLeadDataTalking(leadData);
		};
		socketCore.on('voip-dialer-answered-call', handleAnsweredCall);
		return () => {
			socketCore.removeListener('voip-dialer-answered-call', handleAnsweredCall);
		};
	}, [setCurrentLeadDataTalking, socketCore]);

	const accessLead = leadId => {
		history.push(`/chat/${leadId}`);
	};

	useEffect(() => {
		if (!fieldConfig) return;
		const whatsappHidden = fieldConfig.find(i => i.key === 'phoneWhatsapp' && i.isHidden);
		setIsWhatsappHidden(whatsappHidden);
	}, [fieldConfig]);

	return (
		<Fragment>
			{/** Common voip */}
			{(state === 'onCall' || state === 'dialing' || state === 'ringing') && (
				<IconButton handleClick={() => setVoipModal(true)}>
					<CallOutlinedIcon className={styles.voipIcon} />
				</IconButton>
			)}
			<Webphone />
			{/** Voip Dialer */}
			<FeatureToggle configKey='voipDialer'>
				<PermissionToggle permissionKey='voipDialer' hasFeedback={false}>
					{isTwilioVoip && (
						<Fragment>
							<div style={{display: 'flex', justifyContent: 'center'}}>
								<IconButton
									handleClick={() => setVoipDialerDrawer(true)}
									className={
										voipDialerState === 'onCall' || voipDialerState === 'dialing' ? customStyles.pulseEffect : ''
									}
									disabled={state === 'onCall' || state === 'dialing' || state === 'ringing'}
									alt='Discadora automática'
								>
									<CallOutlinedIcon className={styles.voipDialerIcon} />
								</IconButton>
								{voipDialerState === 'onCall' && currentLeadDataTalking && (
									<div className={styles.accessCallingLead} onClick={() => accessLead(currentLeadDataTalking.leadId)}>
										Em ligação com
										<br />
										{currentLeadDataTalking.customerName} -{' '}
										{isWhatsappHidden ? mask(currentLeadDataTalking.phone) : formatPhone(currentLeadDataTalking.phone)}
										<br />
										<br />
										Acessar
									</div>
								)}
							</div>
							<Drawer
								title={'Discadora automática'}
								direction={'right'}
								isOpen={voipDrawerOpen}
								onClose={() => setVoipDialerDrawer(false)}
								enableBackdropInteraction={true}
							>
								<VoipDialer />
							</Drawer>
						</Fragment>
					)}
					{!isTwilioVoip && <Fragment></Fragment>}
				</PermissionToggle>
			</FeatureToggle>
		</Fragment>
	);
}

VoIpHeader.propTypes = {};

export default VoIpHeader;
