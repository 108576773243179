export function resetAppFeedback() {
  return {
    type: 'RESET_APP_FEEDBACK',
  };
}

export function setAppError(message) {
  return {
    payload: {
      message,
      type: 'error',
    },
    type: 'SET_APP_FEEDBACK',
  };
}

export function setAppInfoMessage(message) {
  return {
    payload: {
      message,
      type: 'info',
    },
    type: 'SET_APP_FEEDBACK',
  };
}

export function setAppSuccessMessage(message) {
  return {
    payload: {
      message,
      type: 'success',
    },
    type: 'SET_APP_FEEDBACK',
  };
}

export function setAppValue(key, value) {
  return {
    payload: {
      key,
      value,
    },
    type: 'SET_APP_VALUE',
  };
}

export function setAppValueWithCb(cb, key, cbParamKey) {
  return {
    payload: {
      cb,
      cbParamKey,
      key,
    },
    type: 'SET_APP_VALUE_WITH_CB',
  };
}
