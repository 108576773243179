import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

const additionalInformationRoutes = [
	{
		component: LazyComponent(lazy(() => import('./Create'))),
		path: '/additional-information/create',
	},
	{
		component: LazyComponent(lazy(() => import('./Update'))),
		path: '/additional-information/update/:id',
	},
];

export default additionalInformationRoutes;
