import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
	root: {},
	image: {
		maxWidth: '100%',
	},
}));

export const AlertAttachmentModalPreviewImage = ({src, alt, children, ...props}) => {
	const styles = useStyles();
	return (
		<Box display='flex' justifyContent='center' width='100%' maxWidth='100%' {...props}>
			<img className={styles.image} src={src} alt={alt} />
		</Box>
	);
};

AlertAttachmentModalPreviewImage.propTypes = {
	alt: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
	classes: PropTypes.object,
	src: PropTypes.string,
};

AlertAttachmentModalPreviewImage.displayName = ' AlertAttachmentModal.PreviewImage';
export default AlertAttachmentModalPreviewImage;
