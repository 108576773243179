import {IconButton, makeStyles, Paper, Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import cn from 'classnames';
import Button from 'components/buttons/Button';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
	modalBorder: {
		borderRadius: theme.spacing(0.5),
	},
	modalContent: {
		padding: '0px 6%',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	title: {
		textAlign: 'center',
		marginTop: theme.spacing(3),
		left: 'auto',
	},
	subtitle: {
		marginTop: theme.spacing(1),
	},
	actions: {
		background: theme.palette.type === 'light' ? '#F3F3F3' : '#2C2E35',
		borderRadius: `0 0 ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
		padding: '30px 10%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
}));
function CustomPaperComponent({className: paperClassName, ...paperProps}) {
	const styles = useStyles();
	return <Paper className={cn(paperClassName, styles.modalBorder)} {...paperProps} />;
}
function Modal(props) {
	const {
		className,
		actions,
		actionsComponent,
		isOpen = false,
		children,
		disableActions,
		hideCloseIcon = false,
		handleClose,
		handleLeftButtonClick,
		handleRightButtonClick,
		handleRightButtonForm,
		title,
		textAlignment,
		subTitle,
		fullWidth,
		maxWidth,
		fullScreen,
		leftButtonTitle,
		rightActionType,
		rightButtonTitle,
		disableEnforceFocus, // Pass true here to use Tinymce inside this modal
		disableBackdropClick,
		onScroll,
		modalPadding,
		alternativeScroll = {},
	} = props;

	const styles = useStyles();

	const handleCloseModal = () => {
		if (handleClose) handleClose();
	};

	// Handle Actions
	const handleLeftAction = () => {
		if (handleLeftButtonClick) handleLeftButtonClick();
	};

	const handleRightAction = () => {
		if (handleRightButtonClick) handleRightButtonClick();
	};

	const handleScroll = evt => {
		if (onScroll) {
			onScroll(evt);
		}
	};

	return (
		<Dialog
			open={isOpen}
			className={className}
			PaperComponent={CustomPaperComponent}
			aria-labelledby='dialog'
			aria-describedby='description'
			maxWidth={maxWidth}
			fullWidth={fullWidth}
			fullScreen={fullScreen}
			disableEnforceFocus={disableEnforceFocus}
			// @see https://stackoverflow.com/a/69179372/1345327
			disableBackdropClick={disableBackdropClick}
			// onClose={(_evt, reason) => {
			//   if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
			//     setOpen(true);
			//   }
			// }}
			onClose={handleCloseModal}
		>
			<DialogTitle disableTypography={true} className={styles.title} id='dialog'>
				<Typography variant='h5' align={textAlignment}>
					{title}
				</Typography>
				{subTitle && (
					<Typography variant='subtitle1' color='primary' align={textAlignment} className={styles.subtitle}>
						{subTitle}
					</Typography>
				)}
				{!hideCloseIcon && (
					<IconButton className={styles.closeButton} aria-label='close' onClick={handleCloseModal}>
						<CloseIcon />
					</IconButton>
				)}
			</DialogTitle>
			<DialogContent
				onScroll={handleScroll}
				className={styles.modalContent}
				style={{
					padding: modalPadding,
					...alternativeScroll,
				}}
			>
				<DialogContentText id='description' component='div'>
					{children(props)}
				</DialogContentText>
			</DialogContent>
			{actions && (
				<DialogActions className={styles.actions}>
					<Button handleClick={handleLeftAction} color='primary' variant='outlined' disabled={disableActions}>
						{leftButtonTitle}
					</Button>
					{rightButtonTitle && (
						<Button
							handleClick={handleRightAction}
							color='primary'
							variant='contained'
							type={rightActionType}
							form={handleRightButtonForm}
							disabled={disableActions}
						>
							{rightButtonTitle}
						</Button>
					)}
				</DialogActions>
			)}
			{actionsComponent && actionsComponent()}
		</Dialog>
	);
}

Modal.propTypes = {
	actions: PropTypes.bool,
	actionsComponent: PropTypes.func,
	children: PropTypes.func, // Função pq precisa passar o handleClose
	className: PropTypes.string,
	disableActions: PropTypes.bool,
	disableBackdropClick: PropTypes.bool,
	disableEnforceFocus: PropTypes.bool, // Pass true here to use Tinymce inside this modal
	fullScreen: PropTypes.bool,
	fullWidth: PropTypes.bool,
	handleClose: PropTypes.func,
	handleLeftButtonClick: PropTypes.func,
	handleRightButtonClick: PropTypes.func,
	handleRightButtonForm: PropTypes.string,
	hideCloseIcon: PropTypes.bool,
	isOpen: PropTypes.bool,
	leftButtonTitle: PropTypes.string,
	maxWidth: PropTypes.string, //xs, md, sm, lg, xl
	onScroll: PropTypes.func,
	rightActionType: PropTypes.string,
	rightButtonTitle: PropTypes.string,
	subTitle: PropTypes.string,
	textAlignment: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
	title: PropTypes.string,
};

export default Modal;
