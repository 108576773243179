import {IconButton, InputAdornment, InputLabel} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialSelect from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';
import {useDispatchGet, useDispatchPost} from 'redux/hooks/fetch';
import {getError} from '../form/utils.js';

const Select = React.forwardRef((props, ref) => {
	const {
		adornmentIcon,
		adornmentButtonType,
		className,
		classNameSkeleton,
		incomingValue,
		isRequired,
		defaultOptions,
		disabled,
		dataOptions,
		errors,
		helperText,
		hasEmptyOption, //not allowing emptyOption with multiple
		label,
		name,
		onChange,
		changeByState,
		handleAdornmentClick,
		control,
		margin,
		multiple, //not allowing emptyOption with multiple
		requestMethod,
		requestParams,
		requestPath,
		requestResultParser,
		fullWidth,
		inicialValues,
		displayEmpty,
	} = props;

	const [options, setOptions] = useState(null);
	const [skeletonActive, setSkeletonActive] = useState(true);

	const handleAdornment = () => {
		if (handleAdornmentClick) handleAdornmentClick();
	};

	//const [requestParamsState, setRequestParamsState] = useState(requestParams || {});
	//const requestParamsState = useAppValue('requestParamsSelect');
	//const setRequestParamsState =  useDispatchSetAppValue('requestParamsSelect');

	const get = useDispatchGet();
	const post = useDispatchPost();

	useEffect(() => {
		if (defaultOptions && defaultOptions.length > 0) {
			setOptions(requestResultParser(defaultOptions));
			setSkeletonActive(false);
			return;
		}
	}, [defaultOptions, requestResultParser]);

	useEffect(() => {
		if (!requestMethod || !requestPath) return;
		const requestMethods = {get, post};
		const request = requestMethods[requestMethod];
		request(requestPath, requestParams)
			.then(responseData => {
				setOptions(requestResultParser(responseData.data));
				setSkeletonActive(false);
			})
			.catch(() => {});
	}, [requestMethod, requestPath, requestParams, requestResultParser, get, post]);

	useEffect(() => {
		if (dataOptions) {
			setOptions(dataOptions);
			setSkeletonActive(false);
		}
	}, [dataOptions]);

	const handleChange = e => {
		if (onChange) onChange(e);
	};

	const id = name; // Will always be the same as name
	const error = getError(errors, name);

	return (
		<FormControl fullWidth={fullWidth} margin={margin}>
			{label && !skeletonActive && <InputLabel id={id}>{label}</InputLabel>}
			{skeletonActive && <Skeleton variant='rect' height='25px' className={classNameSkeleton} />}
			{!skeletonActive && (
				<Controller
					control={control}
					errors={error}
					name={name}
					rules={{required: isRequired}}
					render={({onChange, name, value, ref}) => (
						<MaterialSelect
							displayEmpty={displayEmpty}
							inicialValues={inicialValues ? inicialValues : null}
							className={className}
							error={error ? true : false}
							defaultValue={incomingValue}
							disabled={disabled}
							value={changeByState ? incomingValue : value}
							id={name}
							name={name}
							onChange={e => {
								onChange(e);
								handleChange(e);
							}}
							inputRef={ref}
							multiple={multiple}
							endAdornment={
								adornmentIcon ? (
									<InputAdornment position='end'>
										<IconButton onClick={handleAdornment} type={adornmentButtonType}>
											{adornmentIcon}
										</IconButton>
									</InputAdornment>
								) : (
									<InputAdornment position='end'>
										<span />
									</InputAdornment>
								)
							}
						>
							{hasEmptyOption && !multiple ? (
								<MenuItem value={''} disabled={false}>
									Selecione
								</MenuItem>
							) : null}
							{options.map(data => {
								if (!data) return null;
								// eslint-disable-next-line no-shadow
								const {disabled: optionDisabled, id, label, is_group_name} = data;

								if (is_group_name) {
									return (
										<option
											disabled={true}
											key={id}
											value={id}
											style={{paddingLeft: '2rem', paddingTop: '0.5rem', fontWeight: 'bold', fontStyle: 'italic'}}
										>
											{label}
										</option>
									);
								}

								return (
									<MenuItem disabled={optionDisabled} key={id} value={id}>
										{label}
									</MenuItem>
								);
							})}
						</MaterialSelect>
					)}
				/>
			)}
			<FormHelperText>{error ? helperText : ''}</FormHelperText>
		</FormControl>
	);
});

Select.propTypes = {
	changeByState: PropTypes.bool,
	className: PropTypes.string,
	control: PropTypes.instanceOf(Object),
	dataOptions: PropTypes.instanceOf(Object),
	defaultOptions: PropTypes.instanceOf(Array),
	disabled: PropTypes.bool,
	errors: PropTypes.instanceOf(Object),
	fullWidth: PropTypes.bool,
	hasEmptyOption: PropTypes.bool,
	helperText: PropTypes.string,
	incomingValue: PropTypes.node,
	isRequired: PropTypes.bool,
	label: PropTypes.string,
	margin: PropTypes.string,
	multiple: PropTypes.bool,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	requestMethod: PropTypes.string,
	requestParams: PropTypes.instanceOf(Object),
	requestPath: PropTypes.string,
	requestResultParser: PropTypes.func,
};

Select.defaultProps = {
	fullWidth: true,
	hasEmptyOption: true,
	disabled: false,
	isRequired: false,
	multiple: false,
	requestMethod: 'get',
	requestResultParser: data => data.map(value => ({disabled: false, id: value, label: value})),
};

export default Select;
