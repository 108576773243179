import React, {Fragment, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Typography, Card, CardContent} from '@material-ui/core';
import SlideshowIcon from '@material-ui/icons/Slideshow';
import ImageIcon from '@material-ui/icons/Image';
import AttachmentIcon from '@material-ui/icons/Attachment';
import MessageIcon from '@material-ui/icons/Message';
import GetApp from '@material-ui/icons/GetApp';
import IconButton from 'components/buttons/IconButton';
import styles from './NotificationAttachmentsModal.module.css';

export const NotificationAttachmentsModal = ({notification: {attachments, title, description}}) => {
	const [selectedAtt, setSelectedAtt] = useState(null);

	const renderContent = useCallback(
		att => {
			if (!att) {
				return <Typography style={{whiteSpace: 'pre-wrap'}}>{description}</Typography>;
			}
			const type = att.type.toString();
			if (type.includes('image')) {
				return (
					<div className={styles.media}>
						<img alt={title} src={att.address} />
					</div>
				);
			} else if (type.includes('video')) {
				return (
					<div className={styles.media}>
						<video controls={true}>
							<source src={att.address} type='video/mp4' />
						</video>
					</div>
				);
			} else {
				return (
					<div>
						<div className={styles.downloadTextDiv}>
							<h3 style={{paddingBottom: '1vh'}}>Não foi possível visualizar o arquivo</h3>
							<a
								className={styles.download}
								href={att.address}
								rel='noopener noreferrer'
								target='_blank'
								download={att.address}
							>
								<h4 style={{paddingBottom: '1vh'}}>Clique para baixar</h4>
								<GetApp style={{width: 30, height: 30}} />
							</a>
						</div>
					</div>
				);
			}
		},
		[description, title],
	);

	return (
		<Fragment>
			<div className={styles.containerDiv}>
				<div className={styles.buttonsRow}>
					<IconButton
						className={styles.iconButton}
						handleClick={() => {
							setSelectedAtt(null);
						}}
					>
						<MessageIcon style={{width: 25, height: 25}} />
						<p className={styles.iconText} style={!selectedAtt ? {fontWeight: 'bold'} : {}}>
							{' '}
							Corpo da notificação
						</p>
					</IconButton>
					{attachments.map((att, index) => {
						const type = att.type.toString();
						return (
							<IconButton
								key={`IconButton-${index}-${type}`}
								className={styles.iconButton}
								handleClick={() => {
									setSelectedAtt(att);
								}}
							>
								{type.includes('image') && <ImageIcon style={{width: 25, height: 25}} />}
								{type.includes('video') && <SlideshowIcon style={{width: 25, height: 25}} />}
								{!type.includes('image') && !type.includes('video') && (
									<AttachmentIcon style={{width: 25, height: 25}} />
								)}
								<p
									className={styles.iconText}
									style={selectedAtt === att ? {fontWeight: 'bold'} : {fontWeight: 'normal'}}
								>
									{' '}
									{att.title}{' '}
								</p>
							</IconButton>
						);
					})}
				</div>

				<div className={styles.content}>
					<Card variant={'elevation'} className={styles.card}>
						<CardContent className={styles.cardContent}>
							<h2>{selectedAtt ? selectedAtt.title : title}</h2>
							{renderContent(selectedAtt)}
						</CardContent>
					</Card>
				</div>
			</div>
		</Fragment>
	);
};

NotificationAttachmentsModal.propTypes = {
	className: PropTypes.string,
	notification: PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.string,
		attachments: PropTypes.arrayOf(
			PropTypes.shape({
				type: PropTypes.string,
				title: PropTypes.string,
				address: PropTypes.string,
			}),
		),
	}),
};

NotificationAttachmentsModal.displayName = 'NotificationAttachmentsModal';
export default NotificationAttachmentsModal;
