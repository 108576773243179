import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

const productRoutes = [
	{
		component: LazyComponent(lazy(() => import('./Create'))),
		path: '/product/create',
	},
	{
		component: LazyComponent(lazy(() => import('./Update'))),
		path: '/product/update/:id',
	},
];

export default productRoutes;
