import {Button, TextField, makeStyles} from '@material-ui/core';
import Modal from 'components/modal/Modal';
import {
	useAppValue,
	useDispatchSetAppError,
	useDispatchSetAppSuccessMessage,
	useDispatchSetAppValue,
} from 'redux/hooks/app';
import {useDispatchPost} from 'redux/hooks/fetch';

import RadioGroup from 'components/inputs/RadioGroup';
import {useSocketContext, useUserContext} from 'context';
import {useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';

export default function SurveyModal(props) {
	const useStyles = makeStyles(theme => ({
		input: {
			marginBottom: 40,
		},
		content: {
			paddingBlock: 10,
			paddingInline: 20,
			fontSize: 16,
			fontFamily: 'Inter',
			color: '#404040',
			whiteSpace: 'pre-line',
			marginBottom: 40,
		},
	}));

	const classes = useStyles();
	const post = useDispatchPost();

	const {socketCore} = useSocketContext();

	const {nickname, name} = useUserContext();

	const openSurvey = useAppValue('openSurvey');
	const setOpenSurvey = useDispatchSetAppValue('openSurvey');
	const setErrorMessage = useDispatchSetAppError();
	const setAppSuccess = useDispatchSetAppSuccessMessage();

	// const [isModalOpen, setIsModalOpen] = useState(false);

	const [unansweredSurveys, setUnansweredSurveys] = useState([]);
	const [answers, setAnswers] = useState({});

	const {register, control} = useForm({});

	// initialize the answers object
	useEffect(() => {
		console.log('triggering useeffect');
		if (openSurvey) {
			const aux = {};
			openSurvey.survey.forEach(field => {
				aux[field._id] = '';
			});
			setAnswers(aux);
		} else {
			setAnswers({});
		}
	}, [openSurvey]);

	// get all unanswered surveys that are to be notified
	useEffect(() => {
		post('/notification-center/list-unanswered-surveys')
			.then(responseData => {
				console.log('unanswered surveys: ', responseData.data);
				setUnansweredSurveys(responseData.data);
				if (responseData.data.length && !openSurvey) {
					console.log('here3');
					setOpenSurvey(responseData.data[0]);
				}
			})
			.catch(err => {
				console.error('err fetching surveys', err);
			});
	}, [openSurvey, post, setOpenSurvey]);

	// get the incoming socket from survey notifications
	useEffect(() => {
		if (!socketCore) return;

		const handleNewSurveyNotification = surveyNotification => {
			console.log('new survey: ', surveyNotification);
			setUnansweredSurveys([...unansweredSurveys, surveyNotification]);
			// if theres no open survey, set the new one
			if (!openSurvey) {
				setOpenSurvey(surveyNotification);
			}
		};

		socketCore.on('survey-notification', handleNewSurveyNotification);
		return () => {
			socketCore.removeListener('survey-notification', handleNewSurveyNotification);
		};
	}, [unansweredSurveys, socketCore, openSurvey, setOpenSurvey]);

	const handleClosing = () => {
		// remove the current survey from the list
		let auxUnansweredSurveys = [...unansweredSurveys];
		auxUnansweredSurveys = JSON.parse(JSON.stringify(auxUnansweredSurveys));
		auxUnansweredSurveys = auxUnansweredSurveys.filter(surv => surv._id !== openSurvey._id);
		setUnansweredSurveys(auxUnansweredSurveys);

		// set notification as viewed so the popup doesn't show up again till the next day
		post('/notification-center/set-read', {id: openSurvey._id, read: true}).then(() => {
			setOpenSurvey(null);
			// and if there is any, open the next after a sec
			if (auxUnansweredSurveys.length) {
				setTimeout(() => {
					setOpenSurvey(auxUnansweredSurveys[0]);
				}, 1000);
			}
		});
	};

	const handleSubmit = async () => {
		// check again just to be sure
		const checkingResult = openSurvey.survey.map((question, i) => {
			if ((!answers[question._id] || answers[question._id] === ' ') && question.required) {
				setErrorMessage(`O campo ${i + 1} é obrigatório!`);
				return false;
			} else return true;
		});

		if (checkingResult.includes(false)) {
			return;
		}

		await post('/survey/save', {
			idNotification: openSurvey._id,
			answer: answers,
		})
			.then(() => {
				handleClosing();
				setAppSuccess('Pesquisa enviada com sucesso');
			})
			.catch(() => {
				setErrorMessage('Não foi possível enviar sua pesquisa, tente novamente mais tarde');
			});
	};

	const areRequiredAnswered = useMemo(() => {
		if (!openSurvey) return;
		return openSurvey.survey
			.map((question, i) => {
				if ((!answers[question._id] || answers[question._id] === ' ') && question.required) {
					return false;
				} else return true;
			})
			.every(e => e);
	}, [answers, openSurvey]);

	if (!openSurvey) return null;

	return (
		<div>
			<link rel='preconnect' href='https://fonts.googleapis.com' />
			<link rel='preconnect' href='https://fonts.gstatic.com' crossorigin={true} />
			<link
				href='https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&family=Lato&family=Raleway:wght@200;400&display=swap'
				rel='stylesheet'
			/>
			<Modal
				isOpen={openSurvey}
				fullWidth={true}
				maxWidth='md'
				title={''}
				hideCloseIcon={false}
				handleClose={() => {
					handleClosing();
				}}
				modalPadding='0px'
				alternativeScroll={{
					'&::-webkit-scrollbar': {
						width: '4px',
					},
					'&::-webkit-scrollbar-track': {
						background: '#f1f1f1',
					},
					'&::-webkit-scrollbar-thumb': {
						background: '#888',
						borderRadius: '8px',
					},
				}}
			>
				{({handleClose}) => {
					return (
						<div className={classes.content}>
							<div style={{color: '#404040', fontWeight: 600, fontSize: 20, marginBottom: 20}}>
								Olá, {nickname || name}!
							</div>
							<div style={{marginBottom: 40, fontWeight: 500}}>{openSurvey.description}</div>
							<div>
								{openSurvey.survey.map((field, i) => {
									let options;
									if (field.fieldType === 'multiplo') {
										options = field.fieldOptions.split('\n').map(e => {
											return {
												label: e,
											};
										});
									}
									return (
										/* sort of a hack to separate the indexes and the actual content, as per design */
										<div style={{marginBottom: 40, display: 'flex', flexDirection: 'row'}}>
											{/* indexes of the questions */}
											<div style={{display: 'flex', flexDirection: 'column'}}>
												<span style={{marginRight: 10}}>{i + 1}.</span>
											</div>
											{/* actual content of the questions */}
											<div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
												{field.fieldType === 'csat' && (
													<div style={{display: 'flex', flexDirection: 'column'}}>
														<div style={{marginBottom: 20}}>{`${field.name} ${field.required ? '*' : ''}`}</div>
														<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
															{['#AE2B0F', '#FF6622', 'rgba(0,0,0,0.6)', '#35B1CD', '#35B1CD'].map((color, index) => (
																<div
																	style={{
																		backgroundColor: color,
																		width: 50,
																		height: 50,
																		cursor: 'pointer',
																		display: 'flex',
																		justifyContent: 'center',
																		alignItems: 'center',
																		transform: answers[field._id] === index + 1 ? 'scale(1.1)' : 'scale(1)',
																		boxShadow: answers[field._id] === index + 1 ? `0px 0px 10px 5px ${color}` : 'none',
																		// another option: '0px 0px 10px 0px rgba(0,0,0,0.75)' : '0px 0px 0px 0px rgba(0,0,0,0.75)',
																		transition: 'transform 0.2s',
																	}}
																	onClick={e => {
																		let auxAnswers = {...answers};
																		if (auxAnswers[field._id] === index + 1) {
																			auxAnswers[field._id] = '';
																		} else {
																			auxAnswers[field._id] = index + 1;
																		}
																		setAnswers(auxAnswers);
																	}}
																>
																	<div style={{color: 'white', textAlign: 'center'}}>{index + 1}</div>
																</div>
															))}
														</div>
														<div
															style={{
																display: 'flex',
																flexDirection: 'row',
																justifyContent: 'space-between',
																fontWeight: 500,
																marginTop: 10,
															}}
														>
															<span>Muito insatisfeito</span>
															<span style={{marginRight: '1%'}}>Neutro</span>
															<span>Muito satisfeito</span>
														</div>
													</div>
												)}
												{field.fieldType === 'texto' && (
													<div>
														<div style={{marginBottom: 20}}>{field.name}</div>
														<div
															style={{
																fontSize: 11,
																fontWeight: 500,
																marginBottom: `${answers[field._id]?.length ? 0 : '-2%'}`,
															}}
														>
															COMPARTILHE SEUS PENSAMENTOS
														</div>
														<TextField
															label={`${answers[field._id]?.length ? '' : 'Escreva aqui'}`}
															InputLabelProps={{shrink: false}}
															value={answers[field._id] || ''}
															onChange={e => {
																let auxAnswers = {...answers};
																auxAnswers[field._id] = e.target.value;
																setAnswers(auxAnswers);
															}}
															multiline={true}
															minRows={6}
															fullWidth={true}
															variant='standard'
														/>
													</div>
												)}
												{field.fieldType === 'nps' && (
													<div className={classes.input} style={{display: 'flex', flexDirection: 'column', width: 600}}>
														{/* just a copy of the old one for now */}
														<RadioGroup
															label={`${field.name} ${field.required ? '*' : ''}`}
															name={field.name}
															control={control}
															onChange={e => {
																let auxAnswers = {...answers};
																auxAnswers[field._id] = e.target.value;
																setAnswers(auxAnswers);
															}}
															options={[
																{value: 1, label: '1'},
																{value: 2, label: '2'},
																{value: 3, label: '3'},
																{value: 4, label: '4'},
																{value: 5, label: '5'},
																{value: 6, label: '6'},
																{value: 7, label: '7'},
																{value: 8, label: '8'},
																{value: 9, label: '9'},
																{value: 10, label: '10'},
															]}
															ref={register({required: false})}
															row={true}
														/>
														<div style={{display: 'flex', justifyContent: 'space-between'}}>
															<span style={{position: 'relative', fontSize: 'small'}}>Nem um pouco provável</span>
															<span style={{position: 'relative', fontSize: 'small'}}>Extremamente provável</span>
														</div>
													</div>
												)}
												{field.fieldType === 'multiplo' && (
													<div className={classes.input}>
														{/* just a copy of the old one for now */}
														<RadioGroup
															className={classes.inputNps}
															label={`${field.name} ${field.required ? '*' : ''}`}
															onChange={e => {
																let auxAnswers = {...answers};
																auxAnswers[field._id] = e.target.value;
																setAnswers(auxAnswers);
															}}
															name={field.name}
															control={control}
															options={options}
															ref={register({required: false})}
														/>
													</div>
												)}
											</div>
										</div>
									);
								})}
							</div>
							{/* action buttons (close & send answer) */}
							<div
								style={{
									position: 'absolute',
									bottom: 0,
									left: 0,
									width: '100%',
									padding: 20,
									backgroundColor: 'white',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
									<Button
										onClick={handleClosing}
										variant='contained'
										style={{
											width: '20%',
											height: 40,
											backgroundColor: '#E9EEF5',
											color: '#22589F',
											textTransform: 'none',
											borderRadius: 5,
											fontSize: '11.5pt',
											fontWeight: 'bold',
											marginLeft: '2.5%',
										}}
									>
										Responder depois
									</Button>
									<Button
										onClick={handleSubmit}
										variant='contained'
										disabled={!areRequiredAnswered}
										style={{
											width: '20%',
											height: 40,
											marginLeft: '5%',
											textTransform: 'none',
											borderRadius: 5,
											fontSize: '11.5pt',
											fontWeight: 'bold',

											color: areRequiredAnswered ? 'white' : '#C4C4C4',
											backgroundColor: areRequiredAnswered ? '#22589F' : '#E2E2E2',
										}}
									>
										Enviar respostas
									</Button>
								</div>
							</div>
						</div>
					);
				}}
			</Modal>
		</div>
	);
}
