import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

const templateMessageRoutes = [
	{
		component: LazyComponent(lazy(() => import('./Update'))),
		path: '/message/update/:id',
	},
	{
		component: LazyComponent(lazy(() => import('./Create'))),
		path: '/message/create',
	},
];

export default templateMessageRoutes;
