import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

const groupRoutes = [
	{
		component: LazyComponent(lazy(() => import('./List'))),
		path: '/group/list',
	},
	{
		component: LazyComponent(lazy(() => import('./Create'))),
		path: '/group/create',
	},
	{
		component: LazyComponent(lazy(() => import('./Update'))),
		path: '/group/update/:id',
	},
];

export default groupRoutes;
