import PropTypes from 'prop-types';
import {Fragment, useState} from 'react';
import Button from './buttons/Button';
import IconButton from './buttons/IconButton';
import Modal from './modal/Modal';

function ModalButton(props) {
	const {
		alt,
		altPosition,
		children,
		className,
		classNameModal, // optional classname to Modal, to custom
		color,
		disabled,
		isIconButton,
		renderModal,
		title,
		subTitle,
		afterClose, // optional fn to execute after handleClose
		draggable,
		maxWidth,
		fullWidth,
		fullScreen,
		transition,
		leftButtonTitle,
		rightButtonTitle,
		variant,
		key,
		dataTour,
		setOpen,
		disableEnforceFocus, // Pass true here to use Tinymce inside this modal
		disableBackdropClick,
		onScrollModal,
		...htmlProps
	} = props;

	const [modalIsOpen, setModalIsOpen] = useState(setOpen || false);

	const handleClose = () => {
		setModalIsOpen(false);
		if (afterClose) {
			afterClose();
		}
	};

	const handleScroll = evt => {
		if (onScrollModal) {
			onScrollModal(evt);
		}
	};

	return (
		<Fragment data-tour={dataTour}>
			{isIconButton && (
				<IconButton
					className={className}
					handleClick={() => setModalIsOpen(true)}
					disabled={disabled}
					alt={alt}
					altPosition={altPosition}
					{...htmlProps}
				>
					{children}
				</IconButton>
			)}
			{!isIconButton && (
				<Button
					className={className}
					handleClick={() => setModalIsOpen(true)}
					disabled={disabled}
					alt={alt}
					altPosition={altPosition}
					color={color}
					variant={variant}
					data-tour={dataTour}
					{...htmlProps}
				>
					{children}
				</Button>
			)}
			{modalIsOpen ? (
				<Modal
					key={key}
					className={classNameModal}
					handleClose={handleClose}
					title={title}
					subTitle={subTitle}
					isOpen={modalIsOpen}
					draggable={draggable}
					maxWidth={maxWidth}
					fullWidth={fullWidth}
					fullScreen={fullScreen}
					transition={transition}
					leftButtonTitle={leftButtonTitle}
					rightButtonTitle={rightButtonTitle}
					disableEnforceFocus={disableEnforceFocus}
					onScroll={handleScroll}
					disableBackdropClick={disableBackdropClick}
				>
					{renderModal}
				</Modal>
			) : null}
		</Fragment>
	);
}

ModalButton.propTypes = {
	afterClose: PropTypes.func,
	alt: PropTypes.string,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	classNameModal: PropTypes.string,
	color: PropTypes.string,
	dataTour: PropTypes.string,
	disableBackdropClick: PropTypes.bool,
	disableEnforceFocus: PropTypes.bool, // Pass true here to use Tinymce inside this modal
	disabled: PropTypes.bool,
	draggable: PropTypes.bool,
	fullScreen: PropTypes.bool,
	fullWidth: PropTypes.bool,
	key: PropTypes.string,
	leftButtonTitle: PropTypes.string,
	maxWidth: PropTypes.string,
	open: PropTypes.bool,
	renderModal: PropTypes.func.isRequired,
	rightButtonTitle: PropTypes.string,
	subTitle: PropTypes.string,
	title: PropTypes.string,
	transition: PropTypes.bool,
	variant: PropTypes.string,
};

export default ModalButton;
