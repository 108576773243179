import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

const hubRoutes = [
	{
		component: LazyComponent(lazy(() => import('./List'))),
		path: '/hub/list',
	},
	{
		component: LazyComponent(lazy(() => import('./Create'))),
		path: '/hub/create',
	},
	{
		component: LazyComponent(lazy(() => import('./Update'))),
		path: '/hub/update/:id',
	},
];

export default hubRoutes;
