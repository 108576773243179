import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

const leadClusterRoutes = [
	{
		component: LazyComponent(lazy(() => import('./Update'))),
		path: '/lead-cluster/update/:id',
	},
];

export default leadClusterRoutes;
