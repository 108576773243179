import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

const channelRoutes = [
	{
		component: LazyComponent(lazy(() => import('./Update'))),
		path: '/channel/update/:id',
	},
];

export default channelRoutes;
