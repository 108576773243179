import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

const channelUsesRoutes = [
	{
		component: LazyComponent(lazy(() => import('./List'))),
		path: '/channel-use/',
	},
];

export default channelUsesRoutes;
