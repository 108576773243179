import LazyComponent from 'components/LazyComponent';
import {lazy} from 'react';

const registryRoutes = [
	{
		component: LazyComponent(lazy(() => import('./List'))),
		path: '/registry/list',
	},
	{
		component: LazyComponent(lazy(() => import('./Create'))),
		path: '/registry/create',
	},
	{
		component: LazyComponent(lazy(() => import('./Update'))),
		path: '/registry/update/:id',
	},
];

export default registryRoutes;
