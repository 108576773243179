import {FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, useTheme} from '@material-ui/core';
import MaterialInput from '@material-ui/core/Input';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {getError} from '../form/utils.js';
import styles from './Input.module.css';

const Input = React.forwardRef((props, ref) => {
	const {
		adornmentIcon,
		adornmentButtonType = 'button',
		handleAdornmentClick,
		autoComplete,
		className,
		disabled,
		errors,
		helperText,
		label,
		name,
		onChange,
		type,
		defaultValue,
		value,
		margin,
		placeholder,
		onBlur,
		...rest
	} = props;
	const theme = useTheme();

	const handleChange = evt => {
		if (onChange) onChange(evt);
	};

	const handleOnBlur = _evt => {
		if (onBlur) onBlur();
	};

	const handleAdornment = () => {
		if (handleAdornmentClick) handleAdornmentClick();
	};

	const id = name; // Will always be the same as name
	const error = getError(errors, name);
	return (
		<FormControl fullWidth={true} margin={margin}>
			<InputLabel htmlFor={id}>{label}</InputLabel>
			<MaterialInput
				autoComplete={autoComplete}
				className={cn(styles['MuiInput-root'], className, {[styles.dark]: theme.palette.type === 'dark'})}
				error={error ? true : false}
				disabled={disabled}
				id={id}
				name={name}
				placeholder={placeholder}
				onChange={handleChange}
				onBlur={handleOnBlur}
				inputRef={ref}
				type={type}
				onWheel={e => (type === 'number' ? e.target.blur() : {})}
				defaultValue={defaultValue}
				value={value}
				endAdornment={
					adornmentIcon ? (
						<InputAdornment position='end'>
							<IconButton onClick={handleAdornment} type={adornmentButtonType}>
								{adornmentIcon}
							</IconButton>
						</InputAdornment>
					) : (
						<InputAdornment position='end'>
							<span />
						</InputAdornment>
					)
				}
				{...rest}
			/>
			<FormHelperText>{error ? helperText : ''}</FormHelperText>
		</FormControl>
	);
});

Input.propTypes = {
	adornmentButtonType: PropTypes.oneOf(['button', 'submit', 'reset']),
	adornmentIcon: PropTypes.node,
	autoComplete: PropTypes.string,
	className: PropTypes.string,
	defaultValue: PropTypes.string,
	disabled: PropTypes.bool,
	errors: PropTypes.instanceOf(Object),
	fullWidth: PropTypes.bool,
	handleAdornmentClick: PropTypes.func,
	helperText: PropTypes.string,
	label: PropTypes.string,
	margin: PropTypes.string,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	type: PropTypes.string,
};

Input.defaultProps = {
	disabled: false,
	type: 'text',
};

export default Input;
