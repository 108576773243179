import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {FormGroup, FormHelperText} from '@material-ui/core';
import MaterialCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {getError} from '../form/utils.js';

import {Controller} from 'react-hook-form';

const CheckBox = React.forwardRef((props, ref) => {
  const {
    className,
    errors,
    color,
    disabled,
    helperText,
    label,
    name,
    onChange,
    control,
    incomingValue,
  } = props;

  const handleChange = (e) => {
    if (onChange) onChange(e);
  };

  const error = getError(errors, name);
  return (
    <FormGroup>
      <Controller
        control={control}
        errors={error}
        name={name}
        render={({onChange, onBlur, value, name, ref}) => (
          <Fragment>
            <FormControlLabel
              control={
                <MaterialCheckbox
                  disableRipple={true}
                  className={className}
                  disabled={disabled}
                  color={color}
                  id={name}
                  name={name}
                  onBlur={e => onBlur(e.target.checked)}
                  onChange={(e) => {onChange(e.target.checked); handleChange(e.target.checked)}}
                  checked={incomingValue || value}
                  inputRef={ref}
                />
              }
              label={label}
            />
          </Fragment>
        )}
      />
      <FormHelperText>{error ? helperText : ''}</FormHelperText>
    </FormGroup>
  );
});

CheckBox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  control: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

CheckBox.defaultProps = {
  disabled: false,
};

export default CheckBox;
