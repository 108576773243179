/* eslint-disable no-undef */
import {makeStyles} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {DataLayer} from '@nimbus-target/ui';
import IconButton from 'components/buttons/IconButton';
import {Fragment} from 'react';
import {useFeatureToggle} from 'redux/hooks/app';

function gtag(...rest) {
	DataLayer.push(...rest.slice(1));
}

const useStyles = makeStyles(theme => ({
	title: {
		fontSize: '0.875rem',
		fontWeight: 600,
		color: theme.palette.type === 'dark' ? theme.palette.text : theme.palette.nimbus.neutral.normal,
	},
	switchRoot: {},
	switchBase: {},
	switchThumb: {},
	switchTrack: {},
	switchChecked: {},
	switchLabel: {
		fontSize: '0.875rem',
		fontWeight: 400,
		marginBottom: 2,
	},
	appBar: {
		'position': 'static',
		'backgroundColor': theme.palette.type === 'dark' ? '#333333' : '#ffffff',
		'borderTop': '1px solid rgba(196, 196, 196, 0.5)',
		'boxShadow': theme.palette.type === 'dark' ? undefined : 'none',
		'& .Mui-selected.MuiTab-textColorPrimary': {
			color: theme.palette.nimbus.primary.blue.normal,
		},
	},
	appBarIndicator: {
		backgroundColor: theme.palette.nimbus.primary.blue.normal,
	},
	appBarText: {
		fontFamily: 'Roboto, sans-serif',
		fontWeight: 500,
		textTransform: 'capitalize',
	},
}));

function a11y(id, type = 'tab') {
	const a11yProps = {
		tab: {
			'id': `${type}-${id}`,
			'value': id,
			'aria-controls': `Alerts.tabpanel-${id}`,
		},
		tabpanel: {
			'value': id,
			'role': 'tabpanel',
			'aria-labelledby': `tabpanel-${id}`,
		},
	};
	return a11yProps[type] ?? {};
}

/**
 * @layout:
 * https://www.figma.com/file/qHPhE3Hf2thqrqRaRiDnuI/Nimbus-Design-System?node-id=276%3A7475&mode=dev
 */
export const AlertsHeader = ({
	nimbusLength = 0,
	btgLength = 0,
	tabValue,
	setTabValue,
	isSwitchChecked = false,
	setSwitchChecked,
	setDrawerNotifications,
}) => {
	const styles = useStyles();

	const handleTabChange = (_event, newValue) => {
		setTabValue(newValue);
	};

	const {showFeature: isBtgIntegrationActive} = useFeatureToggle('btgIntegration');

	return (
		<Fragment>
			<Box display='flex' alignItems='center' justifyContent='space-between' px={2} py={0.5}>
				<Typography variant='button' className={styles.title}>
					NOTIFICAÇÕES
				</Typography>
				<Box display='flex' alignItems='center' justifyContent='center'>
					<FormGroup row={true}>
						<FormControlLabel
							classes={{label: styles.switchLabel}}
							label={!isSwitchChecked ? 'Mostrar lidas' : 'Mostrar não lidas'}
							labelPlacement={'start'}
							control={
								<Switch
									classes={{
										root: styles.switchRoot,
										switchBase: styles.switchBase,
										thumb: styles.switchThumb,
										track: styles.switchTrack,
										checked: styles.switchChecked,
									}}
									color='primary'
									disableRipple={false}
									onChange={evt => {
										setSwitchChecked(evt.target.checked);
										gtag('event', 'header_alerts_switch_read', {
											checked: evt.target.checked,
										});
									}}
									checked={isSwitchChecked}
								/>
							}
						/>
					</FormGroup>
					<IconButton handleClick={() => setDrawerNotifications(false)}>
						<CancelOutlinedIcon />
					</IconButton>
				</Box>
			</Box>
			<AppBar square={true} color='default' className={styles.appBar}>
				{isBtgIntegrationActive && (
					<Tabs
						aria-label='Alertas'
						value={tabValue}
						onChange={handleTabChange}
						centered={true}
						position='static'
						variant='fullWidth'
						indicatorColor='primary'
						textColor='primary'
						classes={{indicator: styles.appBarIndicator}}
					>
						<Tab
							label={
								<Badge variant='dot' color='error' overlap='rectangular' invisible={!nimbusLength}>
									<Typography className={styles.appBarText}>Sellera</Typography>
								</Badge>
							}
							{...a11y('nimbus')}
						/>
						<Tab
							label={
								<Badge variant='dot' color='error' overlap='rectangular' invisible={!btgLength}>
									<Typography className={styles.appBarText}>BTG</Typography>
								</Badge>
							}
							{...a11y('btg')}
						/>
					</Tabs>
				)}
			</AppBar>
		</Fragment>
	);
};

AlertsHeader.displayName = 'Alerts.Header';
export default AlertsHeader;
