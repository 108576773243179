import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

const trainingRoutes = [
	{
		component: LazyComponent(lazy(() => import('./List'))),
		path: '/training/list',
	},
];

export default trainingRoutes;
