import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

const deleteLeadsRoutes = [
	{
		component: LazyComponent(lazy(() => import('./List'))),
		path: '/delete-leads/',
	},
];

export default deleteLeadsRoutes;
