import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import {IconButton, InputAdornment, TextField} from '@material-ui/core';
import {Controller} from 'react-hook-form';
import {useDispatchGet, useDispatchPost} from 'redux/hooks/fetch';
import {getError} from '../form/utils.js';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';

const InputAutoComplete = React.forwardRef((props, ref) => {
	const {
		adornmentIcon,
		adornmentButtonType,
		className,
		incomingValue,
		isRequired,
		defaultOptions,
		disabled,
		dataOptions,
		errors,
		helperText,
		label,
		name,
		onChange,
		handleAdornmentClick,
		control,
		margin,
		multiple,
		requestMethod,
		requestParams,
		requestPath,
		requestResultParser,
		fullWidth,
		allowFreeValueOnBlur,
	} = props;

	const [options, setOptions] = useState(null);
	const [skeletonActive, setSkeletonActive] = useState(true);

	const handleAdornment = () => {
		if (handleAdornmentClick) handleAdornmentClick();
	};

	//const [requestParamsState, setRequestParamsState] = useState(requestParams || {});
	//const requestParamsState = useAppValue('requestParamsSelect');
	//const setRequestParamsState =  useDispatchSetAppValue('requestParamsSelect');

	const get = useDispatchGet();
	const post = useDispatchPost();

	useEffect(() => {
		if (defaultOptions && defaultOptions.length > 0) {
			setOptions(requestResultParser(defaultOptions));
			setSkeletonActive(false);
			return;
		}
		//if (requestParamsState === requestParams) return;
		const requestMethods = {get, post};
		const request = requestMethods[requestMethod];
		request(requestPath, requestParams)
			.then(responseData => {
				setOptions(requestResultParser(responseData.data));
				setSkeletonActive(false);
			})
			.catch(() => {});
	}, [defaultOptions, get, post, requestMethod, requestParams, requestPath, requestResultParser]);

	useEffect(() => {
		if (dataOptions) {
			setOptions(dataOptions);
			setSkeletonActive(false);
		}
	}, [dataOptions]);

	const handleChange = (e, newValue) => {
		if (onChange) onChange(newValue);
	};

	const getDefaultValue = defaultValueId => {
		if (multiple) {
			return options.filter(e => defaultValueId && defaultValueId.some(defaultValue => defaultValue === e.id));
		}
		return options.find(e => e.id === defaultValueId);
	};

	const error = getError(errors, name);

	return (
		<FormControl fullWidth={fullWidth} margin={margin}>
			{!skeletonActive && (
				<Controller
					control={control}
					errors={error}
					name={name}
					defaultValue={incomingValue}
					rules={{
						required: isRequired,
					}}
					render={({onChange, name, value, ref}) => (
						<Autocomplete
							clearOnEscape={allowFreeValueOnBlur ? false : true}
							clearOnBlur={allowFreeValueOnBlur ? false : true}
							forcePopupIcon={false}
							noOptionsText={'Nenhuma opção'}
							renderInput={params => (
								<TextField
									{...params}
									label={label}
									error={error ? true : false}
									InputProps={{
										...params.InputProps,
										endAdornment:
											adornmentIcon && !handleAdornmentClick ? (
												<SearchIcon />
											) : (
												<InputAdornment position='end'>
													<SearchIcon />
													<IconButton onClick={handleAdornment} type={adornmentButtonType}>
														{adornmentIcon}
													</IconButton>
												</InputAdornment>
											),
										style: {
											paddingRight: '0px',
										},
										inputRef: ref,
									}}
								/>
							)}
							className={className}
							options={options}
							getOptionDisabled={option => option.disabled === true}
							getOptionLabel={option => option.label}
							defaultValue={getDefaultValue(incomingValue)}
							disabled={disabled}
							//value={changeByState ? incomingValue : value}
							id={name}
							name={name}
							value={getDefaultValue(value)}
							onChange={async (e, newValue) => {
								if (multiple) {
									// Get only values itself (This component brings label too if you let)
									let newValuesOnly = await newValue.map(value => value.id);
									onChange(newValuesOnly);
									handleChange(e, newValuesOnly);
									return;
								}
								onChange(newValue ? newValue.id : {});
								handleChange(e, newValue || {});
							}}
							onBlurCapture={async (e, newValue) => {
								if (!allowFreeValueOnBlur) return;
								if (multiple) {
									// Get only values itself (This component brings label too if you let)
									let newValuesOnly = (await e.target.value) ? e.target.value.map(value => value.id) : [];
									onChange(newValuesOnly);
									handleChange(e, newValuesOnly);
									return;
								}
								onChange(e.target.value || {});
								handleChange(e, e.target.value || {});
							}}
							inputRef={ref}
							multiple={multiple}
						></Autocomplete>
					)}
				/>
			)}
			<FormHelperText>{error ? helperText : ''}</FormHelperText>
		</FormControl>
	);
});

InputAutoComplete.propTypes = {
	allowFreeValueOnBlur: PropTypes.bool,
	changeByState: PropTypes.bool,
	className: PropTypes.string,
	control: PropTypes.instanceOf(Object),
	dataOptions: PropTypes.instanceOf(Object),
	defaultOptions: PropTypes.instanceOf(Array),
	disabled: PropTypes.bool,
	errors: PropTypes.instanceOf(Object),
	fullWidth: PropTypes.bool,
	hasEmptyOption: PropTypes.bool,
	helperText: PropTypes.string,
	incomingValue: PropTypes.string,
	isRequired: PropTypes.bool,
	label: PropTypes.string,
	margin: PropTypes.string,
	multiple: PropTypes.bool,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	requestMethod: PropTypes.string,
	requestParams: PropTypes.instanceOf(Object),
	requestPath: PropTypes.string,
	requestResultParser: PropTypes.func,
};

InputAutoComplete.defaultProps = {
	fullWidth: true,
	hasEmptyOption: true,
	disabled: false,
	isRequired: false,
	multiple: false,
	requestMethod: 'get',
	requestResultParser: data => data.map(value => ({disabled: false, id: value, label: value})),
};

export default InputAutoComplete;
