import Dropdown from 'components/dropdown/Dropdown';
import {useEffect, useState} from 'react';
import {useDispatchPost} from 'redux/hooks/fetch';

export default function KanbanPipelineOptions({featureToggleList, className}) {
	console.log({featureToggleList});
	const post = useDispatchPost();
	const [kanbanLeadList, setKanbanLeadList] = useState([]);
	const [pipelineListLoading, setPipelineListLoading] = useState(true);

	useEffect(() => {
		post('/pipeline/list').then(responseData => {
			let pipelineList = [];
			for (const pipeline of responseData.data) {
				pipelineList.push({label: pipeline.name, link: `/kanban-lead-list?pipeline=${pipeline._id}`});
			}

			setKanbanLeadList(pipelineList);
			setPipelineListLoading(false);
		});
	}, [post, setKanbanLeadList, setPipelineListLoading]);

	return (
		<div>
			{featureToggleList?.find(data => data.featureId.key === 'kanbanLeadList') ? (
				<Dropdown
					hasArrow={true}
					label='Funil'
					className={className}
					itensList={kanbanLeadList}
					excludeDivider={true}
					placement='bottom-start'
					hideScroll={true}
					internalPadding={10}
					forceReload={true}
					itemsLoading={pipelineListLoading}
				/>
			) : null}
		</div>
	);
}
