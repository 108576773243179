import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useThemeContext} from 'context';
import Skeleton from '@material-ui/lab/Skeleton';

function LazyFallback() {
  const [showFallback, setShowFallback] = useState(false);

  const useStyles = makeStyles((theme) => ({
    skeletonContainer: {
      backgroundColor: theme.palette.type === 'dark' ? theme.palette.supportFour : theme.palette.supportOne,
      height: `calc(100% - ${theme.heights.header}px)`,
      display: 'flex',
      justifyContent: 'space-between',
    },
    skeletonMobile: {
      backgroundColor: theme.palette.type === 'dark' ? theme.palette.supportFour : theme.palette.supportOne,
      height: `calc(100% - ${theme.heights.header}px)`,
      '& > div': {
        padding: '2px 6px',
        '& > span': {
          marginBottom: 4,
        },
      },
    },
  }));

  const classes = useStyles();

  const {isMobile} =  useThemeContext();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowFallback(true);
    }, 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  if (!showFallback) return null;
  if (isMobile) {
    return (
      <div className={classes.skeletonMobile}>
        <Skeleton variant="rect" height="100px" width="100%" />
        <div>
          <Skeleton variant="rect" height="77px" width="100%" />
          <Skeleton variant="rect" height="77px" width="100%" />
          <Skeleton variant="rect" height="77px" width="100%" />
          <Skeleton variant="rect" height="77px" width="100%" />
          <Skeleton variant="rect" height="77px" width="100%" />
        </div>
      </div>
    );
  }
  return (
    <div className={classes.skeletonContainer}>
      <Skeleton variant="rect" height="100%" width="30%" />
      <Skeleton variant="rect" height="100%" width="28%" />
      <Skeleton variant="rect" height="100%" width="41%" />
    </div>
  );
}

export default LazyFallback;
