import React, {useEffect, useState} from 'react';
import {makeStyles, CardActions} from '@material-ui/core';
import Modal from 'components/modal/Modal';
import {useAppValue} from 'redux/hooks/app';
import {useDispatchPost} from 'redux/hooks/fetch';
import DialogActions from '@material-ui/core/DialogActions';
import Button from 'components/buttons/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import NotificationAttachmentsModal from 'header/NotificationAttachmentsModal';
import IconButton from 'components/buttons/IconButton';
import AttachmentIcon from '@material-ui/icons/Attachment';

function NotificationAcceptModal(_props) {
	const countNotificationsNotAccept = useAppValue('countNotificationsNotAccept');

	// Styles
	const useStyles = makeStyles(theme => ({
		actions: {
			background: theme.palette.type === 'light' ? '#F3F3F3' : '#2C2E35',
			borderRadius: `0 0 ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
			padding: '30px 10%',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
		terms: {
			//color: "black",
			color: theme.palette.type === 'light' ? 'black' : 'white',
		},
	}));

	const classes = useStyles();
	const post = useDispatchPost();
	let [notification, setNotification] = useState({});

	//texto da notificação substituindo \n por <br>, já que o modal de attachment exibe texto normal n html
	const [changedNotificationText, setChangedModificationText] = useState('');
	let [isOpen, setIsOpen] = useState(false);

	const [checked, setChecked] = useState(false);

	const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);

	useEffect(() => {
		if (countNotificationsNotAccept === 0) return;
		post('/notification-center/view-not-accepted').then(responseData => {
			let auxNotification = responseData.data;

			//substituindo \n por <br>
			if (auxNotification && auxNotification.description) {
				setChangedModificationText(auxNotification.description.replace(/(?:\r\n|\r|\n)/g, '<br>'));
			}

			setNotification(auxNotification);
			setIsOpen(true);
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countNotificationsNotAccept]);

	const assign = () => {
		post('/notification-center/set-accepted', {id: notification._id}).then(_responseData => {
			setNotification(0);
			setIsOpen(false);
		});
	};

	const acceptButton = () => {
		return (
			<DialogActions className={classes.actions}>
				<FormControlLabel
					control={<Checkbox checked={checked} onChange={() => setChecked(!checked)} color='primary' />}
					label='Eu li e estou ciente'
				/>
				<Button handleClick={assign} color='primary' variant='contained' disabled={!checked}>
					Quero continuar
				</Button>
			</DialogActions>
		);
	};

	if (!notification) return '';
	return (
		<div className={classes.container}>
			<Modal
				isOpen={isOpen}
				fullWidth={true}
				maxWidth='md'
				title={notification.title}
				actionsComponent={acceptButton}
				hideCloseIcon={true}
			>
				{({handleClose: _handleClose}) => {
					return (
						<React.Fragment>
							<p className={classes.terms} dangerouslySetInnerHTML={{__html: changedNotificationText}} />
							{(notification.linkType === 'external' || notification.linkType === 'filters') && (
								<CardActions>
									<a href={notification.link} rel='noopener noreferrer' target='_blank'>
										<Button>
											<LinkOutlinedIcon className={classes.linkBtn} />
											Acessar
										</Button>
									</a>
								</CardActions>
							)}

							{notification.linkType === 'termos' && (
								<CardActions>
									<Button
									// handleClick={() => {
									//   setUserTerms(true);
									// }}
									>
										<LinkOutlinedIcon className={classes.linkBtn} />
										Acessar
									</Button>
								</CardActions>
							)}
							{notification.attachments && notification.attachments.length > 0 && (
								<IconButton
									alt='Acessar anexos'
									handleClick={() => {
										setIsAttachmentModalOpen(true);
									}}
								>
									<AttachmentIcon />
									Anexos
								</IconButton>
							)}
						</React.Fragment>
					);
				}}
			</Modal>
			{isAttachmentModalOpen && (
				<Modal
					isOpen={isAttachmentModalOpen}
					handleClose={() => setIsAttachmentModalOpen(false)}
					title='Anexos'
					fullWidth={false}
					maxWidth='md'
					actions={false}
					children={() => {
						return <NotificationAttachmentsModal notification={notification} />;
					}}
				></Modal>
			)}
		</div>
	);
}

export default NotificationAcceptModal;
