import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

function TabContent(props) {
  const {children, className, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tab-${index}`}
      aria-labelledby={`scrollable-force-tabpanel-${index}`}
      style={{outline: 'none'}}
      {...other}
    >
      {value === index && (
        <Box p={3} className={className}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabContent.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default TabContent;