const permissions = {
	additionalInformation: {
		groupTypes: ['nimbus'],
		roleAdministrator: true,
		roles: ['canAccessForms'],
	},
	channel: {
		groupTypes: ['nimbus'],
		roleAdministrator: true,
		roles: ['canAccessForms'],
	},
	chart: {
		groupTypes: ['nimbus', 'hub'],
		roleAdministrator: true,
		roles: ['canAccessReports'],
	},
	chartsMenu: {
		groupTypes: ['nimbus', 'hub'],
		roleAdministrator: true,
		roles: ['canAccessReports'],
	},
	group: {
		groupTypes: ['nimbus', 'hub'],
		roleAdministrator: true,
		roles: ['canAccessForms'],
	},
	hub: {
		groupTypes: ['nimbus', 'hub'],
		roleAdministrator: true,
		roles: ['canAccessForms'],
	},
	association: {
		groupTypes: ['nimbus', 'hub', 'association'],
		roleAdministrator: true,
		roles: [],
	},
	store: {
		groupTypes: ['nimbus', 'hub', 'association', 'store'],
		roleAdministrator: true,
		roles: [],
	},
	canSeeUsersScale: {
		groupTypes: ['nimbus', 'hub', 'association', 'store'],
		manager: ['store', 'team'],
		roleAdministrator: false,
		roles: [],
	},
	canSeeOnlineUsers: {
		groupTypes: ['nimbus', 'hub', 'association', 'store'],
		manager: ['store', 'team'],
		roleAdministrator: false,
		roles: [],
	},
	canOpenSupportTicket: {
		groupTypes: ['nimbus', 'hub', 'association', 'store'],
		manager: ['store', 'team'],
		roleAdministrator: false,
		roles: [],
	},
	intelData: {
		groupTypes: ['nimbus', 'hub', 'association'],
		roleAdministrator: true,
		roles: ['canAccessForms'],
	},
	leadConfirmPending: {
		groupTypes: ['store'],
		roleAdministrator: false,
		roles: [],
	},
	leadCreate: {
		groupTypes: ['nimbus', 'hub', 'store', 'association'],
		roleAdministrator: false,
		roles: [],
	},
	leadGroupHiddenInputs: {
		groupTypes: ['store'],
		roleAdministrator: false,
		roles: [],
	},
	leadGroupInputs: {
		groupTypes: ['nimbus', 'hub'],
		roleAdministrator: true,
		roles: [],
	},
	leadImport: {
		groupTypes: ['nimbus', 'hub'],
		roleAdministrator: true,
		roles: ['canAccessForms'],
	},
	leadFollowUpSales: {
		groupTypes: ['store', 'nimbus'],
		roleAdministrator: false,
		roles: [],
	},
	leadTaskManage: {
		groupTypes: ['store', 'nimbus', 'hub'],
		roleAdministrator: false,
		roles: [],
	},
	settings: {
		groupTypes: ['nimbus', 'hub'],
		roleAdministrator: true,
		roles: [],
	},
	settingsRestricted: {
		groupTypes: ['nimbus'],
		roleAdministrator: true,
		roles: ['canAccessForms'],
	},
	settingsAutomaticDistribution: {
		groupTypes: ['nimbus'],
		roleAdministrator: true,
		roles: ['canAccessForms'],
	},
	settingsPipeline: {
		groupTypes: ['nimbus'],
		roleAdministrator: true,
		roles: ['canAccessForms'],
	},
	settingsPipelineList: {
		groupTypes: ['nimbus', 'hub'],
		roleAdministrator: true,
		roles: ['canAccessForms'],
	},
	settingsStatus: {
		groupTypes: ['nimbus'],
		roleAdministrator: true,
		roles: ['canAccessForms'],
	},
	settingsStatusList: {
		groupTypes: ['nimbus', 'hub'],
		roleAdministrator: true,
		roles: ['canAccessForms'],
	},
	settingsWidget: {
		groupTypes: ['nimbus'],
		roleAdministrator: true,
		roles: ['canAccessForms'],
	},
	settingsWebhook: {
		groupTypes: ['nimbus'],
		roleAdministrator: true,
		roles: ['canAccessForms'],
	},
	setupMenu: {
		groupTypes: ['nimbus', 'hub', 'association', 'store'],
		roleAdministrator: false,
		roles: ['canAccessForms'],
	},
	storeExternalId: {
		groupTypes: ['nimbus', 'hub'],
		roleAdministrator: true,
		roles: ['canAccessForms'],
	},
	teamExternalId: {
		groupTypes: ['nimbus', 'hub', 'association', 'store'],
		roleAdministrator: true,
		roles: ['canAccessForms'],
	},
	user: {
		groupTypes: ['nimbus', 'hub', 'association', 'store'],
		roleAdministrator: false,
		roles: ['canAccessForms'],
	},
	userExternalId: {
		groupTypes: ['nimbus', 'hub', 'association'],
		roleAdministrator: true,
		roles: ['canAccessForms'],
	},
	userGroupInputs: {
		groupTypes: ['nimbus', 'hub', 'association', 'store'],
		roleAdministrator: true,
		roles: [],
	},
	userGroupHiddenInputs: {
		groupTypes: ['store'],
		roleAdministrator: false,
		roles: ['canAccessForms'],
	},
	manageSignature: {
		groupTypes: ['nimbus', 'hub'],
		roleAdministrator: true,
		roles: ['canManageSignature'],
	},
	compliance: {
		groupTypes: ['nimbus'],
		roleAdministrator: true,
		roles: ['canAccessForms'],
	},
	pbiSettings: {
		groupTypes: ['nimbus'],
		roleAdministrator: true,
		roles: ['canAccessForms'],
	},
	trainingAdmin: {
		groupTypes: ['nimbus', 'hub'],
		roleAdministrator: true,
		roles: ['canAccessForms'],
	},
	trainingPlatform: {
		groupTypes: ['nimbus'],
		roleAdministrator: true,
		roles: ['canAccessForms'],
	},
	trainingClient: {
		groupTypes: ['nimbus', 'hub'],
		roleAdministrator: true,
		roles: ['canAccessForms'],
	},
	bestMatch: {
		groupTypes: ['nimbus', 'hub'],
		roleAdministrator: true,
		roles: ['canAccessForms'],
	},
	redistribution: {
		groupTypes: ['nimbus', 'hub', 'association', 'store'],
		roleAdministrator: false,
		roles: ['canRedistributeLeads'],
	},
	campaign: {
		groupTypes: ['nimbus', 'hub', 'store'],
		roleAdministrator: false,
		roles: ['canAccessCampaigns'],
	},
	distributeLeads: {
		groupTypes: ['nimbus', 'hub', 'association', 'store'],
		roleAdministrator: false,
		roles: ['canDistributeLeads'],
	},
	nimbusAdmin: {
		groupTypes: ['nimbus'],
		roleAdministrator: true,
		roles: [],
	},
	generateInvoices: {
		groupTypes: ['nimbus', 'hub', 'store'],
		roleAdministrator: false,
		roles: ['canGenerateInvoices'],
	},
	refundInvoices: {
		groupTypes: ['nimbus', 'hub', 'store'],
		roleAdministrator: false,
		roles: ['canRefundInvoices'],
	},
	indicators: {
		groupTypes: ['nimbus'],
		roleAdministrator: true,
		roles: ['canAccessForms'],
	},
	teams: {
		groupTypes: ['nimbus', 'hub', 'association', 'store'],
		roleAdministrator: false,
		roles: ['canAccessForms'],
	},
	voipDialer: {
		groupTypes: ['store'],
		roleAdministrator: false,
		roles: [],
	},
	pocketOfLeadsForAdmin: {
		groupTypes: ['nimbus', 'hub', 'association'],
		roleAdministrator: false,
		roles: [],
	},
	pocketOfLeadsForStore: {
		groupTypes: ['store'],
		roleAdministrator: false,
		roles: ['canAccessPocketOfLeads'],
	},
	integration: {
		groupTypes: ['nimbus', 'hub'],
		roleAdministrator: true,
		roles: [],
	},
};

export default permissions;
