import {Fragment, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatchPost} from 'redux/hooks/fetch';

function NetworkCondition() {
	const {pathname} = useLocation();

	const [networkState, setNetworkState] = useState();

	const post = useDispatchPost();

	// connection speed indicator
	useEffect(() => {
		let interval = null;
		if (navigator && (navigator.onLine || navigator.connection) && process.env.NODE_ENV === 'production') {
			let downlink = navigator.connection ? navigator.connection.downlink : null;
			if (!navigator.onLine) setNetworkState({isConnected: navigator.onLine});
			else setNetworkState({isConnected: navigator.onLine, downlink});
			interval = setInterval(() => {
				downlink = navigator.connection ? navigator.connection.downlink : null;
				const action = pathname.split('/')[1] || 'home';
				if (!navigator.onLine) {
					setNetworkState(old => {
						// check old value
						if (old.isConnected === true) {
							// disconnect
							localStorage.setItem('disconnect', JSON.stringify({disconnectedAt: new Date()}));
						}
						return {isConnected: navigator.onLine, downlink};
					});
				} else {
					let disconnect = null;
					let changeSpeed = false;
					setNetworkState(old => {
						// check old value
						if (old.isConnected === false) {
							// reconnect
							disconnect = JSON.parse(localStorage.getItem('disconnect'));
							localStorage.removeItem('disconnect');
						}
						if (old !== downlink) {
							changeSpeed = true;
						}
						return {isConnected: navigator.onLine, downlink};
					});
					// store network data only when there is a change
					if (disconnect || changeSpeed) {
						// log network
						post('/network-monitoring/create', {
							action,
							disconnectedAt: disconnect ? disconnect.disconnectedAt : null,
							downloadSpeed: downlink,
							userAgent: navigator.userAgent,
							occurredAt: new Date(),
						})
							.then(() => {})
							.catch(() => {});
					}
				}
			}, [10000]);
		}
		return () => {
			if (interval) clearInterval(interval);
		}; // clear
	}, [pathname, post]);

	return (
		<Fragment>
			{networkState && (!networkState.isConnected || (networkState.downlink && networkState.downlink < 1)) && (
				<div
					style={{
						position: 'absolute',
						height: 18,
						width: '100%',
						background: networkState.isConnected ? 'orange' : '#c45757',
						textAlign: 'center',
						fontSize: 14,
					}}
				>
					{networkState.isConnected
						? 'Detectamos que sua conexão está lenta, você poderá ter problemas ao efetuar chamadas'
						: 'Verifique sua conexão com a Internet'}
				</div>
			)}
		</Fragment>
	);
}

export default NetworkCondition;
