import React from 'react';
import {DataLayer} from '@nimbus-target/ui';
import PermissionToggle from 'components/permission/PermissionToggle';
import {makeStyles} from '@material-ui/core';
import IconButton from 'components/buttons/IconButton';
import GroupIcon from '@material-ui/icons/Group';

function gtag(...rest) {
	DataLayer.push(...rest.slice(1));
}

const useStyles = makeStyles(theme => ({
	icon: {
		fontSize: 28,
		[theme.breakpoints.down('1300')]: {
			fontSize: 22,
		},
		[theme.breakpoints.down('1100')]: {
			fontSize: 20,
		},
	},
	badge: {
		background: theme.palette.success.main,
	},
	moreIconsButtons: {
		'color': 'rgba(0,0,0,0.6) !important',
		'fontSize': '1.25rem',
		'& span': {
			'& svg': {
				marginRight: 10,
			},
		},
	},
}));

function UsersOnlineButton(props) {
	const {setDrawer} = props;

	const classes = useStyles();

	return (
		<PermissionToggle permissionKey='store' hasFeedback={false}>
			<IconButton
				alt='Usuários Online'
				handleClick={() => {
					setDrawer(true);
					gtag('event', 'open_users_online');
				}}
				data-tour='tour_home_menu_online'
				className={classes.moreIconsButtons}
			>
				<GroupIcon className={classes.icon} />
				Usuários online
			</IconButton>
		</PermissionToggle>
	);
}

export default UsersOnlineButton;
