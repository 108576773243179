import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

const complianceRoutes = [
	{
		component: LazyComponent(lazy(() => import('./Update'))),
		path: '/compliance/update/:id',
	},
];

export default complianceRoutes;
