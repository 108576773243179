/* eslint-disable react/jsx-props-no-spreading */

import React, {Suspense} from 'react';
import LazyFallback from './LazyFallback';

export default function lazyComponent(Component, hasFallback = true) {
  return (props) => {
    return (
      <Suspense fallback={hasFallback ? <LazyFallback/> : null}>
        <Component {...props}/>
      </Suspense>
    );
  };
}
