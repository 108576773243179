import {Button, Fade, Icon, IconButton, Snackbar, makeStyles} from '@material-ui/core';
import {useSocketContext} from 'context';
import {useCallback, useEffect, useState} from 'react';
import {useAppValue} from 'redux/hooks/app';
import {useDispatchPost} from 'redux/hooks/fetch';

export default function NotificationPopups(props) {
	// Styles
	const useStyles = makeStyles(theme => ({
		snackbarContent: {
			width: 320,
			height: 300,
			position: 'absolute',
			top: 50,
			right: -20,
			padding: '16px 16px 16px 16px',
			borderRadius: 8,
			boxShadow: '0px 3px 6px #00000029',
			display: 'flex',
			flexDirection: 'row',
			textAlign: 'center',
			fontFamily: 'Inter',
			zIndex: 9999,
			backgroundColor: 'white',
		},
		notificationBody: {
			display: 'flex',
			flexDirection: 'column',
			textAlign: 'start',
			marginLeft: '10%',
		},
		notificationTitle: {
			color: '#404040',
			fontWeight: 600,
			fontSize: 15,
			marginBottom: 10,
		},
		notificationDescription: {
			'whiteSpace': 'pre-line',
			'maxHeight': 167.5,
			'overflowY': 'auto',
			'&::-webkit-scrollbar': {
				display: 'none',
			},
		},
		buttonsWithLink: {
			marginTop: 'auto',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
		buttonsWithoutLink: {
			marginTop: 'auto',
			display: 'flex',
			flexDirection: 'row',
			// justifyContent: 'flex-end',
		},
		ignoreButton: {
			'borderRadius': 4,
			'fontWeight': 600,
			'fontSize': 14,
			'height': 30,
			'width': '45%',
			'backgroundColor': '#E9EEF5',
			'color': '#22589F',
			'& .MuiButton-label': {
				textTransform: 'none',
			},
		},
		goToChatButton: {
			'borderRadius': 4,
			'fontWeight': 600,
			'fontSize': 13.5,
			'width': '52.5%',
			'height': 30,
			'backgroundColor': '#22589F',
			'color': '#FDFDFD',
			'whiteSpace': 'nowrap',
			'& .MuiButton-label': {
				textTransform: 'none',
			},
		},
	}));

	const classes = useStyles();
	const post = useDispatchPost();
	const {socketCore} = useSocketContext();

	const [popupNotifications, setPopupNotifications] = useState([]);
	const [snackbarOpen, setSnackbarOpen] = useState(true);

	const drawerPerfil = useAppValue('perfilDrawer');
	const tasksDrawer = useAppValue('tasksDrawer');
	const onlineUsersDrawer = useAppValue('onlineUsersDrawer');
	const favoriteLeadsDrawer = useAppValue('favoriteLeadsDrawer');
	const notificationsDrawer = useAppValue('notificationsDrawer');

	useEffect(() => {
		post('/notification-center/list-popups')
			.then(responseData => {
				setPopupNotifications(responseData.data);
			})
			.catch(err => {
				console.error('err fetching popups', err);
			});
	}, [post]);

	const getCurrentPopup = useCallback(() => {
		if (popupNotifications.length && snackbarOpen) {
			return popupNotifications[0];
		}
		return null;
	}, [popupNotifications, snackbarOpen]);

	const getAnyDrawerOpen = useCallback(() => {
		return drawerPerfil || tasksDrawer || onlineUsersDrawer || favoriteLeadsDrawer || notificationsDrawer;
	}, [drawerPerfil, tasksDrawer, onlineUsersDrawer, favoriteLeadsDrawer, notificationsDrawer]);

	/*
	const isInHomeOrChat = useCallback(() => {
		return window.location.pathname.includes('/home') || window.location.pathname.includes('/chat');
	}, []);
	*/

	const setPopupViewed = popupId => {
		post('/notification-center/set-read', {id: popupId})
			.then(responseData => {
				console.log('view answer: ', responseData.data);
			})
			.catch(err => {
				console.error('err setting popup viewed', err);
			});
	};

	const handlePopupClose = () => {
		// mark it as viewed
		setPopupViewed(getCurrentPopup()._id);

		// remove the current popup from the list
		setPopupNotifications(popupNotifications.slice(1));

		// close the snackbar
		setSnackbarOpen(false);

		// and if there are more popups, wait for a bit, then open the next one
		if (popupNotifications.length) {
			setTimeout(() => {
				setSnackbarOpen(true);
			}, 250);
		}
	};

	// get the incoming socket from new popups
	useEffect(() => {
		if (!socketCore) return;

		const handleNewPopup = popupNotification => {
			console.log('new popup: ', popupNotification);
			setPopupNotifications([...popupNotifications, popupNotification]);
			setSnackbarOpen(true);
		};

		socketCore.on('new-popup-notification', handleNewPopup);
		return () => {
			socketCore.removeListener('new-popup-notification', handleNewPopup);
		};
	}, [popupNotifications, socketCore]);

	return (
		<div>
			<link rel='preconnect' href='https://fonts.googleapis.com' />
			<link rel='preconnect' href='https://fonts.gstatic.com' crossorigin={true} />
			<link
				href='https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&family=Lato&family=Raleway:wght@200;400&display=swap'
				rel='stylesheet'
			/>
			{!getAnyDrawerOpen() && getCurrentPopup() && (
				<div>
					<Snackbar
						anchorOrigin={{vertical: 'top', horizontal: 'right'}}
						ClickAwayListenerProps={{mouseEvent: false}}
						open={snackbarOpen}
						// autoHideDuration={5000}
						TransitionComponent={Fade}
						transitionDuration={500}
						onClose={() => setSnackbarOpen(false)}
					>
						<div className={classes.snackbarContent}>
							<div style={{width: '15%'}}>
								<Icon>{getCurrentPopup().iconKey || 'cake'}</Icon>
							</div>
							<div className={classes.notificationBody}>
								<div className={classes.notificationTitle}>{getCurrentPopup().title}</div>
								<div className={classes.notificationDescription}>{getCurrentPopup().description}</div>
								{/* only popups created by automations have links, notifications created manually does not */}
								{getCurrentPopup().linkType ? (
									<div className={classes.buttonsWithLink}>
										<Button onClick={handlePopupClose} className={classes.ignoreButton}>
											Ignorar
										</Button>
										<Button onClick={handlePopupClose} className={classes.goToChatButton}>
											<a
												href={getCurrentPopup().link}
												target='blank'
												rel='noopener noreferrer'
												style={{textDecoration: 'none', color: '#FDFDFD'}}
											>
												Ir para chat
											</a>
										</Button>
									</div>
								) : (
									<div className={classes.buttonsWithoutLink}>
										<Button onClick={handlePopupClose} className={classes.ignoreButton}>
											Confirmar
										</Button>
									</div>
								)}
							</div>
							<div style={{width: '10%', marginLeft: 'auto'}}>
								<IconButton style={{padding: 0}} onClick={handlePopupClose}>
									<Icon>close</Icon>
								</IconButton>
							</div>
						</div>
					</Snackbar>
				</div>
			)}
		</div>
	);
}
