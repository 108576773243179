import {Grid, List, ListItem, ListItemText, Typography} from '@material-ui/core';
import {WhatsApp} from '@material-ui/icons';
import ModalButton from 'components/ModalButton';
import Modal from 'components/modal/Modal';
import {useSocketContext, useUserContext} from 'context/index';
import React, {useCallback, useEffect, useState} from 'react';
import {
	useAppValue,
	useDispatchSetAppError,
	useDispatchSetAppSuccessMessage,
	useDispatchSetAppValue,
} from 'redux/hooks/app';
import {useDispatchPost} from 'redux/hooks/fetch';

// Step by step how to connect
const stepByStepQrCode = [
	{
		order: '1.',
		description: 'Abra o WhatsApp no celular.',
	},
	{
		order: '2.',
		description: 'Acesse a opção do seu aparelho que conecta seu aplicativo para Web.',
	},
	{
		order: '3.',
		description: 'Clique em escanear.',
	},
	{
		order: '4.',
		description: 'Aponte o celular para capturar o QR Code.',
	},
	{
		order: '5.',
		description:
			'Aguarde até que a plataforma reconheça o pareamento, isso pode levar alguns segundos. Você receberá uma mensagem de sucesso quando estiver tudo certo.',
	},
];

function WhatsappPerUserModal() {
	const {isAuthenticated, name} = useUserContext();

	const {socketCommunication} = useSocketContext();

	const post = useDispatchPost();
	const setAppError = useDispatchSetAppError();
	const setAppSuccess = useDispatchSetAppSuccessMessage();

	const [isSubmiting, setIsSubmiting] = useState();

	const [qrCodeData, setQrCodeData] = useState(null);
	const [refreshingQrCode, setRefreshingQrCode] = useState(false);

	const isWhatsappPerUserModalOpen = useAppValue('isWhatsappPerUserModalOpen');
	const setIsWhatsappPerUserModalOpen = useDispatchSetAppValue('isWhatsappPerUserModalOpen');

	const setWhatsappPerUserModalAlreadyIgnored = useDispatchSetAppValue('whatsappPerUserModalAlreadyIgnored');

	// "qr-screen", "not-requested", "requested-to-nimbus"
	const whatsappPerUserModalContext = useAppValue('whatsappPerUserModalContext');
	const setWhatsappPerUserModalContext = useDispatchSetAppValue('whatsappPerUserModalContext');

	const whatsappPerUserModalSenderId = useAppValue('whatsappPerUserModalSenderId');
	const setWhatsappPerUserModalSenderId = useDispatchSetAppValue('whatsappPerUserModalSenderId');

	const whatsappPerUserModalSenderProvider = useAppValue('whatsappPerUserModalSenderProvider');
	const setWhatsappPerUserModalSenderProvider = useDispatchSetAppValue('whatsappPerUserModalSenderProvider');

	const setWhatsLightSenderData = useDispatchSetAppValue('whatsLightSenderData');
	const setWhatsLightConfig = useDispatchSetAppValue('whatsLightConfig');

	useEffect(() => {
		if (!isAuthenticated && isWhatsappPerUserModalOpen) setIsWhatsappPerUserModalOpen(false);
	}, [isAuthenticated, isWhatsappPerUserModalOpen, setIsWhatsappPerUserModalOpen]);

	const handleClose = () => {
		setIsSubmiting(true);
		// Aux
		let payload = {
			phoneStatus: whatsappPerUserModalContext,
			ignoredModal: true,
		};
		// Post info on backend
		post('/whatsapp-sender/update-my-phone-status', payload)
			.then(() => {
				setIsSubmiting(false);
				setWhatsappPerUserModalAlreadyIgnored(true);
				setIsWhatsappPerUserModalOpen(false);
				setWhatsappPerUserModalContext(null);
				setWhatsappPerUserModalSenderId(null);
				setWhatsappPerUserModalSenderProvider(null);
			})
			.catch(() => {
				setIsSubmiting(false);
				setAppError('Houve um erro ao processar esta ação, tente novamente.');
			});
	};

	const handleSubmit = async () => {
		try {
			setIsSubmiting(true);
			// If user will request a new instance
			if (whatsappPerUserModalContext === 'not-requested') {
				await post('/whatsapp-sender/request-light-instance');
				setAppSuccess('Ok, nossa equipe de comunicação já foi informada!');
				setIsSubmiting(false);
				setIsWhatsappPerUserModalOpen(false);
				setWhatsappPerUserModalContext(null);
				setWhatsappPerUserModalSenderId(null);
				setWhatsappPerUserModalSenderProvider(null);
			}
			// If phone was connected
			if (whatsappPerUserModalContext === 'phone-connected') {
				setIsSubmiting(false);
				setIsWhatsappPerUserModalOpen(false);
				setWhatsappPerUserModalContext(null);
				setWhatsappPerUserModalSenderId(null);
				setWhatsappPerUserModalSenderProvider(null);
			}
		} catch (err) {
			setIsSubmiting(false);
			setAppError(err.message);
		}
	};

	const getQrCode = useCallback(() => {
		setRefreshingQrCode(true);
		if (whatsappPerUserModalSenderProvider === 'maytapi') {
			post('/whatsapp-sender/maytapi-qr-code', {id: whatsappPerUserModalSenderId})
				.then(responseData => {
					// Set
					setQrCodeData(responseData.data);
					setRefreshingQrCode(false);
				})
				.catch(err => {
					console.error(err);
					setRefreshingQrCode(false);
					setAppError('Erro ao consultar QR Code, tente novamente');
				});
		} else if (whatsappPerUserModalSenderProvider === 'pickyassist') {
			post('/channel/pick-assists', {id: whatsappPerUserModalSenderId})
				.then(responseData => {
					// If QR Code comes
					if (!responseData.isConnected && responseData.qrCode) {
						// Set
						setQrCodeData(responseData.qrCode);
					}
					// If its already connected
					if (responseData.isConnected) {
						// Set state and data
						setWhatsappPerUserModalContext('phone-connected');
						setWhatsLightSenderData({
							senderId: responseData.channelInfo._id,
							status: 'active',
							phoneNumber: responseData.infos.connected_number,
							userId: responseData.channelInfo.userId,
						});
						setWhatsLightConfig(true);
					}
					// Not loading anymore
					setRefreshingQrCode(false);
				})
				.catch(err => {
					console.error(err);
					setRefreshingQrCode(false);
					setAppError('Erro ao consultar dados, tente novamente');
				});
		}
	}, [
		whatsappPerUserModalSenderId,
		whatsappPerUserModalSenderProvider,
		setWhatsappPerUserModalContext,
		setWhatsLightSenderData,
		setWhatsLightConfig,
		post,
		setAppError,
	]);

	// Routine to display qr code on screen
	useEffect(() => {
		if (
			!isAuthenticated ||
			!isWhatsappPerUserModalOpen ||
			!whatsappPerUserModalSenderId ||
			!whatsappPerUserModalSenderProvider
		)
			return;
		// If qr-screen
		if (whatsappPerUserModalContext === 'qr-screen') {
			// Get qr-code on render
			getQrCode();
			// And init routine to refresh it on every 15 seconds
			let isMounted = true;
			const intervalId = setInterval(() => {
				if (!isMounted) return;
				getQrCode();
			}, 15000);
			// Clear
			return () => {
				clearInterval(intervalId);
				isMounted = false;
			};
		}
	}, [
		isAuthenticated,
		isWhatsappPerUserModalOpen,
		whatsappPerUserModalSenderId,
		whatsappPerUserModalContext,
		whatsappPerUserModalSenderProvider,
		getQrCode,
	]);

	// When number is successfully connected, we have to let user know
	useEffect(() => {
		if (!socketCommunication) return undefined;
		// Handler
		const handleMessage = messageData => {
			// Success state
			if (messageData.status === 'active') {
				setWhatsappPerUserModalContext('phone-connected');
				setWhatsLightSenderData(messageData);
				setWhatsLightConfig(true);
			}
		};
		// Listener
		socketCommunication.on('my-whatsapp-phone-status', handleMessage);
		return () => {
			socketCommunication.removeListener('my-whatsapp-phone-status', handleMessage);
		};
	}, [socketCommunication, setWhatsappPerUserModalContext, setWhatsLightSenderData, setWhatsLightConfig]);

	// Set modal title and sub title
	let modalTitle = `Olá ${name}`;
	let modalSubTitle = 'Agora você pode vincular seu WhatsApp aqui na Sellera';
	if (whatsappPerUserModalContext === 'qr-screen') {
		modalTitle = 'Conecte o seu WhatsApp aqui na Nimbus';
	}
	if (whatsappPerUserModalContext === 'phone-connected') {
		modalTitle = 'Tudo pronto!';
		modalSubTitle = '';
	}

	if (!isAuthenticated || !isWhatsappPerUserModalOpen || !whatsappPerUserModalContext) return null;
	return (
		<Modal
			isOpen={true}
			fullWidth={true}
			maxWidth='md'
			handleClose={() => handleClose()}
			title={modalTitle}
			subTitle={modalSubTitle}
			actions={true}
			leftButtonTitle={whatsappPerUserModalContext === 'phone-connected' ? 'Ok' : 'Agora não'}
			rightButtonTitle={
				whatsappPerUserModalContext === 'not-requested' ? (
					<React.Fragment>
						<WhatsApp /> &nbsp; Vincular WhatsApp
					</React.Fragment>
				) : null
			}
			handleRightButtonClick={() => handleSubmit()}
			handleLeftButtonClick={() => handleClose()}
			disableActions={isSubmiting}
			disableBackdropClick={true}
		>
			{() => {
				return (
					<React.Fragment>
						{whatsappPerUserModalContext === 'not-requested' && (
							<Typography>
								É muito fácil, basta clicar em <strong>Vincular WhatsApp</strong>, e em até 24 horas, você receberá aqui
								mesmo o QR Code para conectar.
							</Typography>
						)}
						{whatsappPerUserModalContext === 'phone-connected' && (
							<Typography>
								Agora você pode aproveitar todos os canais disponíveis para se manter conectado ao cliente.
								<br />
								Bom trabalho.
							</Typography>
						)}
						{whatsappPerUserModalSenderId && whatsappPerUserModalContext === 'qr-screen' && (
							<Grid container={true} spacing={1}>
								<Grid item={true} xs={6}>
									<List>
										{stepByStepQrCode.map(step => {
											return (
												<ListItem>
													<ListItemText primary={`${step.order}  ${step.description}`} />
												</ListItem>
											);
										})}
									</List>
								</Grid>
								<Grid item={true} xs={6}>
									<div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
										<Typography>
											{!qrCodeData || refreshingQrCode ? 'Carregando QR Code...' : 'Aguardando leitura do QR Code'}
										</Typography>
										{qrCodeData && (
											<ModalButton
												renderModal={() => {
													return (
														<center>
															{whatsappPerUserModalSenderProvider === 'maytapi' && (
																<img
																	src={`data:image/png;base64,${qrCodeData}`}
																	alt='QR Code'
																	width={400}
																	height={400}
																/>
															)}
															{whatsappPerUserModalSenderProvider !== 'maytapi' && (
																<span
																	dangerouslySetInnerHTML={{__html: qrCodeData.replace('chs=150x150', 'chs=400x400')}}
																/>
															)}
														</center>
													);
												}}
												title='Aponte o celular para capturar o QR Code'
												fullWidth={true}
												maxWidth='md'
											>
												{whatsappPerUserModalSenderProvider === 'maytapi' && (
													<img src={`data:image/png;base64,${qrCodeData}`} alt='QR Code' width={250} height={250} />
												)}
												{whatsappPerUserModalSenderProvider !== 'maytapi' && (
													<span dangerouslySetInnerHTML={{__html: qrCodeData.replace('chs=150x150', 'chs=250x250')}} />
												)}
											</ModalButton>
										)}
									</div>
								</Grid>
							</Grid>
						)}
					</React.Fragment>
				);
			}}
		</Modal>
	);
}

export default WhatsappPerUserModal;
