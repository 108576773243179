import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

const megadashRoutes = [
	{
		component: LazyComponent(lazy(() => import('./List'))),
		path: '/megadash/list',
	},
	{
		component: LazyComponent(lazy(() => import('./Create'))),
		path: '/megadash/create/:type',
	},
	{
		component: LazyComponent(lazy(() => import('./Update'))),
		path: '/megadash/update/:id',
	},
];

export default megadashRoutes;
