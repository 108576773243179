import React, {useEffect, useState} from 'react';
import {useDispatchPost} from 'redux/hooks/fetch';
import {useHistory} from 'react-router-dom';
import {useUserContext} from 'context';
import {formatDateHour} from 'utils/moment';
import {
	makeStyles,
	List,
	ListItem,
	IconButton,
	ListItemAvatar,
	Avatar,
	ListItemText,
	ListItemSecondaryAction,
	Tooltip,
	Button,
	ButtonGroup,
	Grid,
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import LinkIcon from '@material-ui/icons/Link';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import PhoneMissedIcon from '@material-ui/icons/PhoneMissed';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import PhoneDisabledIcon from '@material-ui/icons/PhoneDisabled';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import VoicemailIcon from '@material-ui/icons/Voicemail';

const useStyles = makeStyles(theme => ({
	tabs: {
		textAlign: 'center',
	},
	tabButton: {
		textTransform: 'capitalize',
	},
}));

function DialerCallHistoric() {
	const classes = useStyles();

	const {groupSalesId} = useUserContext();

	const history = useHistory();

	const post = useDispatchPost();

	const [callHistoric, setCallHistoric] = useState(null);
	const [statusFiltered, setStatusFiltered] = useState(null);
	const [countData, setCountData] = useState(null);

	useEffect(() => {
		post('/voip-call/list-dialer-historic', {groupSalesId})
			.then(responseData => {
				setCallHistoric(responseData.data.historic);
				setCountData({
					total: responseData.data.total,
					totalCompleted: responseData.data.totalCompleted,
					totalNoAnswer: responseData.data.totalNoAnswer,
				});
			})
			.catch(err => {
				console.error(err);
			});
	}, [post, groupSalesId, setCallHistoric]);

	const goToLead = leadId => {
		if (!leadId) return;
		history.push(`/chat/${leadId}`);
	};

	const dynamicCallList = () => {
		if (!callHistoric) return [];
		return callHistoric.filter(item =>
			statusFiltered ? statusFiltered.some(statusFilter => statusFilter === item.status) : item,
		);
	};

	const callStatusColor = status => {
		if (!status) return '#f44336';

		if (status === 'completed') {
			return '#4caf50';
		} else if (status === 'no-answer') {
			return '#f44336';
		} else if (status === 'calling') {
			return '#2196f3';
		} else if (status === 'in-progress') {
			return '#2196f3';
		} else if (status === 'busy') {
			return '#ffa500';
		} else if (status === 'failed') {
			return '#f44336';
		} else if (status === 'voicemail') {
			return '#2196f3';
		}
	};

	const callStatusLabel = status => {
		if (!status) return '';

		if (status === 'completed') {
			return 'Ligação atendida';
		} else if (status === 'no-answer') {
			return 'Ligação sem resposta/encerrada';
		} else if (status === 'calling') {
			return 'Chamando...';
		} else if (status === 'in-progress') {
			return 'Ligação em progresso...';
		} else if (status === 'busy') {
			return 'Ocupado';
		} else if (status === 'failed') {
			return 'Falha ao tentar ligar, o número pode ser inválido, verifique';
		} else if (status === 'voicemail') {
			return 'Caixa postal/Robô';
		}
	};

	// Like "Tabs"
	const tabButtons = [
		<Button
			key='all'
			className={classes.tabButton}
			variant={!statusFiltered ? 'contained' : 'outlined'}
			color={!statusFiltered ? 'primary' : 'secondary'}
			onClick={() => setStatusFiltered(null)}
		>
			Todas {countData && `(${countData.total})`}
		</Button>,
		<Button
			key='called'
			className={classes.tabButton}
			variant={statusFiltered && statusFiltered.some(status => status === 'completed') ? 'contained' : 'outlined'}
			color={statusFiltered && statusFiltered.some(status => status === 'completed') ? 'primary' : 'secondary'}
			onClick={() => setStatusFiltered(['completed'])}
		>
			Atendidas {countData && `(${countData.totalCompleted})`}
		</Button>,
		<Button
			key='no-answer'
			className={classes.tabButton}
			variant={
				statusFiltered &&
				statusFiltered.some(status => status === 'no-answer' || status === 'failed' || status === 'busy')
					? 'contained'
					: 'outlined'
			}
			color={
				statusFiltered &&
				statusFiltered.some(status => status === 'no-answer' || status === 'failed' || status === 'busy')
					? 'primary'
					: 'secondary'
			}
			onClick={() => setStatusFiltered(['no-answer', 'failed', 'busy'])}
		>
			Sem resposta {countData && `(${countData.totalNoAnswer})`}
		</Button>,
	];

	return (
		<div>
			{callHistoric && (
				<React.Fragment>
					<div className={classes.tabs}>
						<ButtonGroup size='small' style={{marginBottom: '5px'}}>
							{tabButtons}
						</ButtonGroup>
					</div>
					<List dense={true}>
						{dynamicCallList().map(data => {
							return (
								<ListItem>
									<ListItemAvatar>
										<Tooltip title={callStatusLabel(data.status)}>
											<Avatar style={{background: callStatusColor(data.status)}}>
												{data.status === 'completed' && <PhoneForwardedIcon />}
												{data.status === 'no-answer' && <PhoneMissedIcon />}
												{data.status === 'in-progress' && <PhoneInTalkIcon />}
												{data.status === 'calling' && <PhoneInTalkIcon />}
												{data.status === 'failed' && <PhoneDisabledIcon />}
												{data.status === 'busy' && <EventBusyIcon />}
												{data.status === 'voicemail' && <VoicemailIcon />}
											</Avatar>
										</Tooltip>
									</ListItemAvatar>
									<ListItemText primary={data.customerName} secondary={formatDateHour(data.createdAt)} />
									<ListItemSecondaryAction>
										<Tooltip title={'Acessar lead'}>
											<IconButton edge='end' onClick={() => goToLead(data.leadId)}>
												<LinkIcon />
											</IconButton>
										</Tooltip>
									</ListItemSecondaryAction>
								</ListItem>
							);
						})}
					</List>
				</React.Fragment>
			)}
			{!callHistoric && (
				<Grid container={true} spacing={4}>
					{[1, 2, 3, 4, 5, 6].map(() => {
						return (
							<Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Skeleton variant='rect' width='100%' height={100} />
							</Grid>
						);
					})}
				</Grid>
			)}
		</div>
	);
}

export default DialerCallHistoric;
