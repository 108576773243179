import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

const taskRoutes = [
	{
		component: LazyComponent(lazy(() => import('./CreateMultipleTasks'))),
		path: '/task/create-multiple',
	},
];

export default taskRoutes;
