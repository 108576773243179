import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import {DataLayer} from '@nimbus-target/ui';
import cn from 'classnames';
import Button from 'components/buttons/Button';
import IconButton from 'components/buttons/IconButton';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatchSetAppValue} from 'redux/hooks/app';
import {useDispatchPost} from 'redux/hooks/fetch';
import {formatDateHour} from 'utils/moment';

function gtag(...rest) {
	DataLayer.push(...rest.slice(1));
}

export const useStyles = makeStyles(theme => ({
	card: {
		marginBottom: theme.spacing(2),
		borderColor: theme.palette.primary.light,
		color: theme.palette.type === 'dark' ? theme.palette.text : theme.palette.nimbus.neutral.normal,
	},
	cardUnreaded: {
		backgroundColor:
			theme.palette.type === 'dark' ? theme.palette.nimbus.neutral.normalHover : 'rgba(233, 238, 245, 1)',
	},
	linkBtn: {
		marginRight: theme.spacing(1.5),
	},
	cardHeaderTitle: {
		fontSize: '0.875rem',
		fontWeight: 600,
	},
	cardContent: {
		padding: `${theme.spacing(0)}px ${theme.spacing(2)}px`,
	},
	cardContentTitle: {
		fontSize: '0.875rem',
		fontWeight: 600,
	},
	cardContentDescription: {
		whiteSpace: 'pre-wrap',
	},
	cardActions: {
		flexDirection: 'column',
	},
	iconDoneAll: {
		fill: theme.palette.nimbus.information.normal,
	},
	iconAction: {
		'margin': theme.spacing(1),
		'boxShadow': `0 0 0 1px ${
			theme.palette.type === 'dark'
				? theme.palette.nimbus.primary.blue.normal
				: theme.palette.nimbus.primary.blue.normal
		}`,
		'backgroundColor': theme.palette.type === 'dark' ? theme.palette.nimbus.primary.blue.light : 'transparent',
		'&:hover': {
			backgroundColor: theme.palette.nimbus.primary.blue.lightHover,
		},
	},
	iconActionSymbol: {
		fontSize: '1rem',
		color: theme.palette.nimbus.primary.blue.normal,
	},
	iconActionRead: {
		width: 40,
		height: 40,
	},
	iconActionReadSymbol: {},
	createdAt: {},
}));

export const defaultData = {
	_id: '',
	title: '',
	createdAt: '',
	linkType: 'external',
	recipients: {
		status: 'unread',
	},
	attachments: [],
};

/*
 * @layout
 * https://www.figma.com/file/qHPhE3Hf2thqrqRaRiDnuI/Nimbus-Design-System?type=design&node-id=164-5784&mode=design&t=VTZSatbl25rvYLEP-0
 *
 * @usage:
  <AlertCard
    data={data}
    onClickMark={() => {
      const update = notifications;
      const notifyIndex = update.findIndex(notify => notify._id === data._id);
      if (notifyIndex < 0) return;
      // workaround - não estava re-renderizando sem limpar antes
      setNotifications([]);
      update[notifyIndex].recipients.status = 'read';
      setNotifications(update);
    }}
    onClickAttachment={() => {
      setOpenNotificationId(index);
      setIsAttachmentModalOpen(true);
    }}
  />
 *
 */
export const AlertCard = React.forwardRef(
	({data = defaultData, className, type = 'nimbus', onClickMark, onClickAttachment, ...props}, ref) => {
		const styles = useStyles();
		const post = useDispatchPost();
		const setCountNotificationsNimbus = useDispatchSetAppValue('countNotificationsNimbus');
		const setCountNotificationsBTG = useDispatchSetAppValue('countNotificationsBTG');
		const setUserTerms = useDispatchSetAppValue('userTerms');
		const [read, setRead] = useState(data.recipients.status === 'read');

		const setOpenSurvey = useDispatchSetAppValue('openSurvey');

		const handleMarkAsRead = useCallback(
			async read => {
				await post('/notification-center/set-read', {id: data._id, channel: type, read}).then(responseData => {
					switch (type) {
						case 'btg': {
							setCountNotificationsBTG(responseData.data);
							break;
						}
						default: {
							setCountNotificationsNimbus(responseData.data);
							break;
						}
					}
					gtag('event', 'alert_read', {
						notification_read_id: data._id,
						marked_as_read: read,
					});
				});
			},
			[post, data, setCountNotificationsBTG, setCountNotificationsNimbus, type],
		);

		const handleToggleMarkAsRead = async () => {
			setRead(currentRead => {
				if (onClickMark) {
					onClickMark(!currentRead);
				}
				return !currentRead;
			});
			await handleMarkAsRead(!read);
		};

		useEffect(() => {
			setRead(data.recipients.status === 'read');
		}, [data.recipients.status]);

		return (
			<Card
				ref={ref}
				key={data._id}
				variant={'elevation'}
				className={cn(styles.card, className, {
					[styles.cardUnreaded]: !read,
				})}
				{...props}
			>
				<CardHeader
					classes={{title: styles.cardHeaderTitle}}
					title={data.title}
					style={{textAlign: data.linkType === 'failedWhatsappMessage' ? 'center' : 'flex-start'}}
				/>

				<CardContent classes={{root: styles.cardContent}}>
					<Typography
						component='p'
						className={styles.cardContentDescription}
						align={data.linkType === 'failedWhatsappMessage' ? 'center' : 'flex-start'}
					>
						{data.description}
					</Typography>
				</CardContent>

				<CardActions className={styles.cardActions}>
					<Box
						display='flex'
						alignItems='center'
						justifyContent={data.linkType === 'failedWhatsappMessage' ? 'center' : 'flex-start'}
						width='100%'
					>
						<Box>
							{data.attachments && data.attachments.length > 0 && (
								<IconButton
									className={styles.iconAction}
									variant='outlined'
									alt='Acessar anexos'
									handleClick={onClickAttachment}
								>
									<AttachFileIcon variant='outlined' fontSize='small' className={styles.iconActionSymbol} />
								</IconButton>
							)}
						</Box>
						<Box>
							{(data.linkType === 'external' || data.linkType === 'filters' || data.linkType === 'leadId') && (
								<IconButton
									className={styles.iconAction}
									variant='outlined'
									alt={data.linkType === 'leadId' ? 'Clique para acessar o Lead' : 'Acessar'}
									handleClick={() => {
										window.location.href = data.link;
										gtag('event', 'alert_link', {
											notification_link_id: data._id,
											notification_link_type: data.linkType,
										});
									}}
								>
									<LinkOutlinedIcon variant='outlined' fontSize='small' className={styles.iconActionSymbol} />
								</IconButton>
							)}
							{data.linkType === 'failedWhatsappMessage' && (
								<Button
									variant='contained'
									style={{
										backgroundColor: 'rgba(0,0,0,.75)',
										marginBlock: '20px 10px',
									}}
								>
									<a href={data.link} target='blank' rel='noopener noreferrer'>
										<div style={{color: 'rgba(240,240,240,.95)'}}>Acessar Lead/Cliente</div>
									</a>
								</Button>
							)}

							{data.linkType === 'termos' && (
								<IconButton
									className={styles.iconAction}
									variant='outlined'
									alt='Acessar'
									handleClick={() => {
										setUserTerms(true);
									}}
								>
									<LinkOutlinedIcon variant='outlined' fontSize='small' className={styles.iconActionSymbol} />
								</IconButton>
							)}

							{data.linkType === 'survey' && (
								<IconButton
									className={styles.iconAction}
									variant='outlined'
									alt={
										!data.endDate || new Date(data.endDate) < new Date()
											? 'A pesquisa chegou ao fim'
											: 'Responder pesquisa'
									}
									handleClick={() => {
										if (!data.endDate || new Date(data.endDate) < new Date()) {
											return;
										}
										setOpenSurvey(data);
									}}
								>
									<LinkOutlinedIcon variant='outlined' fontSize='small' className={styles.iconActionSymbol} />
								</IconButton>
							)}
						</Box>
					</Box>

					<Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
						<Typography variant='caption' className={styles.createdAt}>
							{formatDateHour(data.createdAt)}
						</Typography>
						<IconButton
							className={styles.iconActionRead}
							variant='outlined'
							alt={read ? 'Marcar como não lida' : 'Marcar como lida'}
							handleClick={handleToggleMarkAsRead}
						>
							{read ? (
								<DoneAllOutlinedIcon
									variant='outlined'
									fontSize='medium'
									className={cn(styles.iconActionReadSymbol, styles.iconDoneAll)}
								/>
							) : (
								<DoneIcon variant='outlined' fontSize='medium' className={styles.iconActionReadSymbol} />
							)}
						</IconButton>
					</Box>
				</CardActions>
			</Card>
		);
	},
);

AlertCard.propTypes = {
	className: PropTypes.string,
	data: PropTypes.shape({
		_id: PropTypes.string,
		title: PropTypes.string,
		createdAt: PropTypes.string,
		linkType: PropTypes.oneOf(['external', 'filters', 'leadId', 'failedWhatsappMessage', 'termos', 'survey']),
		recipients: PropTypes.shape({
			status: PropTypes.oneOf(['unread', 'read']),
		}),
		attachments: PropTypes.array,
	}),
	type: PropTypes.oneOf(['nimbus', 'btg']),
};

AlertCard.displayName = 'AlertCard';
export default AlertCard;
