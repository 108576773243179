import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

const platformIndicatorsRoutes = [
	{
		component: LazyComponent(lazy(() => import('./Indicators'))),
		path: '/platform-indicators',
	},
];

export default platformIndicatorsRoutes;
