import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

const statusRoutes = [
	{
		component: LazyComponent(lazy(() => import('./Create'))),
		path: '/status/create',
	},
	{
		component: LazyComponent(lazy(() => import('./Update'))),
		path: '/status/update/:id',
	},
];

export default statusRoutes;
