import React from 'react';
import {useAppValue} from 'redux/hooks/app';

import styles from './LoadingIndicator.module.css';

function LoadingIndicator() {
  const isRequesting = useAppValue('isRequesting');

  // FIXME: comoponent usa nome loading e state usa requesting
  if (!isRequesting) return null;
  return (
    <div className={styles.loadingIndicator}/>
  );
}

export default LoadingIndicator;
