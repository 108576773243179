import React from 'react';

export function AlertUnreadIcon({...props}) {
	return (
		<svg {...props} width='62' height='61' viewBox='0 0 62 61' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M21.456 12.2381L17.7706 8.52727C21.7864 5.85852 26.5902 4.30811 31.7752 4.30811C45.8052 4.30811 57.1919 15.6948 57.1919 29.7248C57.1919 34.9098 55.6414 39.7135 52.9727 43.7294L49.2619 40.0185C51.0664 36.9939 52.1085 33.4864 52.1085 29.7248C52.1085 18.516 42.9839 9.39144 31.7752 9.39144C28.0135 9.39144 24.506 10.4335 21.456 12.2381ZM46.161 23.4469L42.5773 19.8631L35.8419 26.5985L39.4256 30.1823L46.161 23.4469ZM51.5494 56.6919L45.7798 50.9223C41.7639 53.591 36.9602 55.1414 31.7752 55.1414C17.7452 55.1414 6.35852 43.7548 6.35852 29.7248C6.35852 24.5398 7.90894 19.736 10.5777 15.7202L4.80811 9.9506L8.39186 6.36686L55.1077 53.0827L51.5494 56.6919ZM42.0944 47.2114L32.2327 37.3498L28.1914 41.391L17.4148 30.6144L20.9985 27.0306L28.1914 34.2235L28.6489 33.766L14.2885 19.4056C12.4839 22.4556 11.4419 25.9631 11.4419 29.7248C11.4419 40.9335 20.5664 50.0581 31.7752 50.0581C35.5369 50.0581 39.0444 49.016 42.0944 47.2114Z'
				fill='#E2E2E2'
			/>
			<rect
				x='51.5964'
				y='56.6616'
				width='66.0533'
				height='4.92084'
				transform='rotate(-135 51.5964 56.6616)'
				fill='#C4C4C4'
			/>
		</svg>
	);
}

AlertUnreadIcon.displayName = 'AlertUnreadIcon';
export default AlertUnreadIcon;
