/* eslint-disable import/prefer-default-export */

import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {get, post} from 'redux/actionCreators/fetch';

export function useDispatchGet() {
	const dispatch = useDispatch();
	return useCallback(url => dispatch(get(url)), [dispatch]);
}

export function useDispatchPost() {
	const dispatch = useDispatch();
	return useCallback((url, data, customJwt) => dispatch(post(url, data, customJwt)), [dispatch]);
}
