import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

const webhookRoutes = [
	{
		component: LazyComponent(lazy(() => import('./Update'))),
		path: '/webhook/update/:id',
	},
];

export default webhookRoutes;
