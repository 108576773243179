import LazyComponent from 'components/LazyComponent';
import {lazy} from 'react';

const leadRoutes = [
	{
		component: LazyComponent(lazy(() => import('./UpdateExcel'))),
		path: '/lead/update',
	},
	{
		component: LazyComponent(lazy(() => import('./KanbanLeadList'))),
		path: '/kanban-lead-list',
	},
];

export default leadRoutes;
