import {Drawer as MUIDrawer} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {guard} from '@nimbus-target/ui';
import IconButton from 'components/buttons/IconButton';
import PropTypes from 'prop-types';
import {Fragment, useCallback, useEffect} from 'react';

const useStyles = makeStyles(theme => ({
	paper: {
		overflow: 'hidden',
		[theme.breakpoints.up('500')]: {
			width: 400,
		},
		[theme.breakpoints.down('500')]: {
			width: '100%',
		},
	},
	header: {
		'display': 'flex',
		'height': 50,
		'alignItems': 'center',
		'margin': '0 5px',
		'flexDirection': ({direction}) => (!direction || direction === 'left' ? 'row-reverse' : 'row'),
		'& > span': {
			width: '100%',
			textAlign: 'center',
		},
	},
	body: {
		height: 'inherit',
		width: '100%',
		padding: 10,
		boxSizing: 'border-box',
		background: theme.palette.type === 'dark' ? '#333333' : theme.palette.supportOne,
		overflow: ({overflow}) => {
			return overflow ?? 'auto';
		},
	},
}));

function Drawer({
	children,
	direction,
	disableCloseButton,
	isOpen,
	onClose,
	title = null,
	header = null,
	enableBackdropInteraction,
	disableBackdropClick,
	disableEscapeKeyDown,
	overflow,
	...props
}) {
	const styles = useStyles({direction, overflow});

	const handleClose = useCallback(
		(_evt, reason) => {
			const disableBackdrop = disableBackdropClick || enableBackdropInteraction;
			if ((disableBackdrop && reason === 'backdropClick') || (disableEscapeKeyDown && reason === 'escapeKeyDown')) {
				return;
			}
			if (onClose) onClose();
		},
		[disableEscapeKeyDown, disableBackdropClick, enableBackdropInteraction, onClose],
	);

	useEffect(() => {
		if (isOpen) {
			window.history.pushState(null, document.title, window.location.href);
		}
	}, [isOpen]);

	// backbutton and gestures close Drawer
	useEffect(() => {
		window.addEventListener('popstate', handleClose);
		return () => window.addEventListener('popstate', handleClose);
	}, [handleClose]);

	return (
		<Fragment>
			<MUIDrawer
				open={isOpen}
				classes={{paper: styles.paper}}
				anchor={direction}
				hideBackdrop={enableBackdropInteraction ? true : false} // Disable the backdrop color/image
				disableEnforceFocus={enableBackdropInteraction ? true : false} // Let the user focus on elements outside the dialog
				style={enableBackdropInteraction ? {position: 'initial'} : {}} // This is the key, reset the position of the dialog, so the user can interact with other elements
				// @see https://stackoverflow.com/a/69179372/1345327
				// disableBackdropClick={disableBackdropClick || enableBackdropInteraction ? true : false} // Remove the backdrop click (just to be sure)
				onClose={handleClose}
				{...props}
			>
				{header ?? (
					<div className={styles.header}>
						<IconButton handleClick={handleClose} disabled={disableCloseButton}>
							<CancelOutlinedIcon />
						</IconButton>
						{guard.isString(title) ? <span>{title}</span> : title}
					</div>
				)}
				<section className={styles.body}>{children}</section>
			</MUIDrawer>
		</Fragment>
	);
}

Drawer.propTypes = {
	children: PropTypes.node,
	direction: PropTypes.string,
	disableBackdropClick: PropTypes.bool,
	disableCloseButton: PropTypes.bool,
	disableEscapeKeyDown: PropTypes.bool,
	enableBackdropInteraction: PropTypes.bool, // Set true if you want to disable backdrop and enable interactions
	header: PropTypes.node,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	overflow: PropTypes.oneOf(['hidden', 'auto']),
	title: PropTypes.node,
};

export default Drawer;
