import React, {Fragment} from 'react';
import {DataLayer} from '@nimbus-target/ui';
import {makeStyles} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import IconButton from 'components/buttons/IconButton';

function gtag(...rest) {
	const args = rest.slice(1);
	const event = args.shift().replace(/(-|\s)/g, '_');
	DataLayer.push(...[event].concat(args));
}

const useStyles = makeStyles(theme => ({
	icon: {
		fontSize: 28,
		[theme.breakpoints.down('1300')]: {
			fontSize: 22,
		},
		[theme.breakpoints.down('1100')]: {
			fontSize: 20,
		},
	},
	badge: {
		background: theme.palette.success.main,
	},
	moreIconsButtons: {
		'color': 'rgba(0,0,0,0.6) !important',
		'fontSize': '1.25rem',
		'& span': {
			'& svg': {
				marginRight: 10,
			},
		},
	},
}));

function FavoriteLeadsButton(props) {
	const {setDrawer} = props;
	const classes = useStyles();

	return (
		<Fragment>
			<IconButton
				alt='Leads favoritos'
				handleClick={() => {
					setDrawer(true);
					gtag('event', 'favorite-leads-button');
				}}
				data-tour='tour_home_menu_favorites'
				className={classes.moreIconsButtons}
			>
				<StarIcon className={classes.icon} />
				Leads favoritos
			</IconButton>
		</Fragment>
	);
}

export default FavoriteLeadsButton;
