import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

const timetableRoutes = [
	{
		component: LazyComponent(lazy(() => import('./List'))),
		path: '/timetable/list',
	},
];

export default timetableRoutes;
