import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	root: {},
	video: {
		maxWidth: '100%',
	},
}));

/**
 * @see https://github.com/Nimbus-Target/ui/blob/beta/src/shared/components/media/Player/index.tsx
 */
export const Player = ({url, ...props}) => {
	const styles = useStyles();
	return (
		<Box className={styles.root} display='flex' justifyContent='center' width='100%' minHeight='200px' {...props}>
			<video controls={true} className={styles.video}>
				<source src={url} type='video/mp4' />
			</video>
		</Box>
	);
};

Player.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	classes: PropTypes.object,
	url: PropTypes.string,
};

Player.displayName = 'Player';
export default Player;
