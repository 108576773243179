import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

export const AlertAttachmentModalPreviewText = ({title: _, description, children, ...props}) => {
	return (
		<Typography style={{whiteSpace: 'pre-wrap'}} {...props}>
			{description}
		</Typography>
	);
};

AlertAttachmentModalPreviewText.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	classes: PropTypes.object,
	description: PropTypes.string,
	title: PropTypes.string,
};

AlertAttachmentModalPreviewText.displayName = ' AlertAttachmentModal.PreviewText';
export default AlertAttachmentModalPreviewText;
