import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

const bestMatchRoutes = [
	{
		component: LazyComponent(lazy(() => import('./Update'))),
		path: '/best-match/update/:id',
	},
];

export default bestMatchRoutes;
