import React, {useEffect} from 'react';
import {useDispatchPost} from 'redux/hooks/fetch';
import {useUserContext} from 'context';
import SwipeableViews from 'react-swipeable-views';
import {formatDateHour} from 'utils/moment';
import {useHistory} from 'react-router-dom';

import {
	Button,
	Typography,
	Card,
	CardContent,
	CardActions,
	makeStyles,
	AppBar,
	Tabs,
	Tab,
	useTheme,
} from '@material-ui/core';
import TabContent from 'components/tabs/TabContent';
import {useDispatchSetAppInfoMesssage} from 'redux/hooks/app';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
	card: {
		marginBottom: theme.spacing(1),
	},
	appBar: {
		marginBottom: theme.spacing(1),
	},
	content: {
		'width': '100%',
		//display: 'flex',
		'padding': 0,
		'&:last-child': {
			paddingBottom: theme.spacing(1),
		},
	},
	icon: {
		color: theme.palette.success.main,
		fontSize: 20,
		marginRight: theme.spacing(1),
	},
	linkBtn: {
		marginRight: theme.spacing(1.5),
	},
	tabContent: {
		padding: '0px',
	},
	cardAction: {
		justifyContent: 'center',
	},
}));

function UsersTask(props) {
	const {onClose} = props;

	const classes = useStyles();
	const theme = useTheme();

	const {isViewOnly, _id} = useUserContext();

	const post = useDispatchPost();
	const history = useHistory();

	const [currentTab, setCurrentTab] = React.useState(0);
	const [tasks, setTasks] = React.useState([]);

	const setAppInfo = useDispatchSetAppInfoMesssage();

	const getTasks = () => {
		let filter;

		setTasks([]);
		if (currentTab === 0) {
			filter = 'todo';
		}

		if (currentTab === 1) {
			filter = 'future';
		}

		if (currentTab === 2) {
			filter = 'done';
		}

		post('/lead/schedule-list', {filter}).then(responseData => {
			setTasks(responseData.data);
		});
	};
	useEffect(() => {
		getTasks();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentTab]);

	const a11yProps = index => {
		return {
			'id': `scrollable-force-tab-${index}`,
			'aria-controls': `scrollable-force-tabpanel-${index}`,
		};
	};

	const handleChange = (event, newValue) => {
		setCurrentTab(newValue);
	};

	const handleDoneSchedule = id => {
		post('/lead/schedule-done', {id}).then(response => {
			getTasks();
		});
	};

	const handleRemoveSchedule = id => {
		post('/lead/schedule-remove', {id}).then(response => {
			getTasks();
		});
	};

	const showTasks = () => {
		if (tasks.length === 0) {
			if (currentTab === 0) return <center>Nenhuma tarefa à fazer futura</center>;
			if (currentTab === 1) return <center>Nenhuma tarefa vencida</center>;
			if (currentTab === 2) return <center>Nenhuma tarefa concluida</center>;
		}
		return tasks?.map(data => {
			let date;

			// let date
			if (currentTab === 0) date = `Agendada para ${formatDateHour(data.dateScheduled)}`;

			if (currentTab === 1) date = `Foi agendado para ${formatDateHour(data.dateScheduled)}`;

			return (
				<React.Fragment
					style={{
						marginTop: '20px',
						right: '10px',
					}}
				>
					<Card key={data._id} className={classes.card}>
						<CardContent className={classes.content}>
							<Typography align={'center'}>
								{_id !== data.userId ? <p>{`--- Criada por: ${data.userName} ---`}</p> : <p>Minha Tarefa</p>}
								<b>{data.taskDescription}</b>
							</Typography>

							<Typography align={'center'}>{data.lead && data.lead[0] ? data.lead[0].name : '-'}</Typography>

							<Typography align={'center'}>{date}</Typography>
						</CardContent>
						<CardActions classes={{root: classes.cardAction}}>
							<center textAlign='center'>
								<Button
									size={'small'}
									onClick={() => {
										onClose();
										history.push('/chat/' + data.lead[0]._id);
										setAppInfo('Lead selecionado');
									}}
								>
									<VisibilityIcon className={classes.linkBtn} />
									Ver
								</Button>

								{(currentTab === 0 || currentTab === 1) && (
									<React.Fragment>
										<Button
											size={'small'}
											onClick={() => {
												if (isViewOnly) {
													setAppInfo('Você não tem permissão para realizar esta ação');
													return;
												}
												handleDoneSchedule(data._id);
											}}
										>
											<DoneIcon className={classes.linkBtn} />
											Feito
										</Button>

										<Button
											size={'small'}
											onClick={() => {
												if (isViewOnly) {
													setAppInfo('Você não tem permissão para realizar esta ação');
													return;
												}
												handleRemoveSchedule(data._id);
											}}
										>
											<DeleteIcon className={classes.linkBtn} />
											Remover
										</Button>
									</React.Fragment>
								)}
							</center>
						</CardActions>
					</Card>
				</React.Fragment>
			);
		});
	};

	const renderTasks = () => {
		return (
			<React.Fragment>
				<AppBar position='static' color='transparent' className={classes.appBar}>
					<Tabs
						value={currentTab}
						onChange={handleChange}
						indicatorColor='primary'
						textColor='primary'
						variant='scrollable'
						centered={true}
						aria-label='scrollable-tabs'
					>
						<Tab label='Futuras' {...a11yProps(0)} />
						<Tab label='Vencidos' {...a11yProps(1)} />
						<Tab label='Concluidas' {...a11yProps(1)} />
					</Tabs>
				</AppBar>
				<SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={currentTab} tabIndex={currentTab}>
					<TabContent value={currentTab} index={0} className={classes.tabContent}>
						<React.Fragment>{showTasks()}</React.Fragment>
					</TabContent>
					<TabContent value={currentTab} index={1} className={classes.tabContent}>
						<React.Fragment>{showTasks()}</React.Fragment>
					</TabContent>
					<TabContent value={currentTab} index={2} className={classes.tabContent}>
						<React.Fragment>{showTasks()}</React.Fragment>
					</TabContent>
				</SwipeableViews>
			</React.Fragment>
		);
	};

	return <div>{renderTasks()}</div>;
}

export default UsersTask;
