// modules
import {Button, Dialog, DialogContent, Slider, makeStyles} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CallEndOutlinedIcon from '@material-ui/icons/CallEndOutlined';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import MicOffIcon from '@material-ui/icons/MicOff';
import {Skeleton} from '@material-ui/lab';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useLocation} from 'react-router-dom';

// components
import IconButton from 'components/buttons/IconButton';
import Select from 'components/inputs/Select';

// functions
import {VolumeDown, VolumeUp} from '@material-ui/icons';
import {useAppValue, useDispatchSetAppValue} from 'redux/hooks/app';
import {useDispatchPost} from 'redux/hooks/fetch';
import {checkPhoneHiddenAndFormat} from 'utils/masks';

const useStyles = makeStyles(theme => ({
	flexColumn: {
		'display': 'flex',
		'flexDirection': 'column',
		'alignItems': 'center',
		'& svg': {
			height: 32,
			width: 32,
		},
	},
	status: {
		margin: '0px 20px',
		fontSize: 18,
		color: theme.palette.supportOne,
	},
	leadInfo: {
		color: theme.palette.supportOne,
		fontSize: 16,
		// margin: '2px 20px',
	},
	userIcon: {
		fontSize: 46,
		color: `${theme.palette.supportOne} !important`,
	},
	icon: {
		fontSize: 36,
		color: `${theme.palette.supportOne} !important`,
	},
	voipNumber: {
		width: 200,
		color: theme.palette.supportOne,
	},
	paper: {
		height: '100%',
		width: '100%',
		margin: 0,
		maxHeight: '100%',
		maxWidth: '100%',
		background: 'transparent',
	},
}));

function Webphone() {
	const {control} = useForm();

	const Janus = useRef(window.Janus);
	const ua = useRef(null);
	const sipcall = useRef(null);
	const audioRemote = useRef(document.getElementById('audio_remote'));
	const ringbacktone = useRef(window.ringbacktone);
	const ringtone = useRef(window.ringtone);
	const turnOffBubble = useRef(window.turn_off_bubble);

	const [incoming, setIncoming] = useState(null);
	const [incomingJSEP, setIncomingJSEP] = useState(null);

	const handshake = useAppValue('VOIP_handshake');
	const setHandshake = useDispatchSetAppValue('VOIP_handshake');
	const registered = useAppValue('VOIP_registered');
	const setRegistered = useDispatchSetAppValue('VOIP_registered');

	const disconnected = useAppValue('VOIP_disconnected');
	const setDisconnected = useDispatchSetAppValue('VOIP_disconnected');

	const [mute, setMute] = useState(false);
	const [volume, setVolume] = useState(100); // 0; 10; 20; 30; [...]; 100
	const [lastVolume, setLastVolume] = useState(100); // when to use silence and not silence in sequence

	const [startHour, setStartHour] = useState(0);
	const [timer, setTimer] = useState(0);

	const [loading, setLoading] = useState(false);

	const [contadorTentativaConexao, setContadorTentativaConexao] = useState(0);

	const fieldConfig = useAppValue('fieldConfig');

	const post = useDispatchPost();

	const selectedLeadData = useAppValue('selectedLeadData');

	// states to call
	const state = useAppValue('VOIP_callStatus');
	const setState = useDispatchSetAppValue('VOIP_callStatus');

	const setVoipIsActive = useDispatchSetAppValue('VOIP_isActiveComponent');

	const voipModal = useAppValue('voip_modal');
	const setVoipModal = useDispatchSetAppValue('voip_modal');

	const {pathname} = useLocation();
	const voipEmbedded = pathname.includes('/webphone');

	const phonetoVoip = useAppValue('VOIP_PhonetoVoip');
	const setPhonetoVoip = useDispatchSetAppValue('VOIP_PhonetoVoip');
	const bina = useAppValue('VOIP_bina');
	const binaIsValid = useAppValue('VOIP_binaIsValid');
	const currentLeadData = useAppValue('VOIP_LeadData');
	const setCurrentLeadData = useDispatchSetAppValue('VOIP_LeadData');

	const setBina = useDispatchSetAppValue('VOIP_bina');
	const setBinaIsValid = useDispatchSetAppValue('VOIP_binaIsValid');
	const ramalNumber = useAppValue('VOIP_ramalNumber');
	const setRamalNumber = useDispatchSetAppValue('VOIP_ramalNumber');

	const host = useAppValue('voip_host');
	const setHost = useDispatchSetAppValue('voip_host');
	const username = useAppValue('voip_username');
	const setUsername = useDispatchSetAppValue('voip_username');
	const secret = useAppValue('voip_secret');
	const setSecret = useDispatchSetAppValue('voip_secret');

	const ownRamal = useAppValue('voip_own_ramal');
	const setOwnRamal = useDispatchSetAppValue('voip_own_ramal');

	const setisTwilioVoip = useDispatchSetAppValue('is_twilio_voip_provider');

	// voice-sip.zenvia.com // Zenvia Host
	// 8c498b2aace38b8340567d_at_nt.digital // Zenvia username
	// Henrique@987 // Zenvia secret

	// sip.nexmo.com // Vonage Host
	// ed798e6f // Vonage username
	// iVc7n2TKXs6ZBfwY // Vonage secret

	// nimbusdigital.sip.twilio.com // Twilio Host
	// henrique.dias // Twilio username
	// Henrique@987 // Twilio secret

	useEffect(() => {
		if (!host) getRamal();
		return () => false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [host]);

	const getRamal = () => {
		post('/voip-ramal/get-ramal')
			.then(response => {
				if (response.data) {
					const ramal = response.data;
					setOwnRamal(!!ramal.userId);
					setHost(ramal.host);
					setUsername(ramal.username);
					setSecret(ramal.secret);
					setBina(ramal.bina);
					setBinaIsValid(ramal.binaIsValid);
					setRamalNumber(ramal.ramalNumber);
					if (ramal.type === 'twilio') {
						setisTwilioVoip(true);
					}
				}
			})
			.catch(error => {
				console.error(error);
			});
	};

	const createCall = () => {
		post('/voip-call/create-call', {
			leadId: selectedLeadData._id,
			mainId: selectedLeadData.mainId,
			customerName: selectedLeadData.name,
			phone: phonetoVoip,
			ramalNumber,
			ramalUsername: username,
		})
			.then(response => {
				if (response.data) {
					// call in progress saved to receive webhook with call data
				}
			})
			.catch(error => {
				console.error(error);
			});
	};

	const switchCallStatus = useMemo(() => {
		if (registered && state !== 'onCall' && state !== 'dialing' && state !== 'ringing') {
			return 'ready';
		} else if (registered && (state === 'onCall' || state === 'dialing' || state === 'ringing')) {
			return 'incoming';
		} else if (!registered) {
			return 'unregistered';
		}
	}, [registered, state]);

	// manage call volume
	// useEffect(() => {
	//   audioRemote.current.volume = volume / 100;
	// }, [volume]);

	useEffect(() => {
		if (switchCallStatus === 'ready' || switchCallStatus === 'unregistered') {
			setCurrentLeadData(selectedLeadData);
		}
	}, [selectedLeadData, setCurrentLeadData, switchCallStatus]);

	useEffect(() => {
		if (state === 'onCall') {
			setStartHour(Date.now());
		} else {
			setStartHour(0);
		}
	}, [state]);

	useEffect(() => {
		if (state === 'onCall' || state === 'dialing' || state === 'ringing') {
			setVoipIsActive(true);
		} else {
			setVoipIsActive(false);
		}
	}, [setVoipIsActive, state]);

	useEffect(() => {
		let timerInterval = setInterval(() => {
			if (startHour > 0) {
				// time in call
				setTimer((Date.now() - startHour) / 1000);
			}
		}, 1000);
		return () => clearInterval(timerInterval);
	}, [startHour]);

	const renderTimer = timer => {
		if (startHour > 0 && timer >= 0) {
			return new Date(timer * 1000).toISOString().slice(11, 19);
		} else {
			return '00:00:00';
		}
	};

	const registerSIP = () => {
		var register = {
			request: 'register',
			username: `sip:${username}@${host}`,
		};

		register['secret'] = secret;
		register['proxy'] = `sip:${host}`;
		sipcall.current.send({message: register});
	};

	function startRingbackTone() {
		try {
			ringbacktone.current.play();
		} catch (e) {}
	}

	function stopRingbackTone() {
		try {
			ringbacktone.current.pause();
		} catch (err) {
			// Log
			console.error(err);
			// Try again after a second
			setTimeout(() => {
				ringbacktone.current.pause();
			}, 1000);
		}
	}

	function startRingTone() {
		try {
			ringtone.current.play();
		} catch (e) {}
	}

	function stopRingTone() {
		try {
			ringtone.current.pause();
		} catch (err) {
			// Log
			console.error(err);
			// Try again after a second
			setTimeout(() => {
				ringtone.current.pause();
			}, 1000);
		}
	}

	function bubbleTone(volume = 50) {
		try {
			turnOffBubble.current.volume = volume / 100;
			turnOffBubble.current.play();
		} catch (e) {}
	}

	const startCall = () => {
		if (incoming) {
			sipcall.current.createAnswer({
				jsep: incomingJSEP,
				media: {audio: true, video: false},
				success: function (jsep) {
					Janus.current.debug('Got SDP! audio=' + true + ', video=' + false);
					Janus.current.debug(jsep);
					var body = {request: 'accept'};
					sipcall.current.send({message: body, jsep: jsep});

					chamadaConectou();
				},
				error: function (error) {
					Janus.current.error('WebRTC error:', error);

					var body = {request: 'decline', code: 480};
					sipcall.current.send({message: body});
				},
			});
		} else {
			if (handshake) {
				// remove mute and silence
				sipcall.current.unmuteAudio();
				setMute(false);
				audioRemote.current.volume = 1;
				setVolume(100);

				var callTo = `sip:${phonetoVoip}@${host}`; // Vonage; use contry code (e.g 5511986759008)
				console.log('Voip callTo:', callTo);
				sipcall.current.createOffer({
					media: {
						audioSend: true,
						audioRecv: true, // We DO want audio
						videoSend: false,
						videoRecv: false, // We MAY want video
					},
					success: function (jsep) {
						startRingbackTone();
						var body = {request: 'call', uri: callTo, autoack: false};
						sipcall.current.send({message: body, jsep: jsep});

						setState('dialing');

						window.postMessage('onCall');

						createCall();
					},
					error: function (error) {
						if (error === 'No capture device found') {
							alert('Microfone não encontrado');
						}
						console.log('WebRTC error:', error);
						alert('WebRTC error... ' + JSON.stringify(error));
					},
				});
			}

			if (!handshake) {
				console.info('not connected');
			}
		}
	};

	function doHangup() {
		var hangup = '';
		if (state === 'ringing') {
			if (incoming) {
				console.info('IS RINGING: - decline call\n\n');
				hangup = {request: 'decline'};
			} else {
				console.info('RINGING True: HANGUP\n');
				hangup = {request: 'hangup'};
			}
		} else {
			console.info('RINGING False: HANGUP\n');
			hangup = {request: 'hangup'};
		}

		sipcall.current.send({message: hangup});
		sipcall.current.hangup();

		window.postMessage('hangup');

		// stop ringtone
		stopRingbackTone();
		stopRingTone();

		// reset state
		setState('');
	}

	const handleDisconnect = err => {
		console.error(err);
		setDisconnected(true);
		setRegistered(false);
		setState('');
		setIncoming(false);

		doHangup();
	};

	function handleMute() {
		bubbleTone();
		if (!mute) {
			sipcall.current.muteAudio();
			setMute(true);
		} else {
			sipcall.current.unmuteAudio();
			setMute(false);
		}
	}

	function handleVolume(volume) {
		bubbleTone(volume);
		setVolume(volume);
		audioRemote.current.volume = volume / 100;
	}

	function chamadaConectou() {
		// if (!voipModal) return;

		if (window.btnBFCP) window.btnBFCP.disabled = false;
		stopRingbackTone();
		stopRingTone();

		setState('onCall');
	}

	const init = () => {
		Janus.current.init({
			debug: true,
			callback: function () {
				ua.current = new Janus.current({
					server: 'https://voice-gw05.zenvia.com/webphone3',
					// server: "wss://janus.conf.meetecho.com/ws", // estudar qual o melhor server para utilizar, ou criar o nosso? qualquer um tem servido
					destroyOnUnload: false,
					iceServers: [
						{urls: 'stun:turn-server.totalsip.com.br:3478'},
						{
							urls: 'turn:turn-server.totalsip.com.br',
							credential: 'zenviaturn',
							username: 'totalvoiceturn',
						},
						{urls: 'stun:stun.l.google.com:19302'},
					],
					success: () => {
						setHandshake(true);

						ua.current.attach({
							plugin: 'janus.plugin.sip',
							opaqueId: 'siptest-' + Janus.current.randomString(12),
							success: pluginHandle => {
								sipcall.current = pluginHandle;
								Janus.current.log(
									'Plugin attached! (' + sipcall.current.getPlugin() + ', id=' + sipcall.current.getId() + ')',
								);

								registerSIP();
							},
							error: error => {
								Janus.current.error('  -- Error attaching plugin...', error);
							},
							consentDialog: on => {
								Janus.current.debug('Consent dialog should be ' + (on ? 'on' : 'off') + ' now');
							},
							onmessage: (msg, jsep) => {
								Janus.current.debug(' ::: Got a message :::');
								Janus.current.debug(JSON.stringify(msg));

								var error = msg['error'];
								if (error !== null && error !== undefined) {
									if (!registered) {
									} else {
										sipcall.current.hangup();
									}

									Janus.current.debug(' ::: ERRORRR :::');
									return;
								}
								var result = msg['result'];
								if (
									result !== null &&
									result !== undefined &&
									result['event'] !== undefined &&
									result['event'] !== null
								) {
									var event = result['event'];
									if (event === 'registration_failed') {
										stopRingbackTone();
										//setTimeout(function(){
										//    window.location.reload(true);
										//}, 7000);
										handleDisconnect();
									}
									if (event === 'registered') {
										Janus.current.log('Successfully registered as ' + result['username'] + '!');
										setRegistered(true);
										setLoading(false);
										setDisconnected(false); // remove disconnect
									} else if (event === 'calling') {
										Janus.current.log('Waiting for the peer to answer...');
									} else if (event === 'incomingcall') {
										setIncoming(true);
										setIncomingJSEP(jsep);
										Janus.current.log(
											'Incoming call from ' +
												result['displayname'] +
												' - ' +
												result['sip_from'] +
												' - ' +
												result['username'],
										);
										var doAudio = true,
											doVideo = false;
										if (jsep !== null && jsep !== undefined) {
											// What has been negotiated?
											doAudio = jsep.sdp.indexOf('m=audio ') > -1;
											doVideo = jsep.sdp.indexOf('m=video ') > -1;
											Janus.current.debug('Audio ' + (doAudio ? 'has' : 'has NOT') + ' been negotiated');
											Janus.current.debug('Video ' + (doVideo ? 'has' : 'has NOT') + ' been negotiated');
										}
										// eslint-disable-next-line no-unused-vars
										// var rtpType = '';
										// var srtp = result['srtp'];
										// if (srtp === 'sdes_optional') rtpType = ' (SDES-SRTP offered)';
										// else if (srtp === 'sdes_mandatory') rtpType = ' (SDES-SRTP mandatory)';

										// TODO notifica o usuario aqui de chamada chegando
										startRingTone();
										setState('ringing');
									} else if (event === 'accepting') {
										// Response to an offerless INVITE, let's wait for an 'accepted'
									} else if (event === 'progress') {
										Janus.current.log('ESTADO:' + state + '\n\n');
										if (state == null) {
											setContadorTentativaConexao(contadorTentativaConexao + 1);
											if (contadorTentativaConexao < 5) {
												// TODO reconnect
												// sipRegister();
											} else {
												// recarrega as configs
												setTimeout(function () {
													// window.location.reload(true);
												}, 1000);
											}
											//displayDesconectado(false, error, error, error);
											//setTimeout(function(){
											//    window.location.reload(true);
											//}, 1000);
										} else {
											setState('ringing');
											stopRingbackTone();
											Janus.current.log("There's early media from " + result['username'] + ', wairing for the call!');
											Janus.current.log(jsep);
											// Call can start already: handle the remote answer
											if (jsep !== null && jsep !== undefined) {
												sipcall.current.handleRemoteJsep({
													jsep: jsep,
													error: doHangup,
												});
											}
											Janus.current.log('Early media...');
										}
									} else if (event === 'accepted') {
										//result["username"]
										Janus.current.log(result['username']);
										// TODO Video call can start
										if (jsep !== null && jsep !== undefined && state !== 'ringing') {
											sipcall.current.handleRemoteJsep({
												jsep: jsep,
												error: doHangup,
											});
										}

										chamadaConectou();
									} else if (event === 'hangup') {
										setIncoming(false);
										Janus.current.log('Call hung up (' + result['code'] + ' ' + result['reason'] + ')!');

										doHangup();
									}
								}
							},
							onlocalstream: function (stream) {
								Janus.current.debug(' ::: Got a local stream :::');
								Janus.current.debug(JSON.stringify(stream));
								// Janus.attachMediaStream(audioRemote, stream);
							},
							onremotestream: function (stream) {
								Janus.current.debug(' ::: Got a remote stream :::');
								Janus.current.debug(JSON.stringify(stream));
								Janus.current.attachMediaStream(audioRemote.current, stream);
							},
							oncleanup: function () {
								Janus.current.log(' ::: Got a cleanup notification :::');
								setIncoming(false);
							},
						});
					},
					error: error => {
						setLoading(false);
						handleDisconnect(error);
					},
					destroyed: error => {
						setLoading(false);
						handleDisconnect(error);
					},
				});
			},
		});
	};

	const MultiNumber = () => {
		if (!voipModal) return;

		let options = [];

		if (selectedLeadData.phoneWhatsapp) {
			options.push({
				label: checkPhoneHiddenAndFormat(
					selectedLeadData.phoneWhatsapp,
					fieldConfig,
					selectedLeadData.phoneWhatsappDDI,
				),
				id: selectedLeadData.phoneWhatsappDDI ? selectedLeadData.phoneWhatsapp : `55${selectedLeadData.phoneWhatsapp}`,
			});
		}

		if (selectedLeadData.phoneOther) {
			options.push({
				label: checkPhoneHiddenAndFormat(selectedLeadData.phoneOther, fieldConfig, selectedLeadData.phoneOtherDDI),
				id: selectedLeadData.phoneOtherDDI ? selectedLeadData.phoneOther : `55${selectedLeadData.phoneOther}`,
			});
		}

		if (selectedLeadData.contactInformation) {
			selectedLeadData.contactInformation.map(contact => {
				if (contact.phoneWhatsapp) {
					options.push({
						label: `${contact.name.substr(0, 10)} - ${checkPhoneHiddenAndFormat(
							contact.phoneWhatsapp,
							fieldConfig,
							contact.phoneWhatsappDDI,
						)}`,
						simpleLabel: checkPhoneHiddenAndFormat(contact.phoneWhatsapp, fieldConfig, contact.phoneWhatsappDDI),
						id: contact.phoneWhatsappDDI ? contact.phoneWhatsapp : `55${contact.phoneWhatsap}`,
					});
				}
				if (contact.phoneOther) {
					options.push({
						label: `${contact.name.substr(0, 10)} - ${checkPhoneHiddenAndFormat(
							contact.phoneOther,
							fieldConfig,
							contact.phoneOtherDDI,
						)}`,
						simpleLabel: checkPhoneHiddenAndFormat(contact.phoneOther, fieldConfig, contact.phoneOtherDDI),
						id: contact.phoneOtherDDI ? contact.phoneOther : `55${contact.phoneOther}`,
					});
				}
				return contact;
			});
		}

		if (!options || options.length === 0) {
			setPhonetoVoip('');
			return <span className={classes.voipNumber}>Cadastre um telefone</span>;
		}

		if (!phonetoVoip) setPhonetoVoip(options[0].id);

		if (!options.some(phone => phone.id === phonetoVoip)) {
			setPhonetoVoip(options[0].id);
		}

		if (switchCallStatus === 'incoming') {
			const {label: labelPhone, simpleLabel} = options.find(phone => phone.id === phonetoVoip);
			return <span className={classes.voipNumber}>{simpleLabel || labelPhone}</span>;
		}

		if (options.length === 1) {
			return <span className={classes.voipNumber}>{options[0].simpleLabel || options[0].label}</span>;
		}

		return (
			<span className={classes.leadInfo}>
				<Select
					defaultOptions={phonetoVoip}
					defaultValue={phonetoVoip}
					incomingValue={phonetoVoip}
					fullWidth={false}
					hasEmptyOption={false}
					name='voipNumber'
					control={control}
					className={classes.voipNumber}
					classNameSkeleton={classes.selectSkeleton}
					onChange={e => setPhonetoVoip(e.target.value)}
					dataOptions={options}
					requestResultParser={() => {}}
				/>
			</span>
		);
	};

	useEffect(() => {
		// if open voipModal and not found own ramal
		// create own ramal
		if (voipModal && !ownRamal) {
			post('/voip-ramal/create-user-ramal')
				.then(response => {
					if (response.data) {
						const ramal = response.data;
						setHost(ramal.host);
						setUsername(ramal.username);
						setSecret(ramal.secret);
						setBina(ramal.bina);
						setBinaIsValid(ramal.binaIsValid);
						setRamalNumber(ramal.ramalNumber);

						// reset register
						if (ua.current) ua.current.destroy();
						ua.current = null;

						setTimeout(() => {
							setOwnRamal(!!ramal.userId);
						}, 2000);
					}
				})
				.catch(error => {
					console.error(error);
				});
		}
	}, [
		ownRamal,
		post,
		setOwnRamal,
		setBina,
		setBinaIsValid,
		setHost,
		setRamalNumber,
		setSecret,
		setUsername,
		voipModal,
	]);

	useEffect(() => {
		// wait load ramal info
		if (!host || !username || !secret) return;

		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [host, secret, username]);

	const classes = useStyles();

	if (!ownRamal) {
		return (
			<Dialog open={Boolean(voipModal)} onClose={() => setVoipModal(false)}>
				<DialogContent
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-evenly',
						alignItems: 'center',
						textAlign: 'center',
						height: 200,
						width: 320,
						backgroundColor: '#32373b',
						padding: 0,
					}}
				>
					<CircularProgress />
					<div style={{paddingInline: 16}}>
						<span className={classes.leadInfo}>Aguarde enquanto preparamos seu Ramal</span>
					</div>
				</DialogContent>
			</Dialog>
		);
	}

	return (
		<React.Fragment>
			<Dialog
				open={Boolean(voipModal)}
				onClose={() => {
					if (voipEmbedded) return;
					setVoipModal(false);
				}}
				classes={{paper: voipEmbedded ? classes.paper : null}}
				style={{
					backgroundColor: voipEmbedded ? '#474e54' : 'transparent',
				}}
			>
				<DialogContent
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						height: voipEmbedded ? '100%' : 400,
						width: voipEmbedded ? '100%' : 320,
						backgroundColor: '#32373b',
						padding: '20px 0 0',
					}}
				>
					{incoming && (
						<div style={{paddingInline: 16}}>
							<span className={classes.leadInfo}>Chegando chamada</span>
						</div>
					)}
					{!incoming && (
						<div style={{paddingInline: 16}}>
							<span className={classes.leadInfo}>{`Bina: ${
								bina && binaIsValid ? checkPhoneHiddenAndFormat(bina, fieldConfig) : '-'
							}`}</span>
							<div
								style={{
									borderRadius: 10,
									marginTop: switchCallStatus === 'incoming' ? 10 : 100,
									marginBottom: 10,
									marginInline: 0,
									transition: 'margin .5s',
									padding: 10,
									backgroundColor: '#474e54',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<div style={{marginRight: 12}}>
									<AccountCircle className={classes.userIcon} />
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										maxWidth: '80%',
									}}
								>
									<span
										style={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											color: '#F3F3F3',
											fontSize: 16,
										}}
									>
										{currentLeadData.name || selectedLeadData.name}
									</span>
									{MultiNumber()}
								</div>
							</div>

							<div
								style={{
									borderRadius: 10,
									margin: '10px 0px',
									padding: 10,
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-around',
									alignItems: 'center',
									opacity: startHour > 0 ? 1 : 0,
									transition: '1s',
								}}
							>
								{startHour > 0 && <span className={classes.leadInfo}>{renderTimer(timer)}</span>}
							</div>

							<div
								style={{
									borderRadius: 10,
									margin: '10px 0px',
									padding: 10,
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-around',
									alignItems: 'center',
									transition: '.5s',
								}}
							>
								{switchCallStatus === 'incoming' && (
									<React.Fragment>
										<IconButton
											handleClick={handleMute}
											style={{
												borderRadius: 40,
												backgroundColor: mute ? '#474e54' : 'transparent',
											}}
										>
											{!mute ? <MicOffIcon className={classes.icon} /> : <MicOffIcon className={classes.icon} />}
										</IconButton>

										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												width: '90%',
											}}
										>
											<IconButton
												handleClick={() => {
													if (volume > 0) {
														setLastVolume(volume);
														handleVolume(0);
													} else {
														handleVolume(lastVolume);
													}
												}}
												style={{
													backgroundColor: volume === 0 ? '#474e54' : 'transparent',
													padding: 8,
												}}
											>
												<VolumeDown className={classes.icon} />
											</IconButton>
											<Slider
												value={volume}
												onChange={(_e, vol) => handleVolume(vol)}
												step={10}
												min={0}
												max={100}
												aria-labelledby='continuous-slider'
												style={{
													color: '#FAFAFA',
													marginInline: 12,
												}}
											/>
											<IconButton handleClick={() => handleVolume(100)}>
												<VolumeUp className={classes.icon} />
											</IconButton>
										</div>
									</React.Fragment>
								)}
							</div>
						</div>
					)}
					{disconnected && (
						<div
							style={{
								borderRadius: 10,
								margin: '10px 10px 10px',
								transition: 'margin .5s',
								padding: 10,
								backgroundColor: '#C7495A',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								color: 'white',
							}}
							onClick={() => {
								setLoading(true);
								// reset register
								if (ua.current) ua.current.destroy();
								ua.current = null;

								setTimeout(() => {
									init();
								}, 1000);
							}}
						>
							{loading && <span>Carregando</span>}
							{!loading && (
								<React.Fragment>
									<span>Conexão perdida</span>
									<span>Toque para Recarregar</span>
								</React.Fragment>
							)}
						</div>
					)}
					{!incoming && !disconnected && (
						<Button
							style={{
								color: '#fff',
								border: 0,
								outline: 0,
								width: '100%',
								padding: '0.5rem 0',
								marginTop: '-0.5rem',
								fontSize: '2rem',
								background:
									switchCallStatus === 'ready'
										? '#49C763'
										: switchCallStatus === 'incoming'
										  ? '#C7495A'
										  : switchCallStatus === 'unregistered'
										    ? '#49C763'
										    : '#FFF',
								borderRadius: '50% 50% 0 0 / 30%',
								textAlign: 'center',
							}}
							disabled={switchCallStatus === 'unregistered' || !phonetoVoip}
							onClick={
								switchCallStatus === 'ready' ? startCall : switchCallStatus === 'incoming' ? doHangup : startCall
							}
						>
							{switchCallStatus === 'unregistered' && (
								<IconButton>
									<Skeleton height='36px' width='36px' style={{transform: 'scale(1)'}} />
								</IconButton>
							)}
							{switchCallStatus === 'ready' && (
								<IconButton>
									<CallOutlinedIcon className={classes.icon} />
								</IconButton>
							)}
							{switchCallStatus === 'incoming' && (
								<IconButton>
									<CallEndOutlinedIcon className={classes.icon} />
								</IconButton>
							)}
						</Button>
					)}
					{incoming && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<Button
								style={{
									color: '#fff',
									border: 0,
									outline: 0,
									width: '50%',
									padding: '0.5rem 0',
									marginTop: '-0.5rem',
									fontSize: '2rem',
									background: '#49C763',
									borderRadius: '40% 0% 0 0 / 50%',
									textAlign: 'center',
								}}
								onClick={startCall}
							>
								<IconButton>
									<CallOutlinedIcon className={classes.icon} />
								</IconButton>
							</Button>
							<Button
								style={{
									color: '#fff',
									border: 0,
									outline: 0,
									width: '50%',
									padding: '0.5rem 0',
									marginTop: '-0.5rem',
									fontSize: '2rem',
									background: '#C7495A',
									borderRadius: '0% 40% 0 0 / 50%',
									textAlign: 'center',
								}}
								onClick={doHangup}
							>
								<IconButton>
									<CallEndOutlinedIcon className={classes.icon} />
								</IconButton>
							</Button>
						</div>
					)}
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}

export default Webphone;
