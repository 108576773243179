import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

const teamsRoutes = [
	{
		component: LazyComponent(lazy(() => import('./List'))),
		path: '/team/list',
	},
	{
		component: LazyComponent(lazy(() => import('./Create'))),
		path: '/team/create',
	},
	{
		component: LazyComponent(lazy(() => import('./Update'))),
		path: '/team/update/:id',
	},
];

export default teamsRoutes;
