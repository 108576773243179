import {useCallback} from 'react';
import {useDispatchPost} from 'redux/hooks/fetch';

function useFetchNotificationsList({status, limit = 5, type = 'default'} = {}) {
	const post = useDispatchPost();

	const fetchNotificationList = useCallback(
		params => {
			const {offset, sort} = params;
			const filters = {
				sort,
				status,
				channel: type,
				limit,
				offset: typeof offset !== 'undefined' ? offset : 0,
			};
			return post('/notification-center/my-notifications', filters);
		},
		[post, limit, type, status],
	);

	return fetchNotificationList;
}

export default useFetchNotificationsList;
