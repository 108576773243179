import {makeStyles} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import {list} from '@nimbus-target/ui';
import cn from 'classnames';
import AlertReadIcon from 'components/icons/AlertReadIcon';
import AlertUnreadIcon from 'components/icons/AlertUnreadIcon';
import Modal from 'components/modal/Modal';
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion';
import PropTypes from 'prop-types';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useInView} from 'react-intersection-observer';
import {useDispatchPost} from 'redux/hooks/fetch';
import AlertAttachmentsModal from './AlertAttachmentsModal';
import AlertCard from './AlertCard';
import useFetchNotificationList from './useFetchNotificationList';

const useStyles = makeStyles(() => ({
	root: {},
}));

/**
 * @layout:
 * https://www.figma.com/file/qHPhE3Hf2thqrqRaRiDnuI/Nimbus-Design-System?node-id=276%3A7475&mode=dev
 */
export const AlertsMessages = ({
	className = '',
	type = 'nimbus',
	status = 'unread',
	limit = 10,
	onClickMark,
	...props
}) => {
	const styles = useStyles();
	const rootRef = useRef();
	const {ref: lastMessageRef, inView: lastMessageInView} = useInView();
	const hasNextPageRef = useRef(null);
	const post = useDispatchPost();
	const fetchNotificationList = useFetchNotificationList({limit, type, status});
	const [notifications, setNotifications] = useState([]);
	const [offset, setOffset] = useState({[status]: 0});
	const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);
	const [openedNotificationId, setOpenedNotificationId] = useState(0);
	const [isLoading, setIsLoading] = useState(true);

	const handleLoadMoreData = useCallback(() => {
		const newOffset = offset[status] + limit;
		fetchNotificationList({offset: newOffset}).then(responseData => {
			hasNextPageRef.current = responseData.data.length === limit;
			setOffset({[status]: newOffset});
			setNotifications(data => {
				return list.getUniqueListBy('_id', [...data, ...responseData.data]);
			});
		});
	}, [offset, status, limit, fetchNotificationList]);

	// reset
	useEffect(() => {
		hasNextPageRef.current = null;
		setOffset({[status]: 0});
	}, [status, type]);

	const handleScroll = useCallback(
		evt => {
			const hasNextPage = hasNextPageRef.current === true || hasNextPageRef.current === null;
			if (lastMessageInView && hasNextPage && !isLoading) {
				handleLoadMoreData();
			}
		},
		[handleLoadMoreData, isLoading, lastMessageInView],
	);

	useEffect(() => {
		if (lastMessageInView) {
			handleScroll();
		}
	}, [handleScroll, lastMessageInView]);

	useEffect(() => {
		setIsLoading(true);
		post('/notification-center/my-notifications', {offset: 0, limit, channel: type, status})
			.then(responseData => {
				setNotifications(responseData.data);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [post, limit, type, status]);

	useEffect(() => {
		setOffset(currentOffset => ({[status]: currentOffset[status] ?? 0}));
	}, [status]);

	const renderingNotifications = () => {
		const transition = {type: 'tween', duration: 0.18};
		const notes = notifications.filter(note => note.recipients.status === status);

		if (notes.length === 0) {
			return (
				<motion.div
					layout={true}
					initial={{scale: 0.8, opacity: 0}}
					animate={{scale: 1, opacity: 1}}
					exit={{scale: 0.8, opacity: 0}}
					transition={transition}
				>
					<Card>
						<CardContent>
							<Box display='flex' alignItems='center' justifyContent='center' flexDirection='column'>
								<Box component='span' fontSize={'1rem'} mb={2}>
									{status === 'unread' ? 'Sem novas notificações no momento!' : 'Sem notificações lidas no momento!'}
								</Box>
								{status === 'unread' ? <AlertUnreadIcon /> : <AlertReadIcon />}
							</Box>
						</CardContent>
					</Card>
				</motion.div>
			);
		}

		return notes.map((data, index) => {
			return (
				<motion.div
					key={data._id}
					layout={true}
					initial={{scale: 0.8, opacity: 0}}
					animate={{scale: 1, opacity: 1}}
					exit={{scale: 0.8, opacity: 0}}
					transition={transition}
					ref={index === notes.length - 1 ? lastMessageRef : undefined}
				>
					<AlertCard
						type={type}
						data={data}
						onClickMark={read => {
							setNotifications(currentNotifications => {
								const newNotifications = currentNotifications.slice();
								const notifyIndex = newNotifications.findIndex(notify => notify._id === data._id);
								if (notifyIndex !== -1) {
									newNotifications[notifyIndex].recipients.status = read ? 'read' : 'unread';
								}
								if (onClickMark) {
									onClickMark(read, data, notifyIndex);
								}
								return newNotifications;
							});
						}}
						onClickAttachment={() => {
							setOpenedNotificationId(index);
							setIsAttachmentModalOpen(true);
						}}
					/>
					{isAttachmentModalOpen && (
						<Modal
							isOpen={isAttachmentModalOpen && openedNotificationId === index}
							handleClose={() => setIsAttachmentModalOpen(false)}
							title={data.title}
							textAlignment='left'
							maxWidth='md'
							fullWidth={false}
							actions={false}
							children={() => {
								return <AlertAttachmentsModal notification={data} />;
							}}
						/>
					)}
				</motion.div>
			);
		});
	};

	if (isLoading) {
		return (
			<Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<section ref={el => (rootRef.current = el)} className={cn(styles.root, className)} {...props}>
			<AnimatePresence mode='popLayout'>{renderingNotifications()}</AnimatePresence>
		</section>
	);
};

AlertsMessages.propTypes = {
	className: PropTypes.string,
	limit: PropTypes.number,
	onClickMark: PropTypes.func,
	status: PropTypes.oneOf(['read', 'unread']),
	type: PropTypes.oneOf(['nimbus', 'btg']),
};

AlertsMessages.displayName = 'Alerts.Messages';
export default AlertsMessages;
