import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

const integrationRoutes = [
	{
		component: LazyComponent(lazy(() => import('./List'))),
		path: '/integration/list',
	},
];

export default integrationRoutes;
