import React, {useEffect} from 'react';
import {useDispatchPost} from 'redux/hooks/fetch';
import {Typography, Card, CardContent, makeStyles} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {useAppValue, useDispatchSetAppValue} from 'redux/hooks/app';

const useStyles = makeStyles(theme => ({
	card: {
		marginBottom: theme.spacing(1),
	},
	content: {
		'display': 'flex',
		'padding': theme.spacing(1),
		'&:last-child': {
			paddingBottom: theme.spacing(1),
		},
	},
	icon: {
		color: theme.palette.success.main,
		fontSize: 20,
		marginRight: theme.spacing(1),
	},
}));

function UsersOnline() {
	const classes = useStyles();

	const post = useDispatchPost();

	const usersOnline = useAppValue('usersOnline');
	const setUsersOnline = useDispatchSetAppValue('usersOnline');

	useEffect(() => {
		post('/user/list', {onlyOnline: 1}).then(responseData => {
			setUsersOnline(responseData.data);
		});

		let isMounted = true;
		const intervalId = setInterval(() => {
			if (!isMounted) return;
			post('/user/list', {onlyOnline: 1}).then(responseData => {
				setUsersOnline(responseData.data);
			});
		}, 10000);

		return () => {
			clearInterval(intervalId);
			isMounted = false;
		};
	}, [post, setUsersOnline]);

	const renderUsers = () => {
		return usersOnline.map(data => {
			return (
				<Card key={data._id} className={classes.card}>
					<CardContent className={classes.content}>
						<FiberManualRecordIcon className={classes.icon} />
						<Typography>{`${data.name}${data.groupName ? ` - PDV: ${data.groupName}` : ''}`}</Typography>
					</CardContent>
				</Card>
			);
		});
	};

	return <div>{renderUsers()}</div>;
}

export default UsersOnline;
